<template>
  <div class="find-container">
    <transition>
      <div class="container" v-if="findNumberStatus">
        <span @click="close" class="close"></span>
        <div class="animate">
          <ul class="step1" v-if="step">
            <li>
              <input placeholder="请输入公司名称" type="text" v-model="companyInfo.name" />
              <span class="hint" ref="name">请输入公司名称</span>
            </li>
            <li>
              <input
                @input="formatCreditCode(companyInfo.creditCode)"
                placeholder="请输入公司税号"
                type="text"
                v-model="companyInfo.creditCode"
              />
              <span class="hint" ref="creditCode">请输入公司税号</span>
            </li>
          </ul>
          <ul class="step2" v-else>
            <li>
              <input placeholder="请输入账号" type="text" v-model="userInfo.loginPhone" />
              <span class="hint" ref="loginPhone">请输入账号</span>
            </li>
            <li>
              <input placeholder="请输入密码" type="text" v-model="userInfo.password" />
              <span class="hint" ref="password">请输入密码</span>
            </li>
          </ul>
          <div @click="next" class="next-btn">{{step?'下一步':'完成'}}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  props: {},
  data () {
    return {
      step: true,
      companyInfo: {
        name: "",
        creditCode: ""
      },
      userInfo: {
        id: "",
        loginPhone: "",
        password: "",
      }
    };
  },

  created () {

  },
  mounted () {

  },
  computed: {
    ...mapState(['findNumberStatus'])
  },
  watch: {},
  methods: {
    formatCreditCode (str) {
      this.companyInfo.creditCode = str.replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").toUpperCase()
    },
    close () {
      this.$store.state.findNumberStatus = !this.$store.state.findNumberStatus

    },

    next () {
      // this.step = false

      if (this.step) {
        if (this.companyInfo.name !== "" && this.companyInfo.creditCode !== "") {
          this.$request.post(this.$api.checkUserInfo, this.companyInfo).then(res => {
            if (res.status == 200 && res.data != "") {
              this.step = false;
              console.log('reeeee', res);
              this.userInfo.id = res.data;
            } else {
              this.$message.error(
                "请输入正确的公司名称/税号，如忘记请联系管理人员!"
              );
            }
          }).catch(err => {
            this.$message.error(
              "请输入正确的公司名称/税号，如忘记请联系管理人员!"
            );
          });

        } if (this.companyInfo.name == "") {
          this.$refs.name.style.display = "block"
        } if (this.companyInfo.creditCode == "") {
          this.$refs.creditCode.style.display = "block"
        }
      } else {
        if (this.userInfo.loginPhone !== "" && this.userInfo.password !== "") {

          let url = this.$api.ifNumber + this.userInfo.loginPhone;

          this.$request.get(url).then(res => {
            if (res.status == 200) {
              console.log('ppp');
              this.$request.put(this.$api.amendNumber, this.userInfo).then(res => {
                // 17701246400
                if (res.status == 200) {
                  this.$message.success("修改成功，请重新登录！");
                }
              });

            } else if (res.status == 201) {
              this.$message({
                message: '该手机号已注册,请重新输入!',
                type: 'warning'
              });
            }
          })
        }
        if (this.userInfo.loginPhone == "") {
          this.$refs.loginPhone.style.display = "block"
        }
        if (this.userInfo.password == "") {
          this.$refs.password.style.display = "block"

        }


      }



    }
  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 300px;
  }
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 30px auto;
  width: 318px;
  height: 370px;
  background: url(../../../assets/images/login/密码登录-背景.png) no-repeat;
  backdrop-filter: blur(5px);
  overflow: hidden;
  .animate {
    padding: 30px 23px;
    width: 100%;
    height: 100%;
    > ul {
      margin-top: 60px;
      li {
        margin-top: 20px;
        position: relative;
        .hint {
          position: absolute;
          left: 15px;
          bottom: -20px;
          color: red;
          font-size: 12px;
          display: none;
        }
        input {
          width: 100%;
          height: 42px;
          line-height: 42px;
          padding-left: 15px;
          background: url(../../../assets/images/login/密码登录-输入框.png)
            no-repeat;
          // outline: none;
          // border: none;
        }
      }
    }
    > .next-btn {
      height: 43px;
      width: 100%;
      margin-top: 60px;
      text-align: center;
      line-height: 43px;
      color: #fff;
      background: url(../../../assets/images/login/密码登录-登录按钮.png)
        no-repeat;
    }
  }

  .close {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
  }
}
</style>