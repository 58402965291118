<template>
  <div class="login">
    <transition>
      <div class="login-cantainter" v-if="loginStatus">
        <span @click="close" class="close"></span>
        <div class="animate">
          <!-- animate盒子解决 有paddig 导致动画不连贯 -->
          <ul class="login-password">
            <li :class="[status?'active1':'']" @click="changLogin(1)">密码登录</li>
            <li :class="[!status?'active2':'']" @click="changLogin(2)">验证码登录</li>
          </ul>
          <ul class="login-list">
            <li v-if="status">
              <ul>
                <li>
                  <input
                    @blur="checkPhone"
                    @input="checkPhone"
                    placeholder="手机号"
                    type="text"
                    v-model="logindata.variable"
                  />
                  <span class="hint" ref="phones">{{ifHasPhone?'请输入手机号':truePhone?'':'请输入正确的手机号'}}</span>
                </li>
                <li class="password">
                  <input :type="type" placeholder="密码" v-model="logindata.password" />
                  <span
                    @click="type='text',iconStatus = !iconStatus"
                    class="iconfont icon-chakan"
                    v-if="iconStatus"
                  ></span>
                  <span
                    @click="type='password',iconStatus = !iconStatus"
                    class="iconfont icon-buchakan"
                    v-else
                  ></span>

                  <!-- <span class="hint" ref="phone">{{truePhone?'请输入正确的手机号':'请输入手机号'}}</span> -->
                </li>
                <li class="edit">
                  <div @click="checkBtn" class="check">
                    <span :class="[checkStatus?'check-active':'']" class="check-btn"></span>
                    <span>下次自动登录</span>
                  </div>
                  <div class="reg">
                    <span @click="findNumber" class="find">账号找回</span>
                    <span @click="regBox" class="reg-btn">注册</span>
                  </div>
                </li>
                <li>
                  <div
                    :class="[truePhone&&logindata.password!==''?'':'login-status']"
                    @click="passwordLogin"
                    class="login-btn"
                  >登录</div>
                </li>
              </ul>
            </li>

            <li class="login-code" v-else>
              <ul>
                <li>
                  <input
                    @blur="checkPhone"
                    @input="checkPhone"
                    placeholder="手机号"
                    type="text"
                    v-model="logindata.variable"
                  />
                  <span class="hint" ref="phones">{{ifHasPhone?'请输入手机号':truePhone?'':'请输入正确的手机号'}}</span>
                </li>
                <li class="send">
                  <input placeholder="验证码" type="text" v-model="logindata.code" />
                  <button
                    :class="[isDisabled?'hasDisabled':'']"
                    :disabled="isDisabled"
                    @click="sendCode"
                  >
                    {{
                    buttonName
                    }}
                  </button>
                  <span class="hint" ref="code">{{truePhone?'请输入正确的验证码':'请输入验证码'}}</span>
                </li>
                <li class="edit">
                  <div @click="checkBtn" class="check">
                    <span :class="[checkStatus?'check-active':'']" class="check-btn"></span>
                    <span>下次自动登录</span>
                  </div>
                  <div class="reg">
                    <span class="find">账号找回</span>
                    <span @click="regBox" class="reg-btn">注册</span>
                  </div>
                </li>
                <li>
                  <div
                    :class="[truePhone&&logindata.code!==''?'':'login-status']"
                    @click="codeLogin"
                    class="login-btn"
                  >登录</div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @import
import { mapState } from 'vuex'

export default {
  components: {},
  props: {},
  data () {
    return {
      status: true,
      checkStatus: false,
      passwordOrCode: true,//密码和验证码 tab切换
      buttonName: "发送验证码",
      isDisabled: true,//手机号错误时 发送验证码按钮的状态控制
      truePhone: true,//判断手机号是否正确
      ifHasPhone: false, //手机号是否为空
      time: 60,//发送验证码倒计时

      logindata: {
        variable: "", // 用户名
        password: "", // 密码
        code: "",
        uuid: ""
      },
      type: "password",
      iconStatus: true,
    };
  },

  created () {

  },
  mounted () {

  },
  computed: {
    ...mapState(['loginStatus'])

  },
  watch: {
    'logindata.variable': {
      handler (newV, oldV) {
        // this.checkPhone2()
      },
      immediate: true
    }
  },
  methods: {
    changLogin (index) {
      this.status = !this.status
      this.checkPhone()
    },
    checkBtn () {
      this.checkStatus = !this.checkStatus
    },
    close () {
      this.$store.state.loginStatus = !this.$store.state.loginStatus

    },
    findNumber () {
      this.$store.state.findNumberStatus = !this.$store.state.findNumberStatus
    },
    regBox () {
      this.$store.state.regStatus = !this.$store.state.regStatus
      this.$store.state.loginStatus = !this.$store.state.loginStatus

      console.log('this.$store.state.regStatus', this.$store.state.regStatus);

    },
    // checkPhone2 () {
    //   let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    //   let status = reg.test(this.logindata.variable)
    //   if (status) {
    //     this.isDisabled = false
    //     this.$refs.phone.style.display = 'none'

    //   } else {
    //     this.isDisabled = true

    //   }
    // },
    checkPhone () {

      this.$refs.phones.style.display = 'block'


      if (this.logindata.variable !== '') {
        this.ifHasPhone = false

        let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        let status = reg.test(this.logindata.variable)
        if (status) {
          this.isDisabled = false
          this.truePhone = true
          this.$refs.phones.style.display = 'none'



        } else {
          this.truePhone = false
          this.isDisabled = true

        }
      } else {
        this.ifHasPhone = true

      }
    },

    //发送验证码
    sendCode () {
      console.log('haha ',);

      if (!this.isDisabled) {
        let data = {
          phoneNumber: this.logindata.variable
        }
        this.$request.get(this.$api.sendMessage, data).then(res => {
          console.log('send', res);
          var me = this;
          this.isDisabled = true;
          var interval = window.setInterval(function () {
            me.buttonName = "已发送" + me.time;
            --me.time;
            if (me.time < 0) {
              me.buttonName = "重新发送";
              me.time = 60;
              me.isDisabled = false;
              window.clearInterval(interval);
            }
          }, 1000);

        })
      }


    },
    login () {
      this.$request.post(this.$api.login, this.logindata).then(res => {
        console.log("///sssss", res);
        // this.dialogvisb = false;
        // console.log(res, "login请求成功");
        // 注释掉就无法登陆
        // this.$store.state.ids = res.data.enterpriseUserDTO.id;
        // window.sessionStorage.setItem("user-token", JSON.stringify(res.data));
        // this.$store.state.tokenStatus = true;
        // sessionStorage.setItem("tokenStatus", true)
        // this.$router.push("/");
        // window.location.reload();
        this.$store.state.userInfo = res.data

        if (this.checkStatus) {
          window.localStorage.setItem("userInfo", JSON.stringify(res.data));
          window.localStorage.setItem("userLoginStatus", true)

        } else {
          window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          sessionStorage.setItem("userLoginStatus", true)

        }


        this.$store.state.userLoginStatus = true;
        this.$store.state.loginStatus = false;


      }).catch(err => {
        console.log('perr', err.response);

        if (err.response.data.status == 400) {
          this.$message(err.response.data.message);
        }

      })
    },
    passwordLogin () {
      this.logindata.code = ''
      this.login()
    },
    //验证码登录
    codeLogin () {
      this.logindata.password = '';
      this.login()
    }
  },
}
</script>
<style lang='less' scoped>
@import '../../../style/index.css';
// @import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 300px;
  }
}
.v-enter-active {
  animation: move 0.35s linear;
}

/* 离开动画 */
.v-leave-active {
  animation: move 0.35s reverse;
}
.login {
}
.login-cantainter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 30px auto;
  width: 318px;
  height: 370px;
  background: url(../../../assets/images/login/密码登录-背景.png) no-repeat;
  backdrop-filter: blur(5px);
  overflow: hidden;
  .close {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
  }
  .animate {
    width: 100%;
    height: 100%;
    padding: 49px 23px;
  }
  .login-password {
    display: flex;
    width: 271px;
    background: url(../../../assets/images/login/密码登录-选择按钮2.png)
      no-repeat;
    .active1 {
      background: url(../../../assets/images/login/密码登录-选择按钮1.png)
        no-repeat;
    }
    .active2 {
      background: url(../../../assets/images/login/验证码登录-选择按钮1.png)
        no-repeat;
    }
    > li {
      width: 138px;
      height: 33px;
      text-align: center;
      line-height: 33px;
      color: #fff;

      &:nth-child(1) {
      }
      &:nth-child(2) {
        // background: url(../../../assets/images/login/验证码登录-选择按钮2.png)
        //   no-repeat;
      }
    }
  }
  .login-list {
    > li {
      > ul {
        .login-status {
          color: #cccccc;
          cursor: not-allowed;
        }
        .password {
          .iconfont {
            position: absolute;
            right: 10px;
            top: 6px;
            font-size: 20px;
            cursor: pointer;
          }
        }
        > li {
          margin-top: 20px;
          position: relative;

          .hint {
            position: absolute;
            left: 15px;
            bottom: -20px;
            color: red;
            font-size: 12px;
            display: none;
          }
          input {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding-left: 15px;
            background: url(../../../assets/images/login/密码登录-输入框.png)
              no-repeat;
            // outline: none;
            // border: none;
          }

          > .login-btn {
            height: 43px;
            width: 100%;
            margin-top: 40px;
            text-align: center;
            line-height: 43px;
            color: #fff;
            background: url(../../../assets/images/login/密码登录-登录按钮.png)
              no-repeat;
          }
        }
        .edit {
          display: flex;
          justify-content: space-between;
          span {
            color: #fff;
          }
          .check {
            display: flex;
            align-items: center;
            .check-btn {
              color: #fff;
              display: inline-block;
              width: 15px;
              height: 15px;
              border: 1px solid #fff;
              margin-right: 10px;
              transition: 0.45s;
            }
            .check-active {
              background: url(../../../assets/images/login/密码登录-对号框.png)
                no-repeat;
            }
          }
          .reg {
            .find {
              display: inline-block;
              color: #fff;

              position: relative;
              &:after {
                display: inline-block;
                content: '';
                width: 1px;
                height: 15px;
                position: absolute;
                top: 3px;
                right: -10px;
                background: #fff;
              }
            }
            .reg-btn {
              margin-left: 20px;
              color: #fff;
            }
          }
        }
      }
    }
    .login-code {
      > ul {
        .login-status {
          color: #cccccc;
          cursor: not-allowed;
        }
        li {
          position: relative;
          .hint {
            position: absolute;
            left: 15px;
            bottom: -20px;
            color: red;
            font-size: 12px;
            display: none;
          }

          .block {
            display: block;
          }
        }
        .send {
          display: flex;
          justify-content: space-between;
          > input {
            width: 146px;
            height: 42px;
            background: url(../../../assets/images/login/验证码登录-验证码输入框.png)
              no-repeat;
          }
          .hasDisabled {
            cursor: not-allowed;
            color: #cccccc;
          }
          button {
            width: 110px;
            color: #fff;
            background: url(../../../assets/images/login/验证码登录-验证码发送按钮.png)
              no-repeat;
          }
        }
      }
    }
  }
}
</style>