export default {
  data() {
    return {
      x: 0, //left:x
      y: 80, //top:y
      leftOffset: 0, //鼠标距离移动窗口左侧偏移量
      topOffset: 0, //鼠标距离移动窗口顶部偏移量
      isMove: false, //是否移动标识
    }
  },
  computed: {
    position() {
      return `top:${this.y}px;left:${this.x}px;`
    },
  },
  methods: {
    mousedown(event) {
      //鼠标按下事件
      this.leftOffset = event.offsetX
      this.topOffset = event.offsetY
      this.isMove = true
      event.target.style.cursor = 'move'
      console.log('event', event.$el, (event.target.style.cursor = 'move'))
    },
    //鼠标移动
    mousemove(event) {
      if (!this.isMove) {
        return
      }
      this.x = event.clientX - this.leftOffset
      this.y = event.clientY - this.topOffset
      console.log('event')
      // if (this.x <= 0) {
      //   this.x = this.$refs.position.offsetLeft
      // }
    },
    //鼠标抬起
    mouseup(event) {
      this.isMove = false
      event.target.style.cursor = 'unset'
    },
  },
}
