<template>
  <div>
    <button @click="add()" class="add">新增</button>
    <div class="text item">
      <el-radio-group @change="change" v-model="radio">
        <div :key="index" class="radio-li" v-for="(item, index) in companyInfo">
          <div>
            <el-radio :label="index">
              {{
              billOrAddress ? item.name : item.address
              }}
            </el-radio>
          </div>
          <div class="operation">
            <div @click="alter(index,item)">修改</div>
            <div @click="del(index)">删除</div>
          </div>
        </div>
      </el-radio-group>
    </div>
    <el-dialog :visible.sync="dialogVisible" @close="close" append-to-body width="30%">
      <!-- <span>这是一段信息</span> -->
      <div class="register">
        <div class="clearfix">
          <span>
            {{ ifEmpty ? "添加" : "修改"
            }}{{ billOrAddress ? "发票" : "收件" }}信息
          </span>
          <!-- <el-button @click.native="onCancel" style="float: right; padding: 3px 0" type="text">关闭</el-button> -->
        </div>
        <el-form
          :model="info.enterpriseInfos[0]"
          label-width="80px"
          ref="form"
          v-if="billOrAddress"
        >
          <el-form-item
            :rules="[
            { required: true, message: '请输入企业名称', trigger: 'blur' }
          ]"
            label="企业名称"
            prop="name"
          >
            <el-input v-model="info.enterpriseInfos[0].name"></el-input>
          </el-form-item>
          <el-form-item
            :rules="[
            { required: true, message: '请输入信用代码', trigger: 'blur' }
          ]"
            label="信用代码"
            prop="creditCode"
          >
            <el-input
              :ref="refList.cardInput"
              @input="inputBlur(info.enterpriseInfos[0].creditCode)"
              v-model="info.enterpriseInfos[0].creditCode"
            ></el-input>
          </el-form-item>
          <el-form-item
            :rules="[
            {  message: '请输入注册地址', trigger: 'blur' }
          ]"
            label="注册地址"
            prop="registerAddress"
          >
            <el-input v-model="info.enterpriseInfos[0].registerAddress"></el-input>
          </el-form-item>
          <el-form-item
            :rules="[
            {  message: '请输入注册电话', trigger: 'blur' },
          ]"
            label="注册电话"
            prop="phone"
          >
            <el-input v-model="info.enterpriseInfos[0].phone"></el-input>
          </el-form-item>

          <el-form-item
            :rules="[
            {  message: '请输入开户行', trigger: 'blur' }
          ]"
            label="开户行"
            prop="accountOpening"
          >
            <el-input v-model="info.enterpriseInfos[0].accountOpening"></el-input>
          </el-form-item>
          <el-form-item
            :rules="[
            {  message: '请输入银行账号', trigger: 'blur' }
          ]"
            label="银行账号"
            prop="bankAccount"
          >
            <el-input
              :ref="refList.cardInputs"
              @input="
              formatCardNumber(
                refList.cardInputs,
                info.enterpriseInfos[0].bankAccount,
                info
              )
            "
              v-model="info.enterpriseInfos[0].bankAccount"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="onSubmit" type="primary">
              {{
              ifEmpty ? "立即添加" : "立即修改"
              }}
            </el-button>

            <el-button @click="onCancel">
              {{
              ifEmpty ? "取消添加" : "取消修改"
              }}
            </el-button>
          </el-form-item>
        </el-form>

        <el-form :model="postAddress" label-position="left" label-width="80px" ref="form2" v-else>
          <el-form-item
            :rules="[
            {  message: '店名', trigger: 'blur' }
          ]"
            label="店名"
            prop="companyName"
          >
            <el-input v-model="postAddress.companyName"></el-input>
          </el-form-item>
          <el-form-item
            :rules="[
            { required: true, message: '请输入收件地址', trigger: 'blur' }
          ]"
            label="收件地址"
            prop="address"
          >
            <el-input v-model="postAddress.address"></el-input>
          </el-form-item>

          <el-form-item
            :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
            label="收件人"
            prop="name"
          >
            <el-input v-model="postAddress.name"></el-input>
          </el-form-item>

          <el-form-item
            :rules="[
            { required: true, message: '请输入电话', trigger: 'blur' },
            { pattern: /^1[34578]\d{9}$/, message: '电话号输入有误' }
          ]"
            label="收件电话"
            prop="phone"
          >
            <el-input v-model="postAddress.phone"></el-input>
          </el-form-item>

          <el-form-item>
            <div class="items">
              <el-button @click="postSubmit" type="primary">
                {{
                ifEmpty ? "立即添加" : "立即修改"
                }}
              </el-button>

              <el-button @click="onCancel">
                {{
                ifEmpty ? "取消添加" : "取消修改"
                }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const info = JSON.parse(window.sessionStorage.getItem("user-token"));

export default {
  name: '',
  props: ['status'],
  data () {
    return {
      msg: '',
      // radio: "",
      companyInfo: [],
      billOrAddress: '',
      dialogVisible: false,
      ifEmpty: true,
      refList: {
        cardInput: "cardInput",
        cardInputs: "cardInputs"
      },
      info: {
        loginPhone: "",
        password: "",
        userName: "",
        email: "",
        enterpriseInfos: [
          {
            id: "",
            accountOpening: "",
            bankAccount: "",
            phone: "",
            creditCode: "",
            name: "",
            registerAddress: "",
            enterpriseUser: {
              id: ""
            }
          }
        ],
        enterpriseAddresses: [
          {
            name: "",
            address: "",
            phone: "",

          }
        ]
      },
      postAddress: {
        id: "",
        companyName: "",
        address: "",
        name: "",
        phone: "",
        enterpriseUser: {
          id: ""
        }
      }
    }
  },
  created () {
    this.getData()
  },
  
  watch: {
    status (newValue, oldValue) {
      console.log('newvalue', newValue, oldValue)
      this.getData()

    },
    nums(){
      debuggeer
    }
    // radio(newValue, oldValue){
    //   console.log('newvalue', newValue, oldValue)

    // }
  },
  mounted () {

  },
 
  computed: {
    radio: {
      get () {
        // debugger
        if (this.status == 'first') {
          return this.$store.state.indexs
        } else {
          return this.$store.state.addressIndex
        }
      },
      set (newValue) {
        // debugger
        if (this.status == 'first') {
          sessionStorage.setItem('indexs', newValue)
          return this.$store.state.indexs = newValue
        } else {
          sessionStorage.setItem('addressIndex', newValue)

          return this.$store.state.addressIndex = newValue
        }

      }
    },
     nums(){
      // debugger
      this.getData()
      return this.$store.state.nums;
    }
  },
 
  methods: {
    change (inte) {
      console.log('item', inte);

    },
    add () {
      this.ifEmpty = true;
      this.dialogVisible = true;
      if (this.status == 'first') {
        this.info.enterpriseInfos[0].accountOpening = ''
        this.info.enterpriseInfos[0].id = ''
        this.info.enterpriseInfos[0].bankAccount = ''
        this.info.enterpriseInfos[0].phone = '';
        this.info.enterpriseInfos[0].creditCode = '';
        this.info.enterpriseInfos[0].name = '';
        this.info.enterpriseInfos[0].registerAddress = '';
        this.info.enterpriseInfos[0].enterpriseUser.id =
          info.enterpriseUserDTO.id;
      } else {
        this.postAddress.address = '';
        this.postAddress.name = '';
        this.postAddress.phone = '';
        this.postAddress.companyName = '';
        this.postAddress.enterpriseUser.id = info.enterpriseUserDTO.id;
      }
    },
    onSubmit () {
      let obj = this.info.enterpriseInfos[0];
      console.log("zhejiushi", info);
      obj.enterpriseUser.id = info.enterpriseUserDTO.id;
      console.log("zhejiushi", obj);
      var _this = this;
      this.$refs.form.validate(valid => {
        console.log("ds", valid);
        if (valid) {
          if (this.ifEmpty) {
            // 添加企业信息
            this.$request
              .post(this.$api.addInfo, obj)
              .then(res => {
                console.log("sjfkasd", res);

                this.$message({
                  message: "添加成功",
                  type: "success"
                });
                // this.$store.state.back = true;

                // this.$router.go(-1);
                this.dialogVisible = false;

              })
              .catch(err => {
                console.log(err);
              });
          } else {
            // 修改企业信息

            // this.$axios({
            //   method:'put',
            //   url:'/api/enterprise/info',
            //   data:this.info.enterpriseInfos[0]
            // }).then(res=>{
            //       debugger
            // console.log('put',res)
            // })

            this.$request
              .put(this.$api.changeInfo, this.info.enterpriseInfos[0])
              .then(res => {
                if (res.status == 200) {
                  this.$message({
                    message: "修改成功",
                    type: "success"
                  });

                  // this.$router.go(-1);
                  this.dialogVisible = false;

                }
              });
          }
        } else {
          this.$message("请输入正确信息");
          return;
        }
      });
    },
    postSubmit () {
      console.log('ooooooooooooooo');
      
      debugger

      this.$refs.form2.validate(vaild => {
        console.log("vaild", vaild);
        let datas = this.$route.query.datas;

        console.log('klklklklklklklk', datas);

        this.postAddress.enterpriseUser.id = info.enterpriseUserDTO.id;
        console.log("vaild", this.postAddress);
        console.log('ressss', this.postAddress)
        if (vaild) {
          if (this.ifEmpty) {
            // debugger
            this.$request
              .post(this.$api.addAddress, this.postAddress)
              .then(res => {
                // debugger
                console.log("res123", res);
                if (res.status == 200 || res.status == 201) {
                  this.$message({
                    message: "添加成功",
                    type: "success"
                  });
                }

                // this.$router.back();
                  this.dialogVisible = false;

              });
          } else {
            debuggeer
            this.postAddress.id = datas.id;

            console.log("datassss", this.postAddress)
            this.$request.put(this.$api.changeAddress, this.postAddress).then(res => {
              console.log('res', res)

              if (res.status == 200) {
                this.$message({
                  message: "修改成功",
                  type: "success"
                });
              }

                  this.dialogVisible = false;
              // this.$router.go(-1);
            })
          }
        }
      });
    },
    onCancel () {
      this.dialogVisible = false;
    },
    getData () {
     

      let id = JSON.parse(window.sessionStorage.getItem("user-token"))
        .enterpriseUserDTO.id;
      if (this.status == 'first') {
        this.radio = this.$store.state.indexs;
        this.billOrAddress = true;
        let url = this.$api.billInfo;
        this.$request.get(url, { id: id }).then(res => {
          console.log("这就是我进来的时候", res,this.companyInfo);
          this.companyInfo = res.data;
          this.$forceUpdate();

          // this.$set(this.companyInfo,'',res.data);

          console.log('这就是我进来的时候 jinlaile',this.companyInfo);
          
          
        });
      } else if (this.status == "second") {
        this.radio = this.$store.state.addressIndex;
        this.billOrAddress = false;

        let url = this.$api.billAddress;
        this.$request.get(url, { id: id }).then(res => {
          console.log("2ee2444444444444", res);
          this.companyInfo = res.data;
        });
      }

    },
    alter (index, item) {
      console.log('itttt', item);
        this.$store.state.nums = false;

      this.dialogVisible = true;

      // console.log("/./...", this.companyInfo[index]);
      this.ifEmpty = false;
      if (this.status == 'first') {
        this.$store.state.indexs = index;
        sessionStorage.setItem('indexs', index)
        this.info.enterpriseInfos[0].accountOpening = item.accountOpening;
        this.info.enterpriseInfos[0].id = item.id;
        this.info.enterpriseInfos[0].bankAccount = item.bankAccount;
        this.info.enterpriseInfos[0].phone = item.phone;
        this.info.enterpriseInfos[0].creditCode = item.creditCode;
        this.info.enterpriseInfos[0].name = item.name;
        this.info.enterpriseInfos[0].registerAddress = item.registerAddress;
        this.info.enterpriseInfos[0].enterpriseUser.id =
          info.enterpriseUserDTO.id;
      } else {
        this.$store.state.addressIndex = index;
        sessionStorage.setItem('addressIndex', index)
        this.postAddress.address = item.address;
        this.postAddress.name = item.name;
        this.postAddress.phone = item.phone;
        this.postAddress.companyName = item.companyName;
        this.postAddress.enterpriseUser.id = info.enterpriseUserDTO.id;
      }
      // this.$router.push({
      //   path: "/modifyinfo",
      //   query: {
      //     ifEmpty: this.ifEmpty,
      //     index: index,
      //     datas: this.companyInfo[index]
      //   }
      // });
      // this.$store.state.indexs = index;

    },
    del(index) {
      console.log("dele", this.companyInfo[index]);
      console.log("dele", this.companyInfo[index].id);

      let id = this.companyInfo[index].id;

      if (this.billOrAddress) {

        this.$request.delete(this.$api.delInfo+'/'+id).then(res => {
          if (res.status == 200) {
            debugger
            this.companyInfo.splice(index, 1);
          }
          console.log("res", res);
        });
      }else{
         this.$request.delete(this.$api.delAddress+'/'+id).then(res => {
          if (res.status == 200) {
            this.companyInfo.splice(index, 1);
          }
          console.log("res", res);
        });
      }
    },
    
    close () {
      // debugger
      this.getData()
    },
    inputBlur (str) {
      // replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").
      this.info.enterpriseInfos[0].creditCode = str.replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").toUpperCase()
    },
    // 修改银行卡号，4位一体 格式化

    formatCardNumber (cardInput, cardNum, info) {
      // 获取input的dom对象，element ui的el-input这样才能拿到
      const input = this.$refs.cardInput.$el.getElementsByTagName("input")[0];

      const cursorIndex = input.selectionStart;
      const lineNumOfCursorLeft = (
        cardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length;
      const noLine = cardNum.replace(/\s/g, "");
      const newCardNum = noLine
        .replace(/\D+/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .replace(/\s$/, "");
      const newLineNumOfCursorLeft = (
        newCardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length;
      const newCursorIndex =
        cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft;
      this.$nextTick(() => {
        if (cardInput == "cardInput") {
          this.info.enterpriseInfos[0].creditCode = newCardNum;
        } else if (cardInput == "cardInputs") {
          this.info.enterpriseInfos[0].bankAccount = newCardNum;
        }
        this.$nextTick(() => {
          input.selectionStart = newCursorIndex;
          input.selectionEnd = newCursorIndex;
        });
      });
    },
  }
}
</script>

<style lang='less' scoped>
.add {
  width: 60px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  background-color: #409eff;
  font-size: 12px;
  outline: none;
  border: none;
  &:active {
    border: none;
  }
}
.clearfix {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}
.text {
  height: 250px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 15px;
}
.el-radio-group {
  font-size: 14px;
  display: block;
  // overflow-y: auto !important;
  .radio-li {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .operation {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      > div {
        margin-left: 15px;
        color: #606266;
        &:hover {
          color: #409eff;
        }
      }
    }
  }
}

/deep/.el-dialog__body {
  padding-top: 10px;
}
</style>
