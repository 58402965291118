<template>
  <div class="common">
    <div @mouseleave="open=false" class="common-message">
      <div :class="[!open?'':'common-title-close']" @mouseenter="open=true" class="titles">
        <div class="common-title">
          <div>公</div>
          <div>告</div>
        </div>
      </div>
      <div :class="[open?'info-open':'']" class="info">{{info}}</div>
    </div>

    <div @mouseleave="messageStatus=false" class="express-message" v-show="expressData.length > 0">
      <div
        :class="[!messageStatus?'':'message-title-close']"
        @mouseenter="messageStatus=true"
        class="title"
      >近期发货信息</div>
      <div :class="[messageStatus?'message-open':'']" @click="sendData()" class="info">
        <ul
          :class="{ move: animate }"
          :style="{'margin-top':height,}"
          class="public-moudle"
          ref="publicMoudle"
        >
          <!-- :class="{ move: animate }"-->
          <li :key="index" class="express-list" v-for="(item, index) in expressData">{{ item.info }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../../assets/js/utils";

export default {
  name: "publicMessage",
  data () {
    return {
      info: "",
      msg: "",
      height: '',
      open: true,
      messageStatus: true,
      expressData: [],
      animate: false,
      publicTime: '',
      addressTime: ''
    };
  },
  created () {





  },
  mounted () {

    this.$request.get(this.$api.newPublicMessage).then(res => {
      // console.log("ddd", res);
      this.info = res.data.info;
      this.publicTime = res.data.dateTime;
    });

    this.$request.get(this.$api.publicMessage).then(res => {
      // console.log("dddsdsds", res);
      // this.info = res.data.info;
      this.expressData = res.data;
      if (this.expressData !== "" && this.expressData.length > 0) {
        setInterval(this.beginMove, 7000);
      }

    });
    // setInterval(this.beginMove, 3000);
    setTimeout(() => {
      // this.open = false;
      this.messageStatus = false;
    }, 10000)
     setTimeout(() => {
      this.open = false;
      // this.messageStatus = false;
    }, 30000)
  },
  filters: {
    formatData (time) {
      return utils.format(time, "yyyy-MM-dd");
    }
  },
  methods: {
    sendData () {
      this.$emit('datas', this.expressData, true)
    },
    beginMove () {
      this.animate = true;
      let height = this.$refs.publicMoudle.children[0].offsetHeight;
      this.height = -(height + 5) + 'px'
      console.log('555555555555', this.$refs)
      setTimeout(() => {
        this.expressData.push(this.expressData[0]);
        this.expressData.shift();
        this.height = 0 + 'px'
        this.animate = false;
      }, 1000);
    }
  }
};
</script>

<style lang="less" scoped>
.common-message {
  border-radius: 3px;
  min-height: 36px;
  font-size: 12px;
  // padding:10px;
  // background: rgba(92,99,110,1);
  display: flex;
  align-items: center;
  // width:30px;
  overflow: hidden;
  position: relative;
  .titles {
    border: 2px solid #fff;
  }
  // >.common-title{
  //   display:flex;
  //   justify-content:space-between;
  // }
  > div {
    // float:left;
    transition: 1.2s;
    &:nth-child(1) {
      height: 100%;
      width: 23px;
      padding: 3px;
      text-align: center;
      background: rgba(92, 99, 110, 1);
      border-radius: 3px;
      position: absolute;
      height: 100%;

      > .common-title {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }
    &:nth-child(2) {
      // display:none
      // visibility: hidden;
    }
  }
  .info {
    width: 100%;
    margin-left: calc(-100% - 46px);
    padding: 15px;
    border: 2px solid #fff;
  }
  .info-open {
    margin-left: 0;
    background: rgba(92, 99, 110, 1);
    width: 100%;
  }
  .common-title-close {
    display: none;
  }
}

.express-message {
  // background: rgba(92,99,110,1);
  display: flex;
  // padding: 0 0 0px 10px;
  font-size: 13px;
  position: relative;

  .title {
    border-radius: 3px;
    border: 2px solid #fff;
    width: 23px;
    position: absolute;
    padding: 3px;
    height: 100%;
    background: rgba(92, 99, 110, 1);
    padding-top: 25px;
  }
}
.move {
  // margin-top: -120px;
  transition: all 1s;
}

.common {
  width: 320px;
  // height: 300px;
  color: #fff;
  // background: #ccc;
  // border: 1px solid #000;
  border-radius: 3px;
  position: fixed;
  left: 15px;
  padding: 15px 0;
  box-sizing: border-box;
  top: 400px;
  // bottom: 260px;
  // left: 70px;
  overflow: hidden;
  // display: none;
  .express-message {
    margin-top: 3px;
    overflow: hidden;

    .info {
      transition: 0.89s;
      cursor: pointer;
      // border-top: 1px solid transparent;
      height: 170px;
      overflow: hidden;
      width: 100%;
      margin-left: -110%;
      border: 2px solid #fff;
      border-radius: 3px;
      // position: relative;
      ul {
        // position: absolute;
        // transition: all 1s;
        // top: 0;
        li {
          font-size: 12px;
          padding: 0 10px 10px 10px;
        }
      }
    }
    .message-open {
      width: 100%;
      margin-left: 0;
      background: rgba(92, 99, 110, 1);
    }
    .message-title-close {
      display: none;
    }
    ul {
    }
    .express-list {
      margin-top: 5px;
      // height: 110px;
    }
  }
}
</style>
