<template>
  <div class="container">
    <!-- logo header -->
    <headerPage></headerPage>

    <!-- center view -->
    <centerPage></centerPage>

    <!-- footer view -->
    <footerPage></footerPage>

    <!-- video screen -->
    <videoPage></videoPage>

    <!-- <prolist v-on:open-list="this.$router.push('/')"></prolist> -->
    <maskBox v-if="maskStatus"></maskBox>
  </div>
</template>

<script>
import videoPage from "../commonView/videoView"
import headerPage from "../commonView/headerView"
import centerPage from "../commonView/centerView"
import footerPage from "../commonView/footerView"
import prolist from '../productList'
import initData from '@/mixins/initData'
import maskBox from '../commonComponents/mask'
import { mapState } from 'vuex'
export default {
  name: '',
  mixins: [initData],
  components: {
    videoPage,
    headerPage,
    centerPage,
    footerPage,
    prolist,
    maskBox
  },
  data () {
    return {
      msg: ''
    }
  },
  created () {
    this.$nextTick(() => {
      // this.init()
    })


  },
  mounted () {
  },

  computed: {
    ...mapState(['maskStatus', 'userLoginStatus', 'imgList', 'billAddressStatus', 'regStatus', 'selectStatus', 'confirmOrderStatus', 'moreAnnouncmentStatus', 'loginStatus', 'contactStatus', 'companyInfoStatus', 'infoStatus'])
  },
  watch: {

    loginStatus (newValue, oldValue) {
      if (newValue) {
        // this.$store.state.moreAnnouncmentStatus = false;
        // this.$store.state.infoStatus = false;
        // this.$store.state.contactStatus = false;
        // this.$store.state.companyInfoStatus = false;
        this.commonCloseStatus('loginStatus')
      }
    },
    regStatus (newValue, oldValue) {
      if (newValue) {
        this.commonCloseStatus('regStatus')
      }
    },
    moreAnnouncmentStatus (newValue, oldValue) {
      if (newValue) {
        this.commonCloseStatus('moreAnnouncmentStatus')
      }
    },
    selectStatus (newValue, oldValue) {
      if (newValue) {
        this.commonCloseStatus('selectStatus')
      }
    },
    infoStatus (newValue, oldValue) {
      if (newValue) {
        this.commonCloseStatus('infoStatus')
      }
    },
    contactStatus (newValue, oldValue) {
      if (newValue) {
        this.commonCloseStatus('contactStatus')
      }
    },
    companyInfoStatus (newValue, oldValue) {
      if (newValue) {
        this.commonCloseStatus('companyInfoStatus')
      }
    },
    confirmOrderStatus (newValue, oldValue) {
      if (newValue) {
        this.commonCloseStatus('confirmOrderStatus')
      }
    },


  },
  methods: {
    beforeInit () {
      // debugger
      this.apiUrl = this.$api.inquireDepartments
      this.methods = 'get'
    },
    commonCloseStatus (name) {
      console.log('this.$store.state[name]', this.$store.state[name]);
      this.$store.state.loginStatus = false;
      this.$store.state.moreAnnouncmentStatus = false;
      this.$store.state.infoStatus = false;
      this.$store.state.contactStatus = false;
      this.$store.state.companyInfoStatus = false;
      this.$store.state.findNumberStatus = false;
      this.$store.state.regStatus = false;

      if (this.userLoginStatus) {
        this.$store.state.selectStatus = false;
        this.$store.state.selectStatus = false;
        this.$store.state.orderListStatus = false;
        this.$store.state.passwordStatus = false;


      }
      if (name !== "billAddressStatus") {
        this.$store.state.billAddressStatus = false;
        this.$store.state.confirmOrderStatus = false;

      }
      //  if (name !== "billAddressStatus") {
      //   this.$store.state.billAddressStatus = false;

      // }
      this.$store.state[name] = true;

    }
  }
}
</script>

<style lang='less' scoped>
.container {
  position: relative;
  // min-width: 1920px;
}
</style>
