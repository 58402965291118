<template>
  <div class="footer-container">
    <div></div>
    <div class="borders">
      <div class="container">
        <div class="image">
          <img alt src="../../../assets/images/1811637027193_.pic_hd.jpg" />
        </div>
        <span>北京修比印刷技术有限公司</span>
        <span @click="jump1()" href="https://beian.miit.gov.cn">京ICP备13026201号-1</span>
        <div class="images">
          <img alt src="../../../assets/images/1691636089678_.pic_hd.jpg" />
        </div>
        <span @click="jump2()" class="police">京公网安备 11011402011867号</span>
      </div>

      <div class="heng-border-left-bottom">
        <div class="border-one"></div>
        <div class="border-two"></div>
        <div class="border-three"></div>
      </div>
      <div class="heng-border-right-bottom">
        <div class="border-one"></div>
        <div class="border-two"></div>
        <div class="border-three"></div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>

export default {
  name: '',
  data () {
    return {
      msg: ''
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    jump1 () {
      window.open('https://beian.miit.gov.cn', '_blank')
    },
    jump2 () {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402011867', '_blank')
    }
  }
}
</script>

<style lang='less' scoped>
@import '../../../style/common.less';

.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  height: 50px;
  border-top: 2px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
  .borders {
    border: none;

    .container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      .image {
        margin-top: -5px;
        width: 80px;
        img {
          width: 100%;
          margin-top: 12px;
        }
      }
      .images {
        margin-left: 20px;
        height: 35px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
      span {
        margin-left: 20px;
      }
    }
  }
}

@media screen and(max-width: 1919px) {
  .footer-container {
    width: 100%;
    > div {
      &:nth-child(1) {
        width: 40px;
        border-right: 2px solid rgba(255, 255, 255, 0.25);
        // border-bottom: 2px solid rgba(255, 255, 255, 0.25);
      }
      &:nth-child(2) {
        width: calc(100% - 84px);
      }
      &:nth-child(3) {
        width: 40px;
        border-left: 2px solid rgba(255, 255, 255, 0.25);
        // border-bottom: 2px solid rgba(255, 255, 255, 0.25);
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .footer-container {
    width: 1920px;
    > div {
      &:nth-child(1) {
        width: 40px;
        border-right: 2px solid rgba(255, 255, 255, 0.15);
        // border-bottom: 2px solid rgba(255, 255, 255, 0.15);
      }
      &:nth-child(2) {
        width: 1836px;
      }
      &:nth-child(3) {
        width: 40px;
        border-left: 2px solid rgba(255, 255, 255, 0.15);
        // border-bottom: 2px solid rgba(255, 255, 255, 0.15);
      }
    }
  }
}
</style>
