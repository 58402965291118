<template>
  <div class>
    <transition>
      <div :style="position" class="info-container" v-if="companyInfoStatus">
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="head"
        ></div>
        <div class="info-text">{{msg}}</div>
        <div class="image">
          <img alt src="../../../assets/images/companyIntro/图片框1.png" />
        </div>
        <span @click="close" class="close"></span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import moveData from '../../../mixins/move'
import vm from '../../../assets/js/event'
// @import

export default {
  mixins: [moveData],

  components: {},
  props: {},
  data () {
    return {
      msg: ''
    };
  },

  created () {

  },
  mounted () {
    vm.$on("allIntro", value => {
      // 接受自定义事件传递的数据
      this.msg = value
    })
  },
  computed: {
    ...mapState(['companyInfoStatus'])

  },
  watch: {
    companyInfoStatus (newValue, oldValue) {
      if (newValue) {
        this.x = (document.body.clientWidth - 318) / 2
        this.y = 80
        console.log('width', this.x);

      } else {
        return
      }
    }
  },
  methods: {
    close () {
      this.$store.state.companyInfoStatus = !this.$store.state.companyInfoStatus
      if (this.$store.state.companyInfoStatus) {
        vm.$emit("allIntro", this.msg)

      }
    },

  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 300px;
  }
}
.info-container {
  position: fixed;

  width: 318px;
  height: 298px;
  color: #fff;

  overflow: hidden;
  background: url(../../../assets/images/companyIntro/背景框.png) no-repeat;
  backdrop-filter: blur(3px);
  .head {
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .info-text {
    swidth: 100%;
    height: 200px;
    padding: 40px 25px;
  }
  .image {
    display: flex;
    justify-content: center;
  }
  .close {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 999;
  }
}
</style>