import Vue from 'vue'
import VueRouter from 'vue-router'
import request from '@/assets/js/request.js'
const login = () => import('../views/login')
const register = () => import('../views/register')
const history = () => import('../views/historyPage')
const findNumber = () => import('../views/findNumber')
const changePage = () => import('../views/module/changePage')
const Modify = () => import('../views/modifypasw')
const Modifyifo = () => import('../views/modifyinfo')
const Order = () => import('../views/order')
const webIndex = () => import('../../src/App')
// const xiubiPage = () => import('../views/xiubiPage')
// const baiduPage = () => import('../views/baiduPage')

// const pcRoute = [
//   {
//     path: '/',
//     component: xiubiPage,
//   },
//   {
//     path: '/baiduPage',
//     component: baiduPage,
//   },
//   {
//     path: '/login',
//     component: login,
//   },
//   {
//     path: '/order',
//     name: 'Order',
//     component: Order,
//   },

//   {
//     path: '/register',
//     component: register,
//   },

//   {
//     path: '/findnumber',
//     component: findNumber,
//   },
//   {
//     path: '/modify',
//     component: Modify,
//   },
//   {
//     path: '/modifyinfo',
//     component: Modifyifo,
//   },
//   {
//     path: '/historyPage',
//     component: history,
//     meta: {
//       keepAlive: true,
//     },
//   },
//   {
//     path: '/changePage',
//     name: 'changePage',
//     component: changePage,
//     meta: {
//       keepAlive: true, // 需要缓存
//     },
//   },
// ]
let pcRoute = []

// const createRouter = () =>
//   new VueRouter({
//     mode: 'history',
//     pcRoute,
//   })

export default pcRoute
