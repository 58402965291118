<template>
  <div id="app">
    <!-- <videoPage></videoPage> -->
    <div class="head-top"></div>
    <div class="footer-bottom"></div>

    <div class="app-container" v-if="!flag">
      <router-view v-if="$route.meta.keepAlive" />
    </div>
    <!-- <div v-if="!flag">
      <div class="nav-list">
        <div @click="aboutUs()" class="about-us">联系我们</div>
        <el-dialog :append-to-body="true" :visible.sync="dialogVisible" width="30%">
          <div>
            <p>请留下您的联系方式，稍后客服会联系您！</p>
            <el-form :model="formdata" label-width="80px" ref="form">
              <el-input placeholder="请留下您的大名" v-model="formdata.name"></el-input>
              <el-input
                @onkeyup="value=value.replace(/[^\d]/g,0)"
                placeholder="请留下您的手机号"
                v-model="formdata.phone"
              ></el-input>
              <el-input placeholder="请留言：" v-model="formdata.spare"></el-input>
              <el-button @click="confirm" data-agl-cvt="5" size="medium" type="primary">确定</el-button>
            </el-form>
          </div>
          <div>
            <p>或者您可以直接联系我们：</p>
            <ul>
              <li>
                <span>公司电话</span>：010-87151783
              </li>
              <li>
                <span>经理电话</span>
                ：13126662186
              </li>
              <li>
                <span>微信添加</span>:
                <div class="wechat-img">
                  <img alt src="../src/assets/images/3371651905117_.pic.jpg" />
                </div>
              </li>
              <li>· 中国 · 北京 · 顺义 · 修比印刷 ·</li>
            </ul>
          </div>
        </el-dialog>

     

        <div class="login">
          <div class="login-box" v-if="show">
            <span
              :class="[loginShowStatus?'login-enter':'login-leave']"
              @click="loginUser"
              @mouseenter="loginShowStatus = true"
              @mouseleave="loginShowStatus=false"
            >
              <span class="iconfont icon-denglu login-user"></span>
              登录
            </span>
            <span
              :class="[registerShowStatus?'login-enter':'login-leave']"
              @click="register"
              @mouseenter="registerShowStatus = true"
              @mouseleave="registerShowStatus=false"
            >
              <span class="iconfont icon-zhuce"></span>
              注册
            </span>
          </div>
          <div class="login-in" v-if="!show">
            <el-avatar
              :size="25"
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            ></el-avatar>
            <span class="sbox"></span>

            <el-dropdown trigger="click">
              <span class="userinfo">
                {{ userinfo }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="history">我的订单</el-dropdown-item>
                <el-dropdown-item @click.native="infoDialogVisible=true">发票/地址信息</el-dropdown-item>
                <el-dropdown-item @click.native="modifyPass">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="loginOut">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div class="login login-left">
          <div class="fullscreen common-style">
            <span @click="fullscreen" class="iconfont icon-quanping_o" v-if="ifScreen"></span>
            <span @click="fullscreen" class="iconfont icon-quxiaoquanping_o" v-else></span>
          </div>
          <div class="muted common-style">
            <span @click="opensVioce" class="iconfont icon-yysoundoff" v-if="mutedStatus"></span>
            <span @click="closesVioce" class="iconfont icon-dkw_gonggao" v-else></span>
          </div>

          <div class="time">{{time}}</div>
        </div>
        <el-dialog :visible.sync="infoDialogVisible" @open="open" append-to-body title width="30%">
          <el-tabs @tab-click="handleClick" v-model="activeName">
            <el-tab-pane label="发票信息" name="first">
              <commonChange :status="activeName" ref="commonChange"></commonChange>
            </el-tab-pane>
            <el-tab-pane label="地址信息" name="second">
              <commonChange :status="activeName" ref="commonChange"></commonChange>
            </el-tab-pane>
          </el-tabs>
        </el-dialog>
      </div>

      <div class="hpicture">
        <div
          :class="index === currentNum ? 'active' : ''"
          :key="item.id"
          @mouseenter="currentInfo(index)"
          @mouseleave="hide()"
          class="user-head"
          v-for="(item, index) in userArr"
        >
          <img :src="`${pubilckPath}/${item.headAddress}`" alt />

          <div v-show="ifToken">
            <p>{{ item.name }}</p>
            <p>{{ item.jobs }}</p>
            <p style="font-family: HeiTi">{{ item.phone }}</p>
          </div>
        </div>
      </div>

   
      <router-view />

    
      <prolist v-on:open-list="this.$router.push('/')"></prolist>

      <div id="icon"></div>

      <div class="player">
        <video autoplay="autoplay" controls="controls" id="video" muted preload="auto"></video>
      </div>

      <div :class="[!loadings?'load-out':'']" class="load-mask">
        <div class="load-img">
          <span class="iconfont icon-loading"></span>
        </div>
        <div class="load-title">正在加载现场请稍后</div>
      </div>



      <div
        @mouseenter="arrows()"
        @mouseleave="workShopLeave"
        class="workShop animated"
        ref="workShop"
      >
        <div v-if="videoData.length<=3">
          <div
            :class="[liveShow && liveIndex == index? 'enters' : 'leaves' , beginStatus== true ? 'begin-enter' : '',currentStatus==index?'begin-enter':'']"
            :id="'video'+item.id"
            :key="index"
            @click="changeVideo(item,index)"
            @mouseenter="liveShow = true;liveIndex = index"
            @mouseleave="liveShow = false"
            class="workSpace"
            v-for="(item,index) in videoData"
          >
            <div @mouseenter="imgEnter(index)" @mouseleave="imgLeave(index)" class="video-img">
              <div
                :class="[titleBlock?'title-block':'','title'+index]"
                class="title-box animated title-block"
                ref="titleBox"
              >
                <div class="title">{{item.roomName}}</div>
              </div>
              <div class="imgArea">
                <img :src="`${pubilckPath}/${item.roomImg}`" alt />
              </div>
            </div>
          </div>
        </div>

        <div class="all-workspace" v-else>
          <div :class="[liveStatus?'titleClose':'']" @mouseenter="liveStatus=true" class="title"></div>
          <div
            :class="[!liveStatus?'containerClose':'containerOpen']"
            @mouseleave="liveStatus=false"
            class="container"
          >
            <ul>
              <li
                :class="[liveCurrentIndex == index? 'active':'']"
                :key="index"
                @click="changeVideo(item,index)"
                style="color:#000"
                v-for="(item,index) in videoData"
              >
                <img :src="`${pubilckPath}/${item.roomImg}`" alt />
                <div class="mask">{{item.roomName}}</div>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="videos.length<3">
          <div
            :class="[hasShow && hasIndex == index? 'enters' : 'leaves' , hasBeginStatus == true ? 'begin-enter' : '',hasCurrentStatus == index?'begin-enter':'']"
            :key="index"
            @click="changeVideos(item,index)"
            @mouseenter="hasShow = true;hasIndex = index"
            @mouseleave="hasShow = false"
            class="work-video"
            v-for="(item,index) in videos"
          >
            <div @mouseenter="imgEnters(index)" @mouseleave="imgLeaves(index)" class="video-img">
              <div
                :class="[titleBlock?'title-block':'','titles'+index]"
                class="title-box title-block animated"
              >
                <div class="title">{{ item.name }}</div>
              </div>
              <div class="video-container">
                <img :src="`${pubilckPath}/${item.videoImgAddress}`" alt />
              </div>
            </div>
          </div>
        </div>
        <div class="all-workspaces" v-else>
          <div :class="[hasStatus?'titleClose':'']" @mouseenter="hasStatus=true" class="title"></div>
          <div
            :class="[!hasStatus?'containerClose':'containerOpen']"
            @mouseleave="hasStatus=false"
            class="container container-1"
          >
            <ul>
              <li
                :class="[hasCurrentIndex == index? 'active':'']"
                :key="index"
                @click="changeVideos(item,index)"
                style="color:#000"
                v-for="(item,index) in videos"
              >
                <img :src="`${pubilckPath}/${item.videoImgAddress}`" alt />
                <div class="mask">{{item.name}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    

      <div class="footer">
        <div class="image">
          <img alt src="./assets/images/1811637027193_.pic_hd.jpg" />
        </div>
        <span>北京修比印刷技术有限公司</span>
        <span>京ICP备13026201号-1</span>
        <div class="images">
          <img alt src="./assets/images/1691636089678_.pic_hd.jpg" />
        </div>
        <span class="police">京公网安备 11011402011867号</span>
      </div>
    </div>-->

    <!-- 移动端开始 -->
    <div class v-else>
      <!-- 登陆页 -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
  </div>
</template>
<script
  type="text/javascript"
  charset="utf-8"
  src="https://g.alicdn.com/de/prismplayer/2.9.0/aliplayer-min.js"
></script>
<script>
import Bus from './utils/bus.js'
import prolist from './views/productList'
import publicMessage from './views/publicMessage'
import mobileLogin from './mobile/mobileLogin'
import mobileRegister from './mobile/mobileRegister'
import Hls from './assets/js/hls'
import pro from './mobile/commonAssembly/producList'
import commonChange from './views/module/commonChange'
import allContainer from './views/xiubi'
import videoPage from './views/commonView/videoView'
export default {
  data () {
    return {
      userInfo: {
        number: '',
        password: ''
      },
      time: '',
      liveCurrentIndex: null,
      hasCurrentIndex: null,
      currentStatus: 0,
      hasCurrentStatus: null,
      titleBlock: true,
      ifScreen: true,
      mutedStatus: true,
      liveShow: false,
      liveIndex: '',
      beginStatus: true,
      hasShow: false,
      hasIndex: '',
      hasBeginStatus: true,
      activeName: 'first',
      loginShowStatus: true,
      registerShowStatus: true,
      infoDialogVisible: false,
      loginUserStatus: undefined,//登陆动画开关
      workspaceStatus: false,
      voice: false,
      flag: null,
      videoUrl: './playVideo/video.html',
      userinfo: '',
      userArr: [],
      currentNum: null,
      show: true,
      ifToken: false,
      videoData: [],
      videoOne: null,
      loadings: true,
      videoLeftStatus: false,
      videos: [],
      metedMove: false,
      move: false,
      moves: true,
      liveStatus: false,//控制live直播切换状态
      hasStatus: false,//控制录播
      flags: false,
      escStatus: true,
      dialogVisible: false,
      formdata: {
        name: "",
        phone: '',
        spare: ''
      }
    }
  },
  components: {
    prolist,
    publicMessage,
    mobileLogin,
    mobileRegister,
    pro,
    commonChange,
    allContainer,
    // videoPage
  },


  created () {
    this.isPhone();
    this.getinfo()
    this.loadings = true;
    Bus.$on('change', e => {
      if (e.userName !== null) {
        this.userinfo = e.userName
      }
    })



  },
  watch: {
    infoDialogVisible (newValue, oldValue) {
      if (newValue == false) {
        this.$store.state.nums = false;
      }
    },
    $route () {

    }



  },
  mounted () {




    // window.addEventListener('keydown',function(e) {
    //   if(e.which === 27){
    //     debugger
    //   }
    // })


    let _this = this;
    // 解决全屏esc后 按钮状态不变  监听全屏下的esc

    window.onresize = function () {
      console.log('escStatus', _this.escStatus);

      if (!_this.esc()) {
        //触发esc事件，执行业务逻辑。
        // debugger
        // _this.ifScreen = !_this.ifScreen;
        _this.ifScreen = true
        _this.flags = false
      }
    }

    // 解决所有直播首次出现退回问题
    let dom = document.querySelector('.container')
    let dom1 = document.querySelector('.container1')

    // console.log('containerClose', dom);

    if (dom !== null && dom.classList.contains('containerClose')) {
      dom.classList.remove('containerClose')
    }
    if (dom1 !== null && dom.classList.contains('containerClose')) {
      dom1.classList.remove('containerClose')
    }


    // 获取时间

    setInterval(() => {
      this.getTime()
    }, 1000)
    setTimeout(() => {
      this.metedMove = true


      // this.fullscreen()
    }, 4500)

    setTimeout(() => {
      this.loginShowStatus = false
      this.registerShowStatus = false
      this.beginStatus = false
      this.hasBeginStatus = false
      this.titleBlock = false
    }, 10000)
    setTimeout(() => {
      this.move = true
      this.moves = false
    }, 6800)

    // this.getparter()
    // this.getVideos()


    // this.openListBtn()
    let token = sessionStorage.getItem('user-token')

    if (token !== '' && token !== null) {
      this.ifToken = true
    } else {
      this.ifToken = false
    }



    if (!this.flag) {
      // this.getplay()

      // this.workShop()
      // this.videoLeft()
    }


    // window.addEventListener('mousemove', this.mousemove, false);

    // window.addEventListener('resize', () => { //监听浏览器窗口大小改变
    //   //浏览器变化执行动作

    //   // var dom = document.getElementById('video')
    //   // var publicDom = document.querySelector('.common')
    //   // var loginDom = document.querySelector('.login')
    //   // var headerDom = document.querySelector('.user-box')
    //   // headerDom.style.left = dom.offsetLeft + 30 + 'px'
    //   // publicDom.style.left = dom.offsetLeft + 40 + 'px'
    //   // loginDom.style.marginRight = dom.offsetLeft + 'px'



    // });
  },
  updated () {

  },
  beforeDestroy () {

  },
  methods: {
    aboutUs () {
      this.dialogVisible = true
    },
    confirm () {

      if (this.formdata.name !== '' && this.formdata.phone !== '') {
        this.$request.post(this.$api.customerInfo, this.formdata).then(res => {
          console.log('reooooo', res);

          this.$message({
            message: '已收到，稍后客户联系您！',
            type: 'success'
          });
          window._agl && window._agl.push(['track', ['success', { t: 3 }]])
          this.formdata = {
            name: "",
            phone: '',
            spare: ''
          }
        })
      } else {
        this.$message({
          message: '请填入姓名和手机号',
          type: 'warning'
        });
      }
    },
    esc () {
      var isFull = document.mozFullScreen ||

        document.fullScreen ||

        //谷歌浏览器及Webkit内核浏览器

        document.webkitIsFullScreen ||

        document.webkitRequestFullScreen ||

        document.mozRequestFullScreen ||

        document.msFullscreenEnabled

      if (isFull === undefined) {
        // undefined

        isFull = false

      }

      return isFull;
      // debugger
      // var el = document.documentElement,
      //   rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
      //   console.log('rfs',rfs);


      // if (typeof rfs != "undefined" && rfs) {
      //   rfs = false;
      //   }
      // // debugger
      // // this.ifScreen = !this.ifScreen;

      // return rfs;

    },
    fullscreen () {
      this.ifScreen = !this.ifScreen;
      if (!this.flags) {
        var el = document.documentElement,
          rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;

        console.log('yuyu', rfs, typeof rfs);

        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
          this.flags = true;
          // this.escStatus = false;
        }
        return;
      } else {
        var el = document,
          cfs = el.cancelFullScreen || el.webkitCancelFullScreen || el.mozCancelFullScreen || el.exitFullScreen;
        if (typeof cfs != "undefined" && cfs) {
          cfs.call(el);
          this.flags = false;
        }
        return;
      }

    },



    handleClick (tab, event) {
      console.log(tab, event);
    },
    open () {
      this.$nextTick(() => {
        this.$store.state.nums = true;
      })

    },

    // 获取当前时间
    getTime () {
      var d = new Date();
      var a = d.getHours()//获取小时
      var b = d.getMinutes()//获取分钟
      var c = d.getSeconds()//获取秒
      if (a < 10) {
        a = "0" + a
      }
      if (b < 10) {
        b = "0" + b
      }
      if (c < 10) {
        c = "0" + c
      }
      this.time = a + ':' + b + ':' + c
    },
    getVideos () {
      this.$request.get(this.$api.getVideos).then(res => {
        this.videos = res.data.content;
        console.log('videos', this.videos);


      })
    },
    openVioce () {
      let video = document.querySelector('video');
      video.muted = false;
      this.voice = true;
      this.move = false;
      this.$forceUpdate()
    },
    closeVioce () {
      let video = document.querySelector('video');
      video.muted = true;
      this.voice = false;
      this.move = true;
      // this.metedMove  = false;
    },

    opensVioce () {
      let video = document.querySelector('video');
      video.muted = false;
      this.mutedStatus = !this.mutedStatus;
    },
    closesVioce () {
      let video = document.querySelector('video');
      video.muted = true;
      this.mutedStatus = !this.mutedStatus;

    },
    // 解决公告信息不在视屏内问题

    videoLeft () {
      var dom = document.getElementById('video')
      dom.addEventListener('timeupdate', this.timeupdate, false)
    },
    timeupdate () {
      var dom = document.getElementById('video')
      console.log('播放了11');
      this.loadings = false;
      dom.removeEventListener('timeupdate', this.timeupdate, false)

    },

    // 车间列表第二版初始化
    workShop () {

      setTimeout(() => {

        if (this.$refs.workShop.classList) {
          this.$refs.workShop.classList.add('fadeOutRight')
        }
      }, 7000)

      setTimeout(() => {
        this.$refs.arrows.classList.add('arrowBlock')
      }, 7500)
    },
    workShopLeave () {
      this.workspaceStatus = false;

      // this.$refs.workShop.classList.remove('fadeInRight')
      // this.$refs.workShop.classList.add('fadeOutRight')
      // this.$refs.arrows.classList.add('arrowBlock')
    },
    arrows () {
      this.workspaceStatus = true;
      // this.$refs.workShop.classList.remove('fadeOutRight')
      // this.$refs.workShop.classList.add('fadeInRight')
      // this.$refs.arrows.classList.remove('arrowBlock')
    },


    imgEnter (index) {

      // let dom = document.querySelector(`.title${index}`)
      // dom.classList.add('title-block')
      // dom.classList.add('fadeIn')
      // dom.classList.remove('fadeOut')

    },
    imgEnters (index) {

      // let dom = document.querySelector(`.titles${index}`)
      // dom.classList.add('title-block')
      // dom.classList.add('fadeIn')
      // dom.classList.remove('fadeOut')

    },
    imgLeave (index) {
      // let dom = document.querySelector(`.title${index}`)
      // dom.classList.remove('fadeIn')
      // dom.classList.add('fadeOut')
      // dom.classList.remove('title-block')
    },
    imgLeaves (index) {
      // let dom = document.querySelector(`.titles${index}`)
      // dom.classList.remove('fadeIn')
      // dom.classList.add('fadeOut')
      // dom.classList.remove('title-block')
    },

    changeVideos (item, index) {

      let dom = document.getElementById('video')
      dom.src = this.pubilckPath + '/' + item.videoAddress
      this.currentStatus = null
      this.hasCurrentStatus = index

      this.hasCurrentIndex = index
      // dom.muted = false
    },




    openListBtn () {
      setTimeout(() => {
        this.$refs.changePlayer.classList.add('changeVideoBlock');
        this.$refs.changePlayer.classList.add('heartBeat');
      }, 2000)

      setTimeout(() => {
        this.$refs.changePlayer.classList.remove('heartBeat');
        this.$refs.changePlayer.classList.add('bounceOutRight');
        // this.$refs.changePlayer.classList.remove('rightBlock');
      }, 3000)

      setTimeout(() => {
        this.$refs.changePlayer.classList.remove('changeVideoBlock');
        this.$refs.arrow.classList.add('arrowBlock');
      }, 4000)


    },
    arrow () {
      this.$refs.arrow.classList.remove('arrowBlock');
      this.$refs.changePlayer.classList.remove('bounceOutRight');
      this.$refs.changePlayer.classList.add('bounceInRight');

      this.$refs.changePlayer.classList.add('changeVideoBlock');

      var x = window.event.clientX;
      var y = window.event.clientY;

    },
    mousemove () {
      var x = window.event.clientX;
      var y = window.event.clientY;
      var divx1 = this.$refs.changePlayer.offsetLeft;
      var divy1 = this.$refs.changePlayer.offsetTop;
      var divx2 = this.$refs.changePlayer.offsetLeft + this.$refs.changePlayer.offsetWidth;
      var divy2 = this.$refs.changePlayer.offsetTop + this.$refs.changePlayer.offsetHeight;
      // this.$refs.changePlayer


      if (x > divx1 && x < divx2 && y > divy1 && y < divy2) {


      } else {

      }

    },
    mouseLeave () {
      this.$refs.arrow.classList.add('arrowBlock');
      this.$refs.changePlayer.classList.add('bounceOutRight');
    },
    // changeVideo (item, index) {

    //   // let middleValue = JSON.parse(JSON.stringify(this.videoOne));
    //   // this.videoOne = item
    //   // this.videoData[index] = middleValue;

    //   if (this.videoData.length > 3) {
    //     this.liveCurrentIndex = index  //直播视频大于三个时  列表点击后的状态
    //   } else {
    //     this.currentStatus = index
    //     this.hasCurrentStatus = null
    //     // this.titleBlock = true
    //     this.imgEnter(index)
    //     this.getVideo(item.roomNo, 'video')
    //     this.$forceUpdate()
    //   }
    // },
    getplay () {

      this.$request.get(this.$api.videoUrl, { sort: 'sort,asc' }).then(res => {


        this.videoOne = res.data.content
        this.videoData = res.data.content
        this.getVideo(this.videoOne[0].roomNo, 'video')

      })

    },
    getVideo (roomNo, videoId) {
      let that = this;
      var pathName = roomNo;
      var video = document.getElementById(videoId);
      if (Hls.isSupported()) {
        var hls = new Hls({
          debug: true,
        });
        hls.loadSource('https://monitor.xiubi.com.cn/live/' + pathName + '/livestream.m3u8');
        // https://monitor.xiubi.com.cn/live/A10067/livestream.m3u8
        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          // video.muted = false;
          video.play();

        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = 'http://monitor.xiubi.com.cn/live/' + pathName + '/livestream.m3u8';
        video.addEventListener('canplay', function () {
          video.play();


        });
      }

    },
    GetQueryValue1 (queryName) {
      var reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURI(r[2]);
      } else {
        return null;
      }
    },
    isPhone () {
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    history () {
      this.$router.push('./historyPage')
    },
    loginUser () {
      this.$router.push('/login').catch(() => { })
      this.$store.state.loginShow = true
    },
    register () {
      this.$router.push('/register').catch(() => { })
      this.$store.state.registerShow = true
    },
    //修改密码
    modifyPass () {
      this.$router.push('/modify')
    },
    getinfo () {
      const token = JSON.parse(window.sessionStorage.getItem('user-token'))
      if (token) {
        this.show = false
        if (token.enterpriseUserDTO.userName !== null) {
          this.userinfo = token.enterpriseUserDTO.userName
        }
      }
    },
    loginOut () {
      this.$confirm('确定要退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          window.sessionStorage.removeItem('user-token')
          window.sessionStorage.removeItem('listInfo')
          this.$store.state.tokenStatus = false;
          sessionStorage.setItem("tokenStatus", false)
          window.location.reload()
        })
        .catch(() => { })
    },
    getOrder () {
      this.$router.push('/order')
    },
    // 获取员工信息
    getparter () {
      this.$axios({
        method: 'get',
        url: '/api/xwEmployees/back',
        params: {
          sort: 'sort,asc'
        }
      })
        .then(res => {

          this.userArr = res.data.content
        })
        .catch(err => {
          // console.log(11111111, err)
        })
    },
    currentInfo (index) {
      if (this.currentNum !== index) {
        this.currentNum = index
      }
    },
    hide () {
      this.currentNum = null
    },


  },

}
</script>
<style lang="less" scoped>
.head-top {
  height: 48px;
  width: 100%;
  backdrop-filter: blur(0.3125rem);
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.15);
}
.footer-bottom {
  height: 50px;
  width: 100%;
  backdrop-filter: blur(0.3125rem);
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 2px solid rgba(255, 255, 255, 0.15);
}
body {
}
.app-container {
  // background-color: #cccccc;
}
.adc {
  width: 100%;
  height: 50px;
  background-color: #000;
  position: fixed;
  left: 0;
  top: 0;
}

.ad {
  width: 465px;
  height: 100vh;
  background-color: #000;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -100;
}
.ads {
  width: 35px;
  height: 100vh;
  background-color: #000;
  position: fixed;
  right: 0;
  top: 0;
}
// #app{
//   background: #ccc;
// }
html {
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}
// .box{
//   background-image: url('./assets/images/backgrounD.jpg');
//     background-size:cover;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
@import 'https://g.alicdn.com/de/prismplayer/2.9.0/skins/default/aliplayer-min.css';
body {
  position: relative;
  background-image: url(./assets/images/bg.gif) !important;
  background-repeat: repeat !important;
  background-color: #0a0a0a !important;
}
#app {
  position: relative;
  overflow-x: hidden;
  overflow: hidden;
  // font-weight: 200 !important;
  cursor: default;
}
.nav-list {
  height: 40px;
  background: transparent;
  // padding: 10px 0px;
  padding-right: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 10;
  width: 100%;
  top: 0;
  justify-content: flex-end;
  position: absolute;
  cursor: pointer;
  .about-us {
    width: 80px;
    height: 30px;
    border: 1px solid #ffffff;
    text-align: center;
    line-height: 30px;
    background-color: #5c636e;
    border-radius: 25px;
    color: #fff;
    margin-right: 15px;
  }

  // .about-container {

  //   }
}
/deep/.el-dialog {
  min-width: 800px;

  .wechat-img {
    width: 150px;
    height: 150px;
    position: relative;
    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      // position: absolute;
      // left: 0;
      // top: 0;
      // transform: scale(.2);
    }
  }
}
/deep/.el-dialog__body {
  display: flex;
  > div {
    width: 50%;
  }
}
/deep/.el-input {
  width: 70% !important;
  margin-top: 15px;
}
/deep/.el-button--medium {
  width: 120px;
  margin-top: 33px;
}
.player {
  width: 100%;
  height: 100vh;
  *zoom: 1;
  position: relative;
  z-index: -1;
  > .title {
    color: #ffffff;
  }
}
.load-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #333;
  width: 73%;
  height: 100vh;
  padding-top: 300px;
  box-sizing: border-box;
  font-size: 18px;
  // line-height: 200px;
  text-align: center;
  color: #fff;
  opacity: 1;
  .load-img {
    margin-bottom: 15px;
    position: relative;
    height: 40px;
    width: 100%;
    // text-align: center;
    display: flex;
    justify-content: center;
    .iconfont {
      font-size: 38px;
      animation: turn 2s linear infinite;
      position: absolute;
    }
  }
}
@keyframes turn {
  // 0%{
  //   transform: scale(1);
  // }
  // 100%{
  //   transform: scale(2)
  // }
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.load-out {
  animation-name: loading;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  opacity: 0;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// z-index: -1;
.player-init {
  position: absolute;
  width: 300px;
  height: 300px;
  z-index: 800;
  background: #000;
}
.player-2 {
  right: 15%;
  top: 12%;
}
.player-3 {
  right: 15%;
  bottom: 12%;
}
.player-name {
  position: absolute;
  color: #fff;
  z-index: 99;
}
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.login-user {
  // color: #7459dc;
  // background: #7459dc;
}
.footer {
  width: 100%;
  height: 35px;
  background: #000;
  position: absolute;
  bottom: 0;
  line-height: 35px;
  color: #fff;
  text-align: center;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    width: 80px;
    img {
      width: 100%;
      margin-top: 12px;
    }
  }
  .images {
    margin-left: 20px;
    height: 35px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  span {
    margin-left: 20px;
  }
}
.logo {
  // position: absolute;
  // left: 40px;
  // top: 10px;
  height: 35px;
}
.time {
  // position: fixed;
  // top: 13px;
  // left: 91%;
  // float: right;
  // margin-right: 140px;
  width: 134px;
  border: 1px solid #fff;
  border-radius: 50px;
  height: 30px;
  text-align: center;
  background-color: #5c636e;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  // font-weight: 600;
}
.login {
  // line-height: 35px;
  color: #fff;
  font-size: 15px;
  // margin-right: 300px;
  //  display: flex;
  // align-items: center;
  // display: none;
  > .login-box {
    border-radius: 25px;
    overflow: hidden;
    // position: fixed;
    border: 1px solid #fff;
    height: 30px;

    @keyframes loginEnter {
      0% {
        right: -52px;
      }
      100% {
        right: 0;
      }
    }
    @keyframes loginLeave {
      0% {
        right: 0px;
      }
      100% {
        right: -52px;
      }
    }
    .login-enter {
      animation-name: loginEnter;
      animation-duration: 0.4s;
      animation-fill-mode: linear;
      right: 0;
    }
    .login-leave {
      animation-name: loginLeave;
      animation-duration: 0.4s;
      animation-fill-mode: linear;
      right: -52px;
    }

    > span {
      display: inline-block;
      // margin-right: 10px;
      // padding: 5px;
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      // margin-bottom: 10px;
      // position: fixed;
      // right: -52px;
      transition: all 0.35s;

      &:nth-child(1) {
        background: #5c636e;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        // top: 60px;
      }
      &:nth-child(2) {
        // top: 104px;

        background: #fff;
        // border: 1px solid #5c636e;
        color: #5c636e;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  > .login-in {
    background: #5c636e;
    border: 1px solid #fff;
    // float: right;
    // width: 134px;
    display: flex;
    align-items: center;
    // position: fixed;
    // top: 90px;
    // right: 0;
    margin-right: 0px;
    padding: 1.5px 30px;
    border-radius: 50px;
  }
  .userinfo {
    // display: inline-block;

    transform: translate(0, -7px);
    color: #fff;
  }
}
.login-left {
  height: 30px;
  line-height: 0;
  // padding: 3px 0 0 0;
  // flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // text-align: right;
  // display:flex;
  // justify-content:left;
  // position:fixed;
  // left: 85%;
  // animation-name: loginMove;
  // animation-duration:1.5s;
  // animation-timing-function: linear;
  > div {
    margin-left: 15px;
  }
  > .common-style {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
    text-align: center;
    line-height: 30px;
    background-color: #5c636e;

    > .iconfont {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

@keyframes loginMove {
  0% {
    left: 0;
  }
  100% {
    left: 85%;
  }
}

.sbox {
  display: inline-block;
  width: 10px;
}

//头像
.hpicture {
  display: none;
  position: absolute;
  // left: 50px;
  // top: 100px;
  bottom: 0px;
  color: #fff;
  z-index: 10;
  img {
    width: 60px;
    border-radius: 50%;
    margin-bottom: 30px;
  }
  p {
    display: none;
  }
}
.user-head {
  overflow: hidden;
  height: 68px;
  margin-bottom: 35px;
}
.active {
  img {
    transform: scale(1.8);
    position: absolute;
    z-index: 10;
    transition: 0.8s;
  }
  div {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-bottom: 30px;
    padding-left: 15px;
    // padding-top: 5px;
    transition: 0.4s;

    p {
      display: block;
      margin: 2px;
      background: rgba(255, 255, 255, 0.2);
      padding-left: 120px;
      padding-right: 10px;
      font-family: YouYuan;
      font-size: 14px;
      text-align: right;
      height: 20px;
      transition: 0.4s;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      &:nth-child(1) {
        width: 50px;
      }
      &:nth-child(2) {
        width: 140px;
      }
      &:nth-child(3) {
        width: 220px;
      }
    }
    :last-child {
      font-family: ITC Avant Garde Gothic Std Book;
    }
  }
}

video::-webkit-media-controls-play-button {
  display: none;
}
video::-webkit-media-controls-timeline {
  display: none;
}
video::-webkit-media-controls-current-time-display {
  display: none;
}
video::-webkit-media-controls-time-remaining-display {
  display: none;
}
video::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
video::-webkit-media-controls-volume-slider {
  display: none;
}
video::-webkit-media-controls-enclosure {
  display: none;
}
.player {
  background: #000;
}
#video {
  height: 100vh;
  margin: 0 auto;
  display: block;
  transform: scale(1.12);
}
.changePlayer {
  position: absolute;
  top: 30%;
  right: 0px;
  display: none;
  .workSpace {
    background: #ffffff;
    width: 100px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    margin-bottom: 10px;
    right: -80px;
    border: 1px solid #00ffff;
  }
}
@keyframes move {
  0% {
    left: 0%;
    opacity: 0;
  }
  50% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
.move {
  -webkit-animation-name: move;
  animation-name: move;
  position: relative;
  // margin-left: -25px;
  font-size: 18px;
  font-weight: bold;
  color: #fa4848;
}
.ar-animated {
  margin-left: -10px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  font-style: inherit;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.ar-delay-1s {
  animation-delay: 0.1s;
}
.ar-delay-2s {
  animation-delay: 0.2s;
}
.ar-delay-3s {
  animation-delay: 0.3s;
}
.ar-delay-4s {
  animation-delay: 0.4s;
}
.ar-delay-5s {
  animation-delay: 0.5s;
}
.ar-delay-6s {
  animation-delay: 0.6s;
}
.ar-delay-7s {
  animation-delay: 0.7s;
}
.ar-delay-8s {
  animation-delay: 0.8s;
}
.ar-delay-9s {
  animation-delay: 0.9s;
}
.ar-delay-10s {
  animation-delay: 0.1s;
}
// 第一版车间列表箭头
.arrow {
  position: fixed;
  width: 50px;
  display: none;
  right: 35px;
  top: 30%;
  // text-align: right;
}
// 第二版车间列表箭头
.arrows {
  position: fixed;
  width: 50px;
  display: none;
  right: 35px;
  top: 40%;
  z-index: 10;
}

.arrowBlock {
  display: block !important;
}
.changeVideoBlock {
  display: block !important;
}

// 车间列表第二版

.workShop {
  position: fixed;
  z-index: 10;
  transition: 0.34s;
  // top: 40%;
  // right: -75px;
  right: 0;
  color: #fff;
  // border: 1px solid rgba(204, 204, 204, 0.3);
  border-radius: 3px;
  // background: rgba(204, 204, 204, 0.3);
  // padding: 10px;
  .all-workspace {
    position: fixed;
    // right: -105px;
    width: 120px;
    min-height: 60px;
    z-index: 1;
    // height: auto;
    // overflow: hidden;
    // border: 2px solid transparent;

    margin-bottom: 10px;
    .title {
      position: fixed;
      top: 330px;
      right: 0;
      width: 7px;
      height: 36px;
      // border: 2px solid red;

      background: #00ffff;
    }
    .titleClose {
      display: none;
    }

    .container {
      position: fixed;
      top: 330px;
      max-width: 100%;
      z-index: 99;
      transition: 0.45s;
      // border: 2px solid transparent;
      min-height: 56px;
      // overflow: hidden;
      // display:none;
      // width: 0;

      right: -100%;
      margin-bottom: 10px;

      ul {
        display: flex;
        min-height: 56px;
        padding-left: 10px;
        align-items: center;
        max-width: 100%;
        flex-wrap: wrap;
        // justify-content:space-between;
        padding: 10px 0 0 10px;
        .active {
          border: 2px solid #00ffff;
        }
        li {
          width: 120px;
          height: 45px;
          background: #fff;
          margin-right: 10px;
          margin-bottom: 10px;
          overflow: hidden;
          position: relative;
          .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            text-align: center;
            line-height: 3;
            color: #fff;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .containerOpen {
      // display:block !important;
      // width: 100%;
      right: 0;
      background: rgba(0, 0, 0, 0.95);
      animation-name: containopen;
      animation-timing-function: linear;
      // border: 2px solid red;
    }
    .containerClose {
      // display:block !important;
      // width: 100%;
      right: -100%;
      background: rgba(0, 0, 0, 0);
      animation-name: containclose;
      // animation-duration: 0.45s;
      animation-timing-function: linear;
      // border: 2px solid red;
    }
    @keyframes containopen {
      0% {
        // width: 0;
        right: -100%;
        opacity: 0;
        border: 2px solid transparent;
        background: rgba(0, 0, 0, 0);
      }
      100% {
        // width: 100%;
        right: 0;
        opacity: 1;
        // border: 2px solid red;
        background: rgba(0, 0, 0, 0.95);
      }
    }
    @keyframes containclose {
      0% {
        // width: 0;
        right: 0%;
        opacity: 1;
        // border: 2px solid transparent;
        background: rgba(0, 0, 0, 0.95);
      }
      100% {
        // width: 100%;
        right: -100%;
        opacity: 0;
        // border: 2px solid red;
        background: rgba(0, 0, 0, 0);
      }
    }
    // transition:.45s;
    // &:hover{
    //   right: 0;
    // }
  }
  .all-workspaces {
    position: fixed;
    // right: -105px;
    width: 120px;
    min-height: 60px;
    z-index: 0;
    // height: auto;
    // overflow: hidden;
    // border: 2px solid transparent;

    margin-bottom: 10px;
    .title {
      position: fixed;
      top: 376px;
      right: 0;
      width: 7px;
      height: 36px;
      // border: 2px solid red;
      z-index: -1;
      background: #00ffff;
    }
    .titleClose {
      display: none;
    }

    .container {
      position: fixed;
      top: 376px;
      max-width: 100%;
      z-index: 99;
      transition: 0.45s;
      background: rgba(0, 0, 0, 0.95);

      // border: 2px solid transparent;
      min-height: 56px;
      // overflow: hidden;
      // display:none;
      // width: 0;

      right: -100%;
      margin-bottom: 10px;

      ul {
        display: flex;
        min-height: 56px;
        padding-left: 10px;
        align-items: center;
        max-width: 100%;
        flex-wrap: wrap;
        // justify-content:space-between;
        padding: 10px 0 0 10px;
        .active {
          border: 2px solid #00ffff;
        }
        li {
          width: 120px;
          height: 45px;
          background: #fff;
          margin-right: 10px;
          margin-bottom: 10px;
          overflow: hidden;
          position: relative;

          .mask {
            // display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            text-align: center;
            line-height: 3;
            color: #fff;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .containerOpen {
      // display:block !important;
      // width: 100%;
      right: 0;
      // background: rgba(0, 0, 0, 0.95);
      animation-name: containopen;
      // animation-duration: 0.45s;
      animation-timing-function: linear;
      // border: 2px solid red;
    }
    .containerClose {
      // display:block !important;
      // width: 100%;
      right: -100%;
      // background: rgba(0, 0, 0, 0);
      animation-name: containclose;
      // animation-duration: 0.45s;
      animation-timing-function: linear;
      // border: 2px solid red;
    }
    @keyframes containopen {
      0% {
        // width: 0;
        right: -100%;
        opacity: 0;
        // border: 2px solid transparent;
        background: rgba(0, 0, 0, 0);
      }
      100% {
        // width: 100%;
        right: 0;
        opacity: 1;
        // border: 2px solid red;
        background: rgba(0, 0, 0, 0.95);
      }
    }
    @keyframes containclose {
      0% {
        // width: 0;
        right: 0%;
        opacity: 1;
        // border: 2px solid transparent;
        background: rgba(0, 0, 0, 0.95);
      }
      100% {
        // width: 100%;
        right: -100%;
        opacity: 0;
        // border: 2px solid red;
        background: rgba(0, 0, 0, 0);
      }
    }
    // transition:.45s;
    // &:hover{
    //   right: 0;
    // }
  }
  .workSpace {
    position: fixed;
    right: -72px;
    width: 76px;
    height: 34px;
    border: 1px solid #00ffff;
    border-right: none;
    border-left: 7px solid #00ffff;
    overflow: hidden;
    margin-bottom: 10px;
    transition: 0.45s;
    &:nth-child(1) {
      top: 192px;
    }
    &:nth-child(2) {
      top: 238px;
    }
    &:nth-child(3) {
      top: 284px;
    }
    // &:hover {
    //   right: 0;
    // }
    > .video-img {
      position: relative;
      cursor: pointer;
      height: 34px;

      .title-box {
        width: 100%;
        height: 100%;
        display: none;
        position: absolute;
        top: 0;
        > .title {
          position: absolute;
          // display: none;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @keyframes enters {
    0% {
      right: -76px;
      border: 2px solid #00ffff;
      border-left: 7px solid #00ffff;
      border-right: none;
    }
    100% {
      right: 0;
      // border: 1px solid transparent;
      border: 2px solid #00ffff;

      border-left: 2px solid #00ffff;
      border-right: none;
    }
  }
  @keyframes leaves {
    0% {
      right: 0px;
      border: 2px solid 00ffff;
      border-left: 2px solid 00ffff;
      border-right: none;
    }
    100% {
      right: -76px;
      border: 2px solid #00ffff;
      border-left: 7px solid #00ffff;
      border-right: none;
    }
  }
  .enters {
    animation-name: enters;
    animation-duration: 0.4s;
    animation-fill-mode: linear;
    right: 0;
    // border: 1px solid transparent;
    border: 2px solid #00ffff;
    border-right: none;
  }
  .leaves {
    animation-name: leaves;
    animation-duration: 0.4s;
    animation-fill-mode: linear;
    right: -76px;
    border: 1px solid #00ffff;
    border-left: 7px solid #00ffff;
    border-right: none;
  }
  .begin-enter {
    border: 2px solid #00ffff;
    border-right: none;
    animation: none;
    right: 0 !important;
  }
}
// .begin-enter {
//   border: none !important;

//   right: 0 !important;
// }
.work-video {
  right: -72px;
  width: 76px;
  height: 34px;
  border: 1px solid red;
  border-right: none;
  border-left: 14px solid red;
  // width: 120px;
  // height: 56px;
  margin-bottom: 10px;
  // border: 2px solid red;
  overflow: hidden;
  position: fixed;
  // right: -107px;
  transition: 0.45s;
  z-index: -1;

  &:nth-child(1) {
    top: 376px;
  }
  &:nth-child(2) {
    top: 422px;
  }
  &:nth-child(3) {
    top: 468px;
  }
  .title-box {
    display: none;
    > .title {
      position: absolute;
      // display: none;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .video-container {
    width: 100%;
    > img {
      width: 100%;
      height: 100%;
    }
  }
}
.title-block {
  display: block !important;
}

.hover-enter {
  width: 15px;
  background-color: transparent;
  height: 400px;
  position: absolute;
  z-index: 999;
  top: 40%;
  right: 0;
}

@keyframes workspaceMove {
  0% {
    right: -75px;
  }
  100% {
    right: 0;
  }
}
@keyframes workspaceLeave {
  0% {
    right: 0px;
  }
  100% {
    right: -75px;
  }
}

.workspaceMove {
  right: 0;
  animation-name: workspaceMove;
  animation-duration: 0.5s;
  animation-fill-mode: linear;
}

.workspaceLeave {
  right: -75px;
  animation-name: workspaceLeave;
  animation-duration: 0.5s;
  animation-fill-mode: linear;
}

.police {
  margin-left: 4px !important;
}

.muted {
  span {
    z-index: 999;
  }
  .closeVioce {
    color: #fff;
    position: absolute;
    left: 98%;
    background: #5c636e;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    top: 10px;
    font-size: 20px;
  }
  .muted-icon {
    color: #fff;
    position: absolute;
    font-size: 70px;
    background: #5c636e;
    border-radius: 50%;
    width: 95px;
    height: 95px;
    text-align: center;
    line-height: 95px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes metedMove {
  0% {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    width: 95px;
    height: 95px;
    line-height: 95px;
  }

  100% {
    left: 98%;
    top: 10px;
    width: 35px;
    height: 35px;
    font-size: 20px;
    line-height: 35px;
  }
}
.muted {
  .muted-move {
    color: #fff;
    position: absolute;
    left: 98%;
    background: #5c636e;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    top: 10px;
    font-size: 20px;
    transform: none;
    animation-name: metedMove;
    animation-duration: 2.5s;
    animation-fill-mode: linear;
  }

  .moves {
    color: #fff;
    position: absolute;
    left: 98%;
    background: #5c636e;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    top: 10px;
    font-size: 20px;
    transform: none;
  }
}
</style>
