<template>
  <div class="company-container">
    <div @mouseenter="companyIfBlock=true" @mouseleave="companyIfBlock=false">
      <ul class="company-nav">
        <li @click="close">{{companyInfoStatus?'关闭':'查看更多'}}</li>

        <li>公司简介</li>
        <li>
          <img alt src="../../../assets/images/icon/WechatIMG609.png" />
        </li>
      </ul>
      <div class="company-box">
        <transition name="company">
          <div @click="close" class="intro" v-show="companyIfBlock">
            <div class="intro-con">{{msg}}</div>
          </div>
        </transition>
      </div>
    </div>

    <div :class="[contactStatus?'active':'']" @click="openContact" class="contact">联系我们</div>

    <transition>
      <!-- <div :style="position" class="info-container" v-if="companyInfoStatus">
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="head"
        ></div>
        <div class="info-text">{{msg}}</div>
        <div class="image">
          <img alt src="../../../assets/images/companyIntro/图片框1.png" />
        </div>
        <span @click="close" class="close"></span>
      </div>-->
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import moveData from '../../../mixins/move'
import vm from '../../../assets/js/event'


export default {
  name: '',
  // mixins: [moveData],

  data () {
    return {
      msg: '',
      // companyInfoStatus: false
      companyIfBlock: true,
    }
  },
  created () {
  },
  mounted () {
    // this.getData()
    setTimeout(() => {
      this.companyIfBlock = false;
    }, 300000)
  },
  computed: {
    ...mapState(['contactStatus', 'companyInfoStatus', 'company'])

  },
  watch: {
    company (newValue, oldValue) {
      if (newValue) {
        this.getData()
      }
    },

  },

  methods: {
    openContact () {
      this.$store.state.contactStatus = !this.$store.state.contactStatus
    },
    close () {
      this.$store.state.companyInfoStatus = !this.$store.state.companyInfoStatus
      if (this.$store.state.companyInfoStatus) {
        vm.$emit("allIntro", this.msg)

      }
    },
    getData () {
      let params = {
        id: this.company.id
      }
      this.$request.get(this.$api.inquireDepartments, params).then(res => {
        console.log('inquireDepartments', res);
        this.msg = res.data.content[0].companyProfile
      })
    }
  }
}
</script>

<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 300px;
  }
}
@keyframes moves {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.company-enter-active {
  animation: moves 0.35s linear;
}
.company-leave-active {
  animation: moves 5s reverse;
}
.company-container {
  .company-nav {
    display: flex;
    height: 20px;
    align-items: center;
    > li {
      height: 20px;
      display: flex;
      align-items: center;
      &:nth-child(3) {
        margin-left: 8px;
      }
      &:nth-child(2) {
        font-size: 16px;
        color: #ffffff;
        position: relative;
        margin-left: 65px;
      }
      &:nth-child(1) {
        width: 79px;
        height: 17px;
        display: block;
        background: url(../../../assets/images/staff/circle.png) no-repeat;

        text-align: center;
        line-height: 17px;
        color: #fff;
        font-size: 12px;
        position: relative;
        margin-top: 0px;
        background-size: contain;
        backdrop-filter: blur(20px);
        // overflow: hidden;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        // margin-left: auto;
        &::before {
          content: '';
          position: absolute;
          display: block;
          height: 1px;
          width: 8px;
          background: #fff;
          top: 58%;
          right: -62px;
        }
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 62px;
          height: 1px;
          right: -62px;
          top: 58%;
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }
  .company-box {
    height: 75px;
    margin-top: 18px;
  }
  .intro {
    padding: 12px;
    margin-top: 18px;
    width: 100%;
    background: url(../../../assets/images/icon/WechatIMG595.png) no-repeat;
    height: 75px;
    font-size: 12px;
    color: #fff;
    backdrop-filter: blur(3px);
    &:hover {
      // background: url(../../../assets/images/icon/express.png) no-repeat;
    }

    // word-break: break-all;
    .intro-con {
      height: 51px;
      width: 216px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .info-container {
    position: fixed;

    width: 318px;
    height: 298px;
    color: #fff;

    overflow: hidden;
    background: url(../../../assets/images/companyIntro/背景框.png) no-repeat;
    backdrop-filter: blur(3px);
    .head {
      width: 100%;
      height: 30px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .info-text {
      swidth: 100%;
      height: 200px;
      padding: 40px 25px;
    }
    .image {
      display: flex;
      justify-content: center;
    }
    .close {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      z-index: 999;
    }
  }
  .contact {
    margin-top: 16px;
    width: 236px;
    backdrop-filter: blur(5px);

    background: url(../../../assets/images/icon/WechatIMG614.png) no-repeat;
    height: 48px;
    cursor: pointer;
    font-size: 16px;
    line-height: 48px;
    color: #fff;
    // text-align: center;
    padding-left: 104px;
    transition: 0.55s;
    &:hover {
      background: url(../../../assets/images/icon/WechatIMG615.png) no-repeat;
    }
  }
  .active {
    background: url(../../../assets/images/icon/WechatIMG615.png) no-repeat;
  }
  .contact-info {
    width: 773px;
    height: 338px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: url(../../../assets/images/aboutUs/联系我们.png) no-repeat;
  }
}
</style>
