<template>
  <div class="select">
    <transition>
      <div :style="position" class="list" v-if="selectStatus">
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="head"
        ></div>
        <ul>
          <li @click.stop="orderPage">我的订单</li>
          <li @click.stop="billOrAddress">发票/地址信息</li>
          <li @click.stop="changePassword">修改密码</li>
          <li @click.stop="loginOut">退出</li>
        </ul>
        <span @click="close" class="close"></span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// @import
import moveData from '../../../mixins/move'

export default {
  components: {},
  mixins: [moveData],
  props: {},
  data () {
    return {

    };
  },

  created () {

  },
  mounted () {

  },
  computed: {
    ...mapState(['selectStatus']),


  },
  watch: {
    selectStatus (newValue, oldValue) {
      if (newValue) {
        console.log('width', document.body.clientWidth, this.$refs.announcement);
        this.x = (document.body.clientWidth - 318) / 2
        this.y = 80
      } else {
        return
      }
    }
  },
  methods: {
    close (event) {
      this.$store.state.selectStatus = !this.$store.state.selectStatus

    },
    orderPage () {
      this.close()
      this.$store.state.orderListStatus = !this.$store.state.orderListStatus

    },
    loginOut () {

      sessionStorage.removeItem('userInfo')
      localStorage.removeItem('userInfo')

      this.$store.state.userLoginStatus = false;
      sessionStorage.setItem("userLoginStatus", false)
      localStorage.setItem("userLoginStatus", false)

      this.$store.state.selectStatus = false;
    },
    changePassword () {
      this.$store.state.passwordStatus = !this.$store.state.passwordStatus
      this.$store.state.selectStatus = false;
    },
    billOrAddress () {
      this.$store.state.billAddressStatus = !this.$store.state.billAddressStatus
      this.$store.state.selectStatus = false


    }
  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 278px;
  }
}
.list {
  position: fixed;

  width: 301px;
  height: 278px;
  background: url(../../../assets/images/message/背景框.png) no-repeat;
  background-size: cover;
  backdrop-filter: blur(5px);
  overflow: hidden;
  .head {
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .close {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 999;
  }
  > ul {
    width: 100%;
    height: 100%;
    padding: 30px 15px;

    li {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding-left: 15px;
      margin-bottom: 15px;
      color: #fff;
      background: url(../../../assets/images/login/密码登录-输入框.png)
        no-repeat;
    }
  }
}
</style>