<template>
  <div class="contact">
    <transition>
      <div :style="position" class="container" v-if="contactStatus">
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="heads"
        ></div>
        <div class="animate">
          <div class="part-1">
            <div class="head">请留下您的联系方式，稍后客服会联系您。</div>
            <ul class="message">
              <li>
                <span>姓名</span>
                <input placeholder="请留下您的大名" type="text" v-model="formdata.name" />
              </li>
              <li>
                <span>手机号</span>
                <input
                  @onkeyup="value=value.replace(/[^\d]/g,0)"
                  placeholder="请留下您的手机号"
                  type="text"
                  v-model="formdata.phone"
                />
              </li>
              <li>
                <span>留言</span>
                <input placeholder="请留言" type="text" v-model="formdata.spare" />
              </li>
            </ul>
            <div @click="confirm" class="confirm">提交</div>
          </div>
          <div class="part-2">
            <div class="head">或者您可以直接联系我们</div>
            <div class="intro">
              <ul>
                <li>
                  <span>公司电话:</span>
                  <span>{{company.specialLine}}</span>
                </li>
                <li>
                  <span>经理电话:</span>
                  <span>{{company.phone}}</span>
                </li>
                <li>
                  <span>工厂地址:</span>
                  <span>{{company.address}}</span>
                </li>
                <li>
                  <span>微信添加:</span>
                  <span></span>
                </li>
                <li class="wechat-img">
                  <img :src="company.wechatQRCode" alt="图片加载失败" />
                </li>
              </ul>
            </div>
            <span @click="closeContact" class="close"></span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @import
import { mapState } from 'vuex'
import moveData from '../../../mixins/move'

export default {
  components: {},
  mixins: [moveData],

  props: {},
  data () {
    return {
      formdata: {
        name: "",
        phone: '',
        spare: '',
        dept: {
          id: "",
        }
      }
    };
  },

  created () {

  },
  mounted () {

  },
  computed: {
    ...mapState(['contactStatus', 'company'])
  },
  watch: {
    contactStatus (newValue, oldValue) {
      if (newValue) {
        console.log('width', document.body.clientWidth, this.$refs.announcement);
        this.x = (document.body.clientWidth - 773) / 2
        this.y = 80
      } else {
        return
      }
    }
  },
  methods: {
    closeContact () {
      this.$store.state.contactStatus = !this.$store.state.contactStatus
    },
    confirm () {
      this.formdata.dept.id = this.company.id
      // console.log('id555', this.formdata);

      if (this.formdata.name !== '' && this.formdata.phone !== '') {
        this.$request.post(this.$api.customerInfo, this.formdata).then(res => {
          console.log('reooooo', res);

          this.$message({
            message: '已收到，稍后客户联系您！',
            type: 'success'
          });
          // window._agl && window._agl.push(['track', ['success', { t: 3 }]])
          setTimeout(() => {
            this.$store.state.contactStatus = false
            this.formdata = {
              name: "",
              phone: '',
              spare: ''
            }
          }, 1500)

        })
      } else {
        this.$message({
          message: '请填入姓名和手机号',
          type: 'warning'
        });
      }
    },
  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 388px;
  }
}

.contact {
  .container {
    width: 773px;
    height: 388px;
    position: fixed;

    overflow: hidden;
    backdrop-filter: blur(5px);
    background: url(../../../assets/images/aboutUs/WechatIMG340.png) no-repeat;
    .heads {
      width: 100%;
      height: 30px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .animate {
      padding: 45px 30px 30px 30px;
      display: flex;
      justify-content: space-between;
      > div {
        flex-basis: 50%;
      }
    }
  }

  .part-1 {
    padding-right: 20px;
    .head {
      color: #ffffff;
      margin-top: 15px;
      padding-left: 2px;
    }
    .message {
      margin-top: 16px;
      margin-bottom: 50px;
      margin-left: 4px;
      > li {
        display: flex;
        align-items: center;
        height: 45px;
        border-bottom: 1px solid #ccc;
        color: #fff;
        > span {
          display: inline-block;
          width: 50px;
          color: #d3fea3;
        }
        > input {
          height: 25px;
          border: none;
          outline: none;
          background: transparent;
        }
        > input::-webkit-input-placeholder {
          color: #fff;
        }
      }
    }
    .confirm {
      width: 149px;
      height: 41px;
      line-height: 41px;
      color: #fff;
      text-align: center;
      background: url(../../../assets/images/aboutUs/按钮.png) no-repeat;
    }
  }
  .part-2 {
    // background: url(../../../assets/images/aboutUs/过渡框.png) no-repeat;
    .head {
      height: 42px;
      line-height: 42px;
      color: #fff;
      padding-left: 25px;
    }
    .intro {
      padding: 20px 25px;
      ul {
        .wechat-img {
          width: 120px;
          height: 120px;
          position: relative;
          > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            // position: absolute;
            // left: 0;
            // top: 0;
            // transform: scale(.2);
          }
        }
        li {
          color: #fff;
          margin-bottom: 10px;
          span {
            display: inline-block;
            width: 65px;
            white-space: nowrap;
          }
        }
      }
    }
    .close {
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
    }
  }
}
</style>