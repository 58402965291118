<template>
  <div class="password">
    <transition>
      <div :style="position" class="password-con" v-if="passwordStatus">
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="heads"
        ></div>
        <div class="password-box">
          <div class="head">修改密码</div>
          <ul class="input-box">
            <li>
              <input @input="passwordText" placeholder="请输入密码" type="text" v-model="password1" />
              <span class="hint" ref="password1">{{password1==''?'请输入密码':''}}</span>
            </li>
            <li>
              <input
                :class="[disabled?'disableds':'']"
                :disabled="disabled"
                placeholder="请确认密码"
                type="text"
                v-model="password2"
              />
              <span
                class="hint"
                ref="password2"
              >{{password2==''?'请再次输入密码':password2 == password1?'':'两次输入密码不一致'}}</span>
            </li>

            <li>
              <button @click="confirm">确认</button>
            </li>
            <transition name="success">
              <li class="change-success" v-if="success">修改成功,请重新登录!</li>
            </transition>
          </ul>
        </div>
        <span @click="close" class="close"></span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex"
import moveData from '../../../mixins/move'


export default {
  components: {},
  mixins: [moveData],

  props: {},
  data () {
    return {
      password1: '',
      password2: '',
      disabled: true,
      success: false
    };
  },

  created () {

  },
  mounted () {

  },
  computed: {
    ...mapState(['passwordStatus', 'userInfo'])

  },
  watch: {
    passwordStatus (newValue, oldValue) {
      if (newValue) {
        console.log('width', document.body.clientWidth, this.$refs.announcement);
        this.x = (document.body.clientWidth - 318) / 2
        this.y = 80
      } else {
        return
      }
    }
  },
  methods: {
    close () {
      this.$store.state.passwordStatus = !this.$store.state.passwordStatus
      // this.$store.state.selectStatus = true;

    },
    passwordText () {
      if (this.password1 !== '') {
        this.disabled = false;
        this.$refs.password1.style.display = 'none';
      } else {
        this.disabled = true;
      }
    },
    confirm () {

      if (this.password1 !== '') {
        this.$refs.password1.style.display = 'none';

        if (this.password1 == this.password2) {
          this.$refs.password2.style.display = 'none';

          let id = this.userInfo.enterpriseUserDTO.id;
          console.log('chengg', id);
          this.$request.put(this.$api.passwordChange, { id: id, password: this.password1 }).then(res => {
            console.log('chengg', res);

            this.success = true;

            setTimeout(() => {
              window.sessionStorage.removeItem('userInfo')
              sessionStorage.setItem("userLoginStatus", false)
              this.$store.state.userLoginStatus = false;
              this.$store.state.passwordStatus = false;
              this.$store.state.loginStatus = true;
              this.success = false;
              this.password1 = ''
              this.password2 = ''
            }, 1500)




          })


        } else {
          this.$refs.password2.style.display = 'block';
          console.log('chenggshibai');

        }

      } else {
        this.$refs.password1.style.display = 'block';

      }
    }
  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 278px;
  }
}
@keyframes moves {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 进入动画 */
.success-enter-active {
  animation: moves 0.25s linear;
}

/* 离开动画 */
.success-leave-active {
  animation: moves 0.45s reverse;
}
.password-con {
  position: fixed;

  width: 301px;
  height: 278px;
  background: url(../../../assets/images/message/背景框.png) no-repeat;
  background-size: cover;
  backdrop-filter: blur(5px);
  overflow: hidden;
  .heads {
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .close {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 999;
  }
  .password-box {
    width: 100%;
    height: 100%;
    padding: 25px 15px;

    .head {
      height: 49px;
      line-height: 49px;
      color: #fff;
      font-size: 18px;
    }

    .input-box {
      position: relative;
      .change-success {
        position: absolute;
        top: 0;
        width: 100%;
        height: 177px;
        line-height: 177px;
        text-align: center;
        background: #8f4833;
        margin-top: 0;
        color: #fff;
      }
      li {
        position: relative;
        .hint {
          position: absolute;
          left: 15px;
          bottom: -2px;
          color: red;
          font-size: 12px;
          display: none;
        }
        .disableds {
          cursor: not-allowed !important;
        }
      }
      input {
        width: 100%;
        height: 42px;
        line-height: 42px;
        padding-left: 15px;
        background: url(../../../assets/images/login/密码登录-输入框.png)
          no-repeat;

        background-size: cover;
        margin-bottom: 15px;
        // outline: none;
        // border: none;
      }
      button {
        height: 43px;
        width: 100%;
        margin-top: 20px;
        text-align: center;
        line-height: 43px;
        color: #fff;
        background: url(../../../assets/images/login/密码登录-登录按钮.png)
          no-repeat;
      }
    }
  }
}
</style>