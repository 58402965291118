<template>
  <div class="register">
    <transition>
      <div class="reg-box" v-if="regStatus">
        <span @click="close" class="close"></span>
        <div class="animate">
          <div class="head">
            <span>注册</span>
          </div>
          <div class="container">
            <ul class="step">
              <li>
                <div :class="{'icon-success':currentActive>=1}" class="icon"></div>
                <div class="text">用户信息</div>
                <div :class="[currentActive>=1?'line-success':'']" class="line"></div>
              </li>
              <li>
                <div
                  :class="[currentActive==1?'icon-enter':currentActive==2?'icon-success':'']"
                  class="icon"
                ></div>
                <div class="text">开票信息</div>
                <div :class="[currentActive>=2?'line-success':'']" class="line"></div>
              </li>
              <li>
                <div
                  :class="[currentActive==2?'icon-enter':'',finish?'icon-success':'']"
                  class="icon"
                ></div>
                <div class="text">收货信息</div>
              </li>
            </ul>
            <ul class="form" v-if="currentActive == 0">
              <li>
                <input placeholder="用户名" type="text" v-model="info.userName" />
              </li>
              <li>
                <input placeholder="邮箱" type="text" v-model="info.email" />
              </li>
              <li>
                <input
                  @blur="phoneTest"
                  @input="phoneTest"
                  placeholder="手机号/必填"
                  type="text"
                  v-model="info.loginPhone"
                />
                <span class="hint" ref="phone">{{truePhone?'请输入正确的手机号':ifNumber?'该手机号已被注册':''}}</span>
              </li>
              <li class="code">
                <input placeholder="验证码/必填" type="text" v-model="vcode" />
                <button :class="[codeStatus?'code-status':'']" @click="sendCode">{{buttonName}}</button>
                <span class="hint" ref="code">{{codeText}}</span>
              </li>
              <li>
                <input placeholder="密码/必填" type="text" v-model="info.password" />
                <span class="hint" ref="password">请输入密码</span>
              </li>
            </ul>
            <ul class="form form2" v-if="currentActive == 1">
              <li>
                <input placeholder="企业名称" type="text" v-model="info.enterpriseInfos[0].name" />
              </li>
              <li>
                <input
                  @input="formatCreditCode(info.enterpriseInfos[0].creditCode)"
                  placeholder="信用代码"
                  type="text"
                  v-model="info.enterpriseInfos[0].creditCode"
                />
              </li>
              <li>
                <input
                  placeholder="注册地址"
                  type="text"
                  v-model="info.enterpriseInfos[0].registerAddress"
                />
              </li>
              <li>
                <input placeholder="注册电话" type="text" v-model="info.enterpriseInfos[0].phone" />
              </li>
              <li>
                <input
                  placeholder="开户行"
                  type="text"
                  v-model="info.enterpriseInfos[0].accountOpening"
                />
              </li>
              <li>
                <input
                  @input="formatBankAccount(info.enterpriseInfos[0].bankAccount)"
                  placeholder="银行账号"
                  type="text"
                  v-model="info.enterpriseInfos[0].bankAccount"
                />
              </li>
            </ul>
            <ul class="form form3" v-if="currentActive == 2">
              <li>
                <input placeholder="收货人/必填" type="text" v-model="info.enterpriseAddresses[0].name " />
                <span class="hint" ref="name">请输入收货人</span>
              </li>
              <li>
                <input
                  placeholder="店名"
                  type="text"
                  v-model="info.enterpriseAddresses[0].companyName"
                />
              </li>
              <li>
                <input
                  placeholder="收货电话/必填"
                  type="text"
                  v-model="info.enterpriseAddresses[0].phone"
                />
                <span class="hint" ref="telephone">请输入收货人电话</span>
              </li>
              <li>
                <input
                  placeholder="收货地址/必填"
                  type="text"
                  v-model="info.enterpriseAddresses[0].address"
                />
                <span class="hint" ref="address">请输入收货人地址</span>
              </li>
              <!-- <li>
                <transition name="address">
                  <div class="success" v-if="finished">注册成功,正在审核!</div>
                </transition>
              </li>-->
              <transition name="address">
                <li class="success" v-if="finished">注册成功,正在审核!</li>
              </transition>
            </ul>

            <div class="edit">
              <span @click="back" ref="back" v-if="currentActive>0">返回</span>
              <button
                :class="[!truePhone?'':'next-disabled']"
                @click="next"
                class="next"
                ref="next"
              >{{currentActive==2?'立即注册':'下一步'}}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @import
import { mapState } from 'vuex'

export default {
  components: {},
  props: {},
  data () {
    return {
      currentActive: 0,
      finish: false,
      finished: false,
      vcode: '',
      truePhone: false,
      ifNumber: false,//手机号是否已被注册
      codeStatus: true,//发送验证码状态
      time: 60,//发送验证码倒计时
      buttonName: "获取验证码",
      codeText: '请输入验证码',
      nextStatus: true,
      info: {
        dept: {
          id: ""
        },
        loginPhone: "",
        password: "",
        userName: "",
        email: "",
        enterpriseInfos: [{
          accountOpening: "",
          bankAccount: "",
          phone: "",
          creditCode: "",
          name: "",
          registerAddress: "",
        }],
        enterpriseAddresses: [{
          companyName: "",
          name: "",
          address: "",
          phone: "",
        }],
      },
    };
  },

  created () {

  },
  mounted () {

  },
  computed: {
    ...mapState(['regStatus', "company"])

  },
  watch: {
    truePhone (newValue, oldValue) {
      if (newValue) {
        this.$refs.phone.style.display = "block";
      }
    }
  },
  filters: {
    formatText (str) {
      this.info.enterpriseInfos[0].creditCode = str.replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").toUpperCase()
    },
  },
  methods: {
    close () {
      this.$store.state.regStatus = !this.$store.state.regStatus

    },
    formatCreditCode (str) {
      this.info.enterpriseInfos[0].creditCode = str.replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").toUpperCase()
    },
    formatBankAccount (str) {
      this.info.enterpriseInfos[0].bankAccount = str.replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").toUpperCase()
    },
    // 验证手机号及该手机号是否注册
    phoneTest () {
      console.log('status');

      if (this.info.loginPhone !== '') {
        let reg = /^1[34578]\d{9}$/
        let status = reg.test(this.info.loginPhone);
        console.log('status', status);

        if (status) {
          this.truePhone = false

          let url = this.$api.ifNumber + this.info.loginPhone;
          this.$request.get(url).then((res) => {
            console.log('11', res);
            if (res.status == 200) {
              this.codeStatus = false;//获取验证码的权限打开
              this.$refs.phone.style.display = 'none'
              this.$refs.next.style.display = 'block'

            } else if (res.status == 201) {
              this.ifNumber = true;
              // this.truePhone = true
              this.$refs.next.style.display = 'none'

              this.$refs.phone.style.display = 'block'
            }
          })




          // this.truePhone = false


          return
        } else {
          this.truePhone = true
          this.codeStatus = true;//获取验证码的权限关闭

          this.$refs.phone.style.display = 'block'

        }
      } else {
        this.truePhone = true
        this.ifNumber = false;
        this.codeStatus = true;//获取验证码的权限关闭
        this.$refs.phone.style.display = 'block'

      }
    },
    //发送验证码
    sendCode () {
      console.log('haha ',);


      if (!this.codeStatus && this.info.loginPhone !== '') {

        let data = {
          phoneNumber: this.info.loginPhone
        }
        this.$request.get(this.$api.sendMessage, data).then(res => {
          console.log('send', res);
          var me = this;
          this.codeStatus = true;
          var interval = window.setInterval(function () {
            me.buttonName = "已发送" + me.time;
            --me.time;
            if (me.time < 0) {
              me.buttonName = "重新发送";
              me.time = 60;
              me.codeStatus = false;
              window.clearInterval(interval);
            }
          }, 1000);

        })
      }


    },
    next () {
      // this.info.dept.id = this.company.id

      // console.log('id555', this.info);

      switch (this.currentActive) {
        case 0:
          if (!this.truePhone && this.vcode !== '' && this.info.password !== '') {
            this.$refs.password.style.display = 'none'
            this.$refs.code.style.display = 'none'
            this.$refs.phone.style.display = 'none'

            console.log('jinglaile');

            // let reg = /^1[34578]\d{9}$/
            // let status = reg.test(this.info.loginPhone);
            // console.log('status1', status);
            // if (!status) {
            //   this.truePhone = true;
            //   return
            // }


            this.$request.post(this.$api.codeTest + '?vcode=' + this.vcode + '&phoneNumber=' + this.info.loginPhone).then(res => {

              this.nextStatus = true
              console.log('cuole4', res);

              this.currentActive++

              // this.finish = false

            }).catch(err => {
              this.currentActive = 0

              console.log('cuole', err);
              this.codeText = '验证码有误'
              if (this.$refs.code) {
                this.$refs.code.style.display = 'block';

              }

            })




          } else {
            if (!this.truePhone) {
              this.$refs.phone.display = 'none';
            } else {
              let phone = this.$refs.phone;
              this.hint(this.info.loginPhone, phone)

            }
            // this.truePhone = true;
            // this.nextStatus = false
            // 
            // if (this.info.password !== '') {
            //   this.$refs.password.style.display = 'none'
            // } else {
            //   this.$refs.password.style.display = 'block'
            // }
            let code = this.$refs.code;
            let password = this.$refs.password;
            this.hint(this.vcode, code)
            this.hint(this.info.password, password)
          }
          break;

        case 1:
          this.currentActive++

        case 2:
          this.info.dept.id = this.company.id
          if (this.info.enterpriseAddresses[0].name !== '' && this.info.enterpriseAddresses[0].phone !== '' && this.info.enterpriseAddresses[0].address) {
            this.$refs.name.style.display = 'none'
            this.$refs.telephone.style.display = 'none'
            this.$refs.address.style.display = 'none'

            console.log('reggg', this.info);
            this.$request.post(this.$api.regapi, this.info).then(res => {
              console.log('reggg', res);

              this.finish = true
              this.finished = true
              this.$refs.next.style.display = 'none'
              this.$refs.back.style.display = 'none'
              setTimeout(() => {
                this.finish = false
                this.finished = false
                this.$store.state.regStatus = false
                this.$store.state.loginStatus = true
                this.$refs.next.style.display = 'block'
                this.$refs.back.style.display = 'block'
                this.currentActive = 0
                this.info = {
                  loginPhone: "",
                  password: "",
                  userName: "",
                  email: "",
                  enterpriseInfos: [{
                    accountOpening: "",
                    bankAccount: "",
                    phone: "",
                    creditCode: "",
                    name: "",
                    registerAddress: "",
                  }],
                  enterpriseAddresses: [{
                    companyName: "",
                    name: "",
                    address: "",
                    phone: "",
                  }],
                }
                this.vcode = ''


              }, 1500)
            })




          } else {

            let name = this.$refs.name;
            let telephone = this.$refs.telephone;
            let address = this.$refs.address;
            this.hint(this.info.enterpriseAddresses[0].name, name)
            this.hint(this.info.enterpriseAddresses[0].phone, telephone)
            this.hint(this.info.enterpriseAddresses[0].address, address)
          }


      }



    },
    hint (datas, refName) {
      if (datas == '' && refName) {
        refName.style.display = 'block'
      } else if (refName) {
        refName.style.display = 'none'
      }
    },
    back () {

      if (this.currentActive <= 0) {
        return
      } else {
        this.currentActive--
      }
    }
  },
}
</script>
<style lang='less' scoped>
@import '../../../style/index.css';
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 550px;
  }
}

@keyframes moves {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 进入动画 */
.address-enter-active {
  animation: moves 0.25s linear;
}

/* 离开动画 */
.address-leave-active {
  animation: moves 0.45s reverse;
}

.reg-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 30px auto;
  width: 316px;
  height: 550px;
  background: url(../../../assets/images/reg/背景.png) no-repeat;
  backdrop-filter: blur(5px);
  overflow: hidden;
  .close {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
  }
  .animate {
    height: 100%;
    padding-top: 20px;
    color: #ffffff;
    .head {
      height: 30px;
      line-height: 30px;
      padding-left: 23px;
      border-bottom: 1px solid #ccc;
      color: #ffffff;
      > span {
        display: inline-block;
        position: relative;
        &::after {
          display: inline-block;
          content: '';
          width: 40px;
          height: 1px;
          background-color: #fff;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
    .container {
      padding: 49px 23px;
      position: relative;
      height: calc(100% - 40px);
      .step {
        display: flex;
        justify-content: space-between;
        li {
          width: 68px;
          height: 45px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          .icon {
            width: 17px;
            height: 17px;
            margin-bottom: 10px;
            background: url(../../../assets/images/reg/过程点2.png) no-repeat;
          }
          &:nth-child(1) {
            .icon {
              background: url(../../../assets/images/reg/WechatIMG643.png)
                no-repeat;
            }
          }
          .icon-enter {
            background: url(../../../assets/images/reg/WechatIMG643.png)
              no-repeat !important;
          }
          .icon-success {
            background: url(../../../assets/images/reg/过程点1.png) no-repeat !important;
          }
          .line {
            width: 52px;
            height: 2px;
            background: url(../../../assets/images/reg/过程线2.png) no-repeat;
            position: absolute;
            right: -44px;
            top: 7px;
          }
          .line-success {
            background: url(../../../assets/images/reg/过程线1.png) no-repeat !important;
          }
        }
      }
      .form {
        > li {
          margin-top: 20px;
          position: relative;
          // &::after {
          //   display: inline-block;
          //   content: '123';
          //   width: 50px;
          //   height: 30px;
          //   position: absolute;
          //   bottom: -30px;
          //   left: 15px;
          //   color: red;
          // }
          .hint {
            position: absolute;
            left: 15px;
            bottom: -20px;
            color: red;
            font-size: 12px;
            display: none;
          }
          input {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding-left: 15px;
            background: url(../../../assets/images/login/密码登录-输入框.png)
              no-repeat;
            position: relative;
            // outline: none;
            // border: none;
          }

          button {
            width: 110px;
            color: #fff;
            background: url(../../../assets/images/login/验证码登录-验证码发送按钮.png)
              no-repeat;
          }
        }
        .code {
          display: flex;
          justify-content: space-between;

          .code-status {
            cursor: not-allowed;
            color: #ccc;
          }
          > input {
            width: 146px;
            height: 42px;
            background: url(../../../assets/images/login/验证码登录-验证码输入框.png)
              no-repeat;
          }
          button {
            width: 110px;
            color: #fff;
            background: url(../../../assets/images/login/验证码登录-验证码发送按钮.png)
              no-repeat;
          }
        }
      }
      .form2 {
        > li {
          margin-top: 10px;
        }
      }
      .form3 {
        position: relative;
        .success {
          position: absolute;
          top: 0;
          width: 100%;
          height: 280px;
          line-height: 280px;
          text-align: center;
          background: #8f4833;
          margin-top: 0;
          // display: none;
        }
        li {
          position: relative;
          // position: initial;
          .success {
            position: absolute;
            top: 0;
            width: 100%;
            height: 280px;
            line-height: 280px;
            text-align: center;
            background: #8f4833;
            margin-top: 0;
            // display: none;
          }
        }
      }
      .edit {
        width: 270px;
        position: absolute;
        bottom: 30px;
        margin-top: 25px;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .next-disabled {
          cursor: not-allowed;
          pointer-events: none;
          color: #ccc;
        }
        > span {
          display: inline-block;
          position: relative;
          &::before {
            display: inline-block;
            content: '';
            width: 14px;
            height: 14px;
            background: url(../../../assets/images/reg/返回标.png) no-repeat;
            position: absolute;
            left: -14px;
            top: 5px;
          }
        }
        .next {
          width: 135px;
          height: 41px;
          line-height: 41px;
          text-align: center;
          margin-left: auto;
          background: url(../../../assets/images/reg/下一步按钮.png) no-repeat;
        }
      }
    }
  }
}
</style>