<template>
  <div class="containers">
    <!-- <videoPage></videoPage> -->

    <commonPage></commonPage>
  </div>
</template>

<script>
import commonPage from '../commonPages'
import initData from '../../mixins/initData'


export default {
  name: '',
  // mixins: [initData],

  components: {
    commonPage
  },
  data () {
    return {
      msg: '123',
      dataList: []
    }
  },
  created () {
    this.$nextTick(() => {
      // this.init();
      this.whichCompany()
    })
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    // beforeInit () {
    //   this.apiUrl = this.$api.inquireDepartments
    //   this.methods = 'get'
    // },
    // async whichCompany () {
    //   let companyName = this.$route.name;
    //   await this.init()
    //   if (this.dataList.length > 0) this.dataList.forEach(item => {
    //     if (item.alias == companyName) {
    //       this.$store.commit('setCompany', item)
    //     }
    //   })
    //   console.log('this.$store.state.company1', this.$store.state.company);
    // },
    whichCompany () {
      let companyName = this.$route.name;
      let params = {
        sort: 'sort,asc'

      }
      this.$request.get(this.$api.inquireDepartments, params).then((res) => {
        console.log('woshitb23477777', res.data.content);
        this.dataList = res.data.content;
        this.$store.commit('setCompanyList', res.data.content);
        if (this.dataList.length > 0) this.dataList.forEach(item => {
          if (item.alias == companyName) {
            this.$store.commit('setCompany', item)
          }
        })
      })
    }

  }
}
</script>

<style lang='less' scoped>
@media screen and(max-width: 1919px) {
  .containers {
    width: 100%;
  }
}
@media screen and (min-width: 1920px) {
  .containers {
    width: 1920px;
  }
}
.containers {
  margin: 0 auto;
}
</style>
