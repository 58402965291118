<template>
  <div class="header-container">
    <div></div>
    <div class="borders">
      <div class="logo">
        <!-- <img alt src="../../../assets/images/1801637026767_.pic_hd.jpg" /> -->
        <img :src="company.logo" alt />

        <span class="name">{{company.cohr}}</span>
      </div>

      <div class="edit">
        <div class="time">
          <div>{{time}}</div>
          <div class="date">{{date}}</div>
        </div>
        <div class="head">
          <!-- v-permission="{action:userLoginStatus}" -->
          <span @click="selectBox" class="iconfont icon-touxiang" v-if="userLoginStatus"></span>
          <span @click="loginBox" class="login-text" v-if="!userLoginStatus">登录</span>
        </div>
        <div class="name">
          <div
            @click="selectBox"
            class="login-name"
            v-if="userLoginStatus"
          >{{userInfo?userInfo.enterpriseUserDTO.userName:''}}</div>
          <div
            @click="selectBox"
            class="company-name"
            v-if="userLoginStatus"
          >{{address.length>0?address[addressIndex].companyName:''}}</div>
          <span @click="register" v-if="!userLoginStatus">注册</span>
        </div>
        <div @click="fullScreen" class="screen">
          <img :src="require('../../../assets/images/icon/'+screenSrc)" alt />
        </div>
        <div @click="controlVoice" class="voice">
          <img :src="require('../../../assets/images/icon/'+voiceSrc)" alt />
        </div>
      </div>

      <div class="heng-border-left">
        <div class="border-one"></div>
        <div class="border-two"></div>
        <div class="border-three"></div>
      </div>
      <div class="heng-border-right">
        <div class="border-one"></div>
        <div class="border-two"></div>
        <div class="border-three"></div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: '',
  data () {
    return {
      msg: '',

      screenSrc: 'screenOpen.png', //全屏icon
      voiceSrc: 'novoice.png', //声音icon
      flags: false, //全屏 状态
      time: '',//时间
      date: '',//日期

    }
  },
  created () {
  },
  mounted () {
    console.log('lk', this.company);

    // 解决全屏esc后 按钮状态不变  监听全屏下的esc
    let _this = this;
    window.onresize = function () {

      if (!_this.esc()) {
        //触发esc事件，执行业务逻辑。
        debugger
        _this.flags = false;
        _this.screenSrc = 'screenOpen.png'

      }
    }

    // 获取时间

    setInterval(() => {
      this.getDateAndTime()
    }, 1000)


  },
  computed: {
    ...mapState(['company', 'userLoginStatus', 'userInfo', 'loginStatus', 'bills', 'address', 'indexs', 'addressIndex'])
  },
  watch: {

  },
  methods: {

    //获取时间和日期
    getDateAndTime () {
      var d = new Date();
      var a = d.getHours()//获取小时
      var b = d.getMinutes()//获取分钟
      var c = d.getSeconds()//获取秒
      var year = d.getFullYear() //
      var month = d.getMonth() + 1
      var day = d.getDate()
      if (a < 10) {
        a = "0" + a
      }
      if (b < 10) {
        b = "0" + b
      }
      if (c < 10) {
        c = "0" + c
      }
      this.time = a + ':' + b + ':' + c
      this.date = year + '.' + (month >= 10 ? month : '0' + month) + '.' + (day >= 10 ? day : '0' + day)
    },
    loginBox () {
      this.$store.state.loginStatus = !this.$store.state.loginStatus;
      this.$store.state.regStatus = false
      // this.$store.state.moreAnnouncmentStatus = false;


    },
    selectBox () {
      // this.$store.state.regStatus = !this.$store.state.regStatus
      this.$store.state.selectStatus = !this.$store.state.selectStatus
      this.$store.state.passwordStatus = false

    },
    register () {
      this.$store.state.regStatus = !this.$store.state.regStatus
      this.$store.state.loginStatus = false
    },
    // 全屏
    fullScreen () {
      if (this.screenSrc == 'screenOpen.png') {
        this.screenSrc = 'closeScreen.png'
      } else {
        this.screenSrc = 'screenOpen.png'
      }
      if (!this.flags) {
        var el = document.documentElement,
          rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;

        console.log('yuyu', rfs, typeof rfs);

        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
          this.flags = true;
          // this.escStatus = false;
        }
        return;
      } else {
        var el = document,
          cfs = el.cancelFullScreen || el.webkitCancelFullScreen || el.mozCancelFullScreen || el.exitFullScreen;
        if (typeof cfs != "undefined" && cfs) {
          cfs.call(el);
          this.flags = false;
        }
        return;
      }
    },
    // 控制声音开关
    controlVoice () {
      let video = document.querySelector('video');
      console.log('mutedssss', video.muted);
      if (video.muted == true) {
        video.muted = false;
        this.voiceSrc = 'voiceOpen.png'
      } else {
        video.muted = true;
        this.voiceSrc = 'novoice.png'
      }
    }
  }
}
</script>

<style lang='less' scoped>
@import '../../../style/common.less';
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 50px;
  backdrop-filter: blur(5px);

  .borders {
    display: flex;
    justify-content: space-between;
    .logo {
      padding-left: 15px;
      display: flex;
      align-items: center;
      img {
        height: 44px;
        transform: scale(0.65);
        margin-left: -20px;
      }
      .name {
        font-size: 20px;
        color: #fff;
        margin-left: 10px;
        font-weight: 400;
      }
    }

    .edit {
      width: auto;
      display: flex;
      align-items: center;
      font-size: 18px;
      // color: #fff;
      > div {
        height: 50px;
        margin-left: 20px;
        margin-right: 20px;
      }

      .time {
        color: rgba(0, 0, 0, 0.5);
        width: 78px;
        text-align: right;
        margin-left: 0;
        position: relative;
        > div {
          width: 78px;
          &:nth-child(1) {
            font-size: 20px;
            margin-top: 4px;
          }
        }
        .date {
          font-size: 12px;
          margin-top: -7px;
        }
        &::after {
          content: '';
          display: block;
          height: 30px;
          width: 2px;
          background-color: rgba(255, 255, 255, 0.25);
          position: absolute;
          top: 12px;
          right: -22px;
        }
      }
      .name {
        width: auto;
        height: 55px;
        margin-left: 0;
        text-align: right;
        // margin-top: 6px;
        .login-name {
          margin-top: 6px;
        }
        > .company-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          margin-top: -4px;
        }
      }
      .head {
        line-height: 50px;
        .iconfont {
          font-size: 34px;
          color: #d1d1d1;
        }
        .login-text {
          display: inline-block;
          width: 40px;
          font-size: 18px;
          color: #d1d1d1;
        }
      }
      .name {
        color: #d1d1d1;
        span {
          display: inline-block;
          width: 100%;
          height: 55px;
          line-height: 55px;
          text-align: center;
        }
      }

      .screen {
        position: relative;
        // height: 55px;
        // display: flex;
        // align-items: center;
        > img {
          width: 35px;
          height: 35px;
          margin-top: 8px;
        }
        &::before {
          content: '';
          display: block;
          height: 30px;
          width: 2px;
          background-color: rgba(255, 255, 255, 0.25);
          position: absolute;
          top: 12px;
          left: -20px;
        }
        &::after {
          content: '';
          display: block;
          height: 30px;
          width: 2px;
          background-color: rgba(255, 255, 255, 0.25);
          position: absolute;
          top: 12px;
          right: -20px;
        }
      }
      .voice {
        // height: 55px;
        display: flex;
        align-items: center;
        margin-left: 13px;
        margin-right: 10px;
        > img {
          width: 35px;
          height: 40px;
          margin-top: 0px;
        }
      }
    }
  }
}
@media screen and(max-width: 1919px) {
  .header-container {
    width: 100%;
    > div {
      &:nth-child(1) {
        width: 40px;
        border-right: 2px solid rgba(255, 255, 255, 0.25);
        border-bottom: 2px solid rgba(255, 255, 255, 0.25);
      }
      &:nth-child(2) {
        width: calc(100% - 84px);
      }
      &:nth-child(3) {
        width: 40px;
        border-left: 2px solid rgba(255, 255, 255, 0.25);
        border-bottom: 2px solid rgba(255, 255, 255, 0.25);
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .header-container {
    width: 1920px;
    > div {
      &:nth-child(1) {
        width: 40px;
        border-right: 2px solid rgba(255, 255, 255, 0.15);
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);
      }
      &:nth-child(2) {
        width: 1836px;
      }
      &:nth-child(3) {
        width: 40px;
        border-left: 2px solid rgba(255, 255, 255, 0.15);
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);
      }
    }
  }
}
</style>
