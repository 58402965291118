<template>
  <div class="confirm-box">
    <transition>
      <div :style="position" class="confirm-caontainer" v-if="confirmOrderStatus">
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="head"
        ></div>
        <span @click="close" class="close"></span>

        <div class="orderList ximiBlock" id="print">
          <div id="pdfDom">
            <div class="orderLogo">
              <img alt src="../../../assets/images/修文印刷订单.jpg" />
              <span class="lot">{{ time ? timestampToTime(time) : "历史订单列表" }}</span>
            </div>
            <!-- <div v-show="horders" style="width:100%;positon:relative">{{orderId}}  <a @click="back" style="position:absolute; right:70px">返回当前订单</a></div> -->
            <table class="orderInfo" v-if="orders.length>0">
              <tr class="nav-list">
                <td>名称</td>
                <td>数量</td>
                <td>单位</td>

                <td>单价</td>
                <td>金额/元</td>
                <td v-if="buttonShow">操作</td>
              </tr>
              <tr :key="index" class="product-info" v-for="(item, index) in orders">
                <td>{{ item.product.productName }}</td>
                <td>
                  <input
                    :disabled="disabled"
                    @blur="inputBtn(item.orderNumber, index, item.product,item)"
                    class="number"
                    min="1"
                    oninput="if(this.value.length == 1){this.value = this.value.replace(/[^1-9]/g,'');}"
                    onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                    type="number"
                    v-model.number.trim="item.orderNumber"
                  />
                </td>
                <td>{{ item.product.productUnits }}</td>
                <td>{{ item.price }}</td>

                <td>{{ toDecimal2(item.totalPrice) }}</td>
                <td v-if="buttonShow">
                  <span
                    @click="cancel(index)"
                    class="iconfont icon-cancel-1-copy cancel"
                    v-if="buttonShow"
                  ></span>
                  <!-- <el-button @click="cancel(index)" class="cancel" v-if="buttonShow">删除</el-button> -->
                </td>
              </tr>
              <tr class="product-info">
                <td>合计金额:</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ toDecimal2(totalPrice) }}</td>
                <td v-if="buttonShow"></td>
              </tr>
              <tr class="product-info">
                <td>运费:</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ toDecimal2(freight) }}</td>
                <td v-if="buttonShow">
                  <div class="checked">
                    <input type="checkbox" v-model="checked" />
                    <span>到付</span>
                  </div>
                </td>
              </tr>
              <tr class="product-info">
                <td>应付总额:</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ toDecimal2(totalPrice + freight) }}</td>
                <td v-if="buttonShow"></td>
              </tr>
            </table>

            <div class="describes product-info" v-if="orders.length>0">
              <span>备注:</span>
              <textarea :disabled="disabled" cols="36" id name rows="10" v-model="describes"></textarea>
            </div>
            <div class="company company1 product-info" v-if="orders.length>0">
              <div class="left">发票信息:</div>
              <div class="mid" v-if="companyBill">
                <p>
                  {{ companyBill[indexs].name }}
                  {{ companyBill[indexs].creditCode }}
                </p>
                <p>
                  {{ companyBill[indexs].registerAddress}}
                  {{ companyBill[indexs].phone }}
                </p>

                <p>
                  {{ companyBill[indexs].accountOpening }}
                  {{ companyBill[indexs].bankAccount }}
                </p>
              </div>

              <div class="change-btn" v-show="order">
                <img
                  @click="changeBtn"
                  alt
                  class="change-btn"
                  src="../../../assets/images/comfirmOrder/修改标.png"
                  v-if="buttonShow"
                />
              </div>
              <!-- 
          <div v-show="order">
            <span @click="change(2)">修改</span>
              </div>-->
            </div>
            <div class="company product-info" v-if="orders.length>0">
              <div class="left">收货人信息:</div>
              <div class="mid" v-if="userAddress">
                <p>{{ userAddress[addressIndex].address }}</p>
                <p>
                  {{ userAddress[addressIndex].name }}
                  {{ userAddress[addressIndex].phone }}
                </p>
              </div>
              <div v-show="order">
                <!-- <span @click="change(3)">修改</span> -->
                <!-- <span @click="billChange" class="change-btn" v-if="buttonShow">修改</span> -->
                <img
                  @click="billChange"
                  alt
                  class="change-btn"
                  src="../../../assets/images/comfirmOrder/修改标.png"
                  v-if="buttonShow"
                />
              </div>
            </div>
            <div class="company product-info" v-if="orders.length>0">
              <div class="left">收款账号:</div>
              <div class="mid mid-end">
                <p>北京修文印刷技术有限公司</p>
                <p>招商银行股份有限公司北京北三环支行 &nbsp; 110917960410501</p>
              </div>
              <div style="width:30px" v-show="order"></div>
            </div>
          </div>
        </div>
        <div class="orderBtn" v-if="orders.length>0">
          <div class="his">
            <img alt src="../../../assets/images/comfirmOrder/历史标.png" />
            <input @click="historyOrder" class="historyBtn" type="button" value="历史订单" />
          </div>
          <!-- <input type="button" value="再来一单" class="left" @click="creatOrder" v-show="!again" /> -->
          <ul class="right-box">
            <li>
              <img alt src="../../../assets/images/comfirmOrder/打印标.png" />
              <input @click="prints" class="rights" type="button" v-print="printObj" value="打印订单" />
            </li>
            <li class="confirm">
              <div class="hint" v-if="hintStatus">
                <div class="message">
                  <span>请注意</span>
                  <span class="font-weight">开票信息</span>
                  <span>和</span>
                  <span class="font-weight">收货人信息</span>
                  <span>，有错误可点击修改。没有问题请点击确定以提交订单。</span>
                </div>
                <div class="edit">
                  <span @click="orderCanel">取消</span>
                  <span @click="realConfirm">确认</span>
                </div>
              </div>
              <input
                :value="[checkOrder?'确认订单':'再来一单']"
                @click="creatOrder"
                class="right"
                type="button"
              />
              <!-- <input
                @click="creatOrder"
                class="right"
                type="button"
                v-if="againOrder && cancelStatus"
                value="再来一单"
              />-->
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @import
import { mapState } from 'vuex'
import vm from '../../../assets/js/event'
import moveData from '../../../mixins/move'

export default {
  components: {},
  props: {},
  mixins: [moveData],

  data () {
    return {
      printObj: {
        id: "print",
        // preview: true,
        ignoreClass: "right",
        standard: "loose",
        popTitle: "",
        extraHead:
          '<meta http-equiv="Content-Language"content="zh-cn"/>,<style> #print { height: auto !important; } <style>',
        beforeOpenCallback () {
          console.log('正在加载预览窗口')
        },
      },
      totalPrice: '',
      creatName: '确认订单',
      time: '',

      checked: false,
      describes: "",
      companyBill: "",
      userAddress: "",
      address: "",
      order: true,
      cancelStatus: true,
      hintStatus: false,
      detailsArr: []

    };
  },

  created () {
    this.getDate();

  },
  mounted () {
    // this.getCompanyBill();
    // this.getUserAddress();
  },
  computed: {
    ...mapState(['confirmOrderStatus', 'company', 'orders', 'userInfo', 'indexs', 'addressIndex', 'againOrder', 'checkOrder', 'hasChangeBill', 'hasChangeAddress', 'buttonShow', 'disabled']),

    freight: {
      get () {
        if (this.totalPrice >= 300) {
          let str = this.address.substring(0, 4)
          if (this.checked) {

            return 0;
          }

          if (str.search('宁夏') != -1 || str.search('甘肃') != -1 || str.search('广西') != -1 || str.search('贵州') != -1 || str.search('海南') != -1 || str.search('青海') != -1 || str.search('云南') != -1) {

            return this.totalPrice * 0.06

          } else if (str.search('西藏') != -1 || str.search('新疆') != -1) {
            return this.totalPrice * 0.08

          } else {
            return 0
          }

        } else {

          if (this.checked) {
            return 0;
          }
          // debugger
          let str = this.address.substring(0, 4)
          console.log('com1nnnn', str.substring(0, 4), this.address,);

          if (str.search('北京') != -1) {
            return 15;
          } else if (str.search('宁夏') != -1 || str.search('甘肃') != -1 || str.search('广西') != -1 || str.search('贵州') != -1 || str.search('海南') != -1 || str.search('青海') != -1 || str.search('云南') != -1) {
            return 30

          } else if (str.search('西藏') != -1 || str.search('新疆') != -1) {
            return 40;

          } else {
            return 25
          }
          // if (str.search('宁夏') || str.search('甘肃') || str.search('广西') || str.search('贵州') || str.search('海南') != -1) {
          //   return 15;
          // } else {
          //   return 25;
          // }



        }
      },
      set () {

      }
    }

  },
  watch: {
    userInfo (newValue, oldValue) {
      if (newValue) {
        this.getCompanyBill()
        this.getUserAddress()
      }
    },
    hasChangeBill (newValue, oldValue) {
      if (newValue) {
        this.getCompanyBill()
      }
    },
    hasChangeAddress (newValue, oldValue) {
      if (newValue) {
        this.getUserAddress()
      }
    },
    address (newValue, oldValue) {
      this.freights()
    },
    addressIndex () {

      this.address = this.userAddress[this.$store.state.addressIndex].address;
      console.log('com1111haha ', this.$store.state.addressIndex, this.address);

      return this.$store.state.addressIndex;
    },
    orders (newValue, oldValue) {
      this.resSetData()
    },
    confirmOrderStatus (newValue, oldValue) {
      if (newValue) {
        console.log('width', document.body.clientWidth, this.$refs.announcement);
        this.x = (document.body.clientWidth - 318) / 2
        this.y = 80
      } else {
        return
      }
    }
  },
  methods: {
    freights () {
      if (this.totalPrice >= 300) {
        let str = this.address.substring(0, 4)
        if (this.checked) {

          this.freight = 0

        }

        if (str.search('宁夏') != -1 || str.search('甘肃') != -1 || str.search('广西') != -1 || str.search('贵州') != -1 || str.search('海南') != -1 || str.search('青海') != -1 || str.search('云南') != -1) {

          this.freight = this.totalPrice * 0.06

        } else if (str.search('西藏') != -1 || str.search('新疆') != -1) {
          this.freight = this.totalPrice * 0.08

        } else {
          this.freight = 0
        }

      } else {

        if (this.checked) {
          this.freight = 0;
        }
        // debugger
        let str = this.address.substring(0, 4)
        console.log('com1nnnn', str.substring(0, 4), this.address,);

        if (str.search('北京') != -1) {
          this.freight = 15;
        } else if (str.search('宁夏') != -1 || str.search('甘肃') != -1 || str.search('广西') != -1 || str.search('贵州') != -1 || str.search('海南') != -1 || str.search('青海') != -1 || str.search('云南') != -1) {
          this.freight = 30

        } else if (str.search('西藏') != -1 || str.search('新疆') != -1) {
          this.freight = 40;

        } else {
          this.freight = 25
        }
        // if (str.search('宁夏') || str.search('甘肃') || str.search('广西') || str.search('贵州') || str.search('海南') != -1) {
        //   return 15;
        // } else {
        //   return 25;
        // }



      }
    },
    //改变数量时获取相对应的单价
    inputBtn (inputNum, index, product, item) {




      console.log('itttt', item);

      var inputNum = inputNum; //输入款默认值

      var num = this.orders[index].orderNumber;


      var moq = product.moq; //订购不能低于的数量

      var beginNum = product.productPrices[0].productNumber; //初始价格的最低数量

      // 订购数量不得低于 基准值
      if (moq != 0 && num !== '' && num !== null) {
        if (num < moq) {
          this.$message({
            message: `起订数量为${moq}${product.productUnits}`,
            type: 'warning',
          })
          this.orders[index].orderNumber = moq

        }
      }
      if (inputNum <= beginNum) {
        this.orders[index].price = product.productPrices[0].price;
      }

      if (product.productPrices.length > 1 && inputNum >= beginNum) {
        for (var i = 0; i < product.productPrices.length; i++) {
          if (i < product.productPrices.length) {
            if (i + 1 == product.productPrices.length) {
              if (inputNum >= product.productPrices[i].productNumber) {
                this.orders[index].price = product.productPrices[i].price;
              }
            }

            if (
              i + 1 < product.productPrices.length &&
              i + 1 !== product.productPrices.length
            ) {
              if (
                inputNum >= product.productPrices[i].productNumber &&
                inputNum < product.productPrices[i + 1].productNumber
              ) {
                this.orders[index].price = product.productPrices[i].price;
              }
            }
          }
        }
      }
      console.log('ordersnew', this.orders);

      this.reSet();
    },
    prints () {


      // this.buttonShow = false;
      this.$store.state.buttonShow = false;
      var _this = this;
      setTimeout(() => {
        // this.buttonShow = !this.buttonShow;
        this.$store.state.buttonShow = !this.$store.state.buttonShow
        _this.$forceUpdate();
        console.log("buttonb", this.buttonShow);
      }, 1000);
    },
    // 删除
    cancel (index) {
      if (this.orders.length - 1 <= 0) {
        this.$store.state.checkOrder = false;
        this.cancelStatus = false;
      }
      this.orders.splice(index, 1);
      console.log("oooiii", this.orders);
      this.reSet();
    },
    historyOrder () {
      // this.$router.push("./historyPage");
      this.$store.state.orderListStatus = true
      this.$store.state.confirmOrderStatus = false;
    },
    creatOrder () {
      this.hintStatus = true;
      this.arr = this.orders.filter(function (item) {
        return item.orderNumber;
      });
      this.arr.forEach(item => {
        item.detailsId = null;
      });

      console.log('att', this.arr);

    },
    orderCanel () {
      this.hintStatus = false;

    },
    //提交订单
    realConfirm () {
      // this.buttonShow = false;
      // this.disabled = true;
      // this.$store.state.againOrder = false;
      console.log('哼哼', this.company);


      let data = {

        freight: this.freight,
        describes: this.describes,
        enterpriseUser: {
          id: this.userInfo.enterpriseUserDTO.id
        },
        enterpriseInfo: {
          id: this.companyBill[this.indexs].id
        },
        enterpriseAddress: {
          id: this.userAddress[this.addressIndex].id
        },
        dept: {
          id: this.company.id
        },

        orderDetails: this.arr,

        orderAmount: this.totalPrice
      }
      console.log('哼哼', data);


      this.$request.post(this.$api.creatOrders, data).then(res => {
        console.log("44444yyyyy", res, this.arr);
        // this.buttonShow = false;
        this.$store.state.buttonShow = false;
        this.$store.state.disabled = true;
        this.$store.state.checkOrder = false;
        this.hintStatus = false;

        this.$notify({
          title: "订单提交成功",
          message: "请您关注物流信息,谢谢",
          type: "success"
        });
      }).catch(err => {

      })




    },
    reSet () {
      // window.sessionStorage.setItem("listInfo", JSON.stringify(this.proList));
      this.$store.state.orders = this.orders
      this.resSetData();
    },
    resSetData () {
      this.totalPrice = 0;

      this.orders.forEach(item => {
        item.totalPrice = item.orderNumber * item.price;
        this.totalPrice += parseFloat(item.totalPrice);
        // const d = new Date();
        // this.time = Number(d);

      });
    },
    getDate () {

      this.totalPrice = 0;

      vm.$on("customEvent", value => {
        // 接受自定义事件传递的数据
        this.orders = value
        this.resSetData()
      })
      const d = new Date();
      this.time = Number(d);

      vm.$on('billAndAddress', value => {
        console.log('wojiushivm', value);
        this.resSetData()

        if (value.billId !== '') {
          this.companyBill.forEach((item, index, arr) => {
            if (item.id == value.billId) {
              this.$store.state.indexs = index
            }

          })
        }
        if (value.addressId !== '') {
          this.userAddress.forEach((item, index, arr) => {
            if (item.id == value.addressId) {
              this.$store.state.addressIndex = index
            }

          })
        }
      })




      // if (this.orders.length > 0) {
      //   this.orders.forEach(item => {
      //     item.totalPrice = item.orderNumber * item.price;
      //     this.totalPrice += parseFloat(item.totalPrice);
      //     const d = new Date();
      //     this.time = Number(d);

      //   });

      // } else {
      //   this.orders = []
      // }
      // console.log('orders', this.orders);

    },
    timestampToTime (timestamp) {
      var date = new Date(timestamp); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = date.getFullYear() + "-";
      const M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      const D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      const h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      const m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      const s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    close () {
      this.$store.state.confirmOrderStatus = !this.$store.state.confirmOrderStatus

    },
    // 保留小数点后两位
    toDecimal2 (x) {
      var f = parseFloat(x);

      f = Math.round(f * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
    getCompanyBill () {
      // 查询企业发票信息
      // debugger
      let id = this.userInfo.enterpriseUserDTO.id;


      this.$request.get(this.$api.billInfo, { id: id }).then(res => {
        this.companyBill = res.data;
        console.log("2ee233333333", this.companyBill);
        this.$forceUpdate()
        // 历史订单页 所需要显示数据。（发票信息）
        // if (this.$route.params.billId) {
        //   let billId = this.$route.params.billId;
        //   let that = this;
        //   this.companyBill.forEach(function (value, index, array) {
        //     if (value.id == billId) {
        //       that.$store.state.indexs = index
        //     }
        //   });

        // }
      });
    },
    getUserAddress () {
      // 查询收货人信息
      let id = this.userInfo.enterpriseUserDTO.id;

      this.$request.get(this.$api.billAddress, { id: id }).then(res => {
        this.userAddress = res.data;

        console.log('rrrrr11', res);


        if (res.data.length > 0) {
          this.address = res.data[0].address
        } else {
          this.address = ''
        }
        // console.log("090", this.address);
        console.log('com1', this.address);

        // 历史订单页 所需要显示数据。（邮寄地址）
        // if (this.$route.params.addressId) {
        //   let addressId = this.$route.params.addressId;
        //   let that = this;
        //   this.userAddress.forEach(function (value, index, array) {
        //     if (value.id == addressId) {
        //       that.$store.state.addressIndex = index
        //       that.address = res.data[index].address
        //     }
        //   });
        // }
      });
    },
    changeBtn () {
      // this.$store.state.back = true;
      this.$store.state.navStatus = true;

      this.$store.state.hasChangeBill = false;

      this.$store.state.billAddressStatus = true;

      // this.billOrAddress = true;
      // this.$store.state.billOrAddress = true;
      // this.$router.push({
      //   name: "changePage",
      //   params: { id: this.userinfo.id, billOrAddress: this.billOrAddress }
      // });
    },
    billChange () {

      this.$store.state.billAddressStatus = true;
      this.$store.state.navStatus = false;


      // this.billOrAddress = false;
      // this.$store.state.billOrAddress = false;
      // this.$router.push({
      //   name: "changePage",
      //   params: { id: this.userinfo.id, billOrAddress: this.billOrAddress }
      // });
    },
  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 733px;
  }
}
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}

html {
  background-color: #ffffff;
  margin: 0px; /* this affects the margin on the html before sending to printer */
}

body {
  border: solid 1px blue;
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
}
.again {
  right: 200px !important;
}

// ?以上是隐藏打印页面的页眉和页脚！

.confirm-caontainer {
  position: fixed;

  width: 608px;
  height: 733px;
  background: url(../../../assets/images/comfirmOrder/订单外框.png) no-repeat;
  backdrop-filter: blur(5px);
  overflow: hidden;
  padding: 40px 25px 10px 25px;

  .head {
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.close {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  z-index: 999;
}
.demonstration {
  font-size: 13px;
}
.orderList {
  width: 100%;
  height: 100%;
  padding-bottom: 90px;

  // height: auto;
  background-color: #fff;
  // border: 1px solid #605d5e;
  font-family: YouYuan;
  font-weight: 600;
  font-size: 18px;
  box-sizing: border-box;
  // padding: 20px 45px 150px;
  overflow: hidden;
}
.historyBtn {
  padding: 2px;
  cursor: pointer;
}
.orderInfo tr td:last-child {
  text-align: right;
  // padding-left: 100px;
}
.orderInfo tr td:nth-child(3) {
  // text-align: center;
  // padding-left: 24px;
}
.cancel {
  // color: #cccccc;
  font-size: 14px;
  margin-right: 8px;
}
.orderInfo tr td:first-child {
  width: 185px;
}
.orderInfo tr td:nth-child(2) {
  // padding-left: 10px;
  // text-align: center;
  // input{
  //   width: 50px
  // }
}
.orderInfo tr:first-child {
  font-size: 16px;
  height: 40px;
}
.orderInfo tr {
  border-bottom: 1px solid #c8c5c4;
  height: 30px;
}
.orderInfo {
  margin-bottom: 20px;
  input {
    border: none;
  }
}
.modify {
  height: 50px;
}
table {
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}
.company {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c8c5c4;
  font-size: 13px;
  padding-top: 5px;
  line-height: 18px;
  .mid {
    width: 395px;
  }
  .mid-end {
    margin-left: 16px;
  }
  .left {
    width: 80px;
  }
  p {
    flex: 1;
    margin: 0;
    // height: 20px;
  }
}
.company1 {
  border-top: 1px solid #c8c5c4;
}
.orderBtn {
  // border-bottom: 1px solid #c8c5c4;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  text-align: justify;
  height: 41px;
  position: absolute;
  bottom: 0px;
  width: 98%;
  padding: 0 25px;
  left: 3px;
  background: #fff;

  .right-box {
    .confirm {
    }
    > li {
      position: relative;

      .hint {
        width: 180px;
        height: 120px;
        border: 1px solid #ebeef5;
        position: absolute;
        top: -130px;
        left: -50px;

        .edit {
          width: 100%;
          text-align: right;
          color: #ccc;
          span {
            margin-right: 10px;
          }
        }

        .message {
          color: #ccc;
          padding: 5px;
          text-align: left !important;
          line-height: 1.2 !important;

          > span {
            color: #ccc;
            font-size: 14px;
          }
          .font-weight {
            font-weight: 500;
            color: #000;
          }
        }

        &:after {
          display: block;
          content: '';
          width: 0px;
          height: 0px;
          border: 10px solid transparent;
          // border-top-color: #fff;
          border-top-color: #ebeef5;
          position: absolute;
          left: calc(50% - 10px);
          bottom: -20px;
        }
      }
    }
  }
  .his {
    display: flex;
    height: 41px;
    align-items: center;
    > img {
      margin-right: 5px;
      height: 16px;
      width: 16px;
    }
  }
  > ul {
    display: flex;
    height: 41px;
    li {
      text-align: center;
      &:nth-child(1) {
        margin-right: 30px;
        display: flex;
        align-items: center;
        > img {
          margin-right: 5px;
        }
      }
      &:nth-child(2) {
        width: 133px;
        height: 41px;
        color: #fff;
        line-height: 42px;
        background: url(../../../assets/images/comfirmOrder/确认订单按钮.png)
          no-repeat;
      }
    }
  }
  // top: 100px;
  input {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 13px;
  }
  .left {
    position: absolute;
    left: 95px;
    bottom: 10px;
  }
  .right {
    // position: absolute;
    // right: 95px;
    // bottom: 10px;
    padding: 2px;
    cursor: pointer;
  }
  .rights {
    // position: absolute;
    // right: 195px;
    // bottom: 10px;
    padding: 2px;
    cursor: pointer;
  }
}
/deep/ .el-pagination {
  display: inline-block;
}
.orderList {
  overflow-y: auto;
  position: relative;
}
.return {
  position: absolute;
  z-index: 999;
  left: 0;
  bottom: 0;
  font-size: 15px;
  font-weight: 700;
}

.fbox {
  width: 660px;
  height: 855px;
  position: absolute;
  background: #fff;
  left: 50%;
  top: 50%;
  z-index: 11;
  padding-bottom: 60px;
  transform: translate(-50%, -50%);
  font-weight: 200;
  .close-box {
    width: 25px;
    height: 25px;
    position: relative;
    left: 10px;
    top: 10px;
    // overflow: hidden;
    // background: #fa4848;
    .clbtn {
      position: absolute;
      z-index: 9;
      cursor: pointer;
      color: red;
      font-size: 25px;
    }
  }
}

.ordered {
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 100px;
}

.block {
  padding-bottom: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.describes {
  margin-bottom: 20px;
  font-size: 13px;
  padding: 2px 0;
  margin-top: 3px;
  height: 80px;
  width: 100%;
  border: 1px solid #67605d;
  position: relative;
  span {
    position: absolute;
    top: 2px;
  }
  textarea {
    font-family: YouYuan !important;
    border: none;
    resize: none;
    outline: none;
    height: 58px;
    width: 500px;
    display: inline-block;
    margin-left: 40px;
  }
}

.orderLogo {
  display: flex;

  font-size: 13px;
  // line-height:;
  // position: absolute;
  // left: 40px;
  // margin-left: -40px;
  margin-bottom: 20px;
  img {
    height: 36px;
    margin-bottom: -4px;
    padding-right: 25px;
  }
  .lot {
    height: 37px;
    display: flex;
    align-items: flex-end;
    // position: absolute;
    // left: 72px;
    // top: 75px;
  }
}
.title {
  border-bottom: 1px solid #c8c5c4;
  font-size: 15px;
  p {
    display: inline-block;
  }
  p:first-child {
    padding-right: 35px;
  }
  p:nth-child(3) {
    padding-left: 50px;
    padding-right: 30px;
  }
}

// .ximiBlock::-webkit-scrollbar-track
// {
//   -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
//   border-radius: 10px !important;
//   background-color: #ffffff;
// }

// .ximiBlock::-webkit-scrollbar
// {
//   width: 5px !important;
//   background-color: #ffffff;
// }
// .ximiBlock::-webkit-scrollbar-thumb
// {
//   border-radius: 15px !important;
//   -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
//   background-color: #555;
// }

.orderlist {
  // margin: 0 auto;
  overflow-x: hidden;
  div {
    border-bottom: 1px solid #c8c5c4;
    height: 23px;
  }
  span {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    margin-top: -3px;
  }
  span:first-child {
    width: 96px;
    padding-left: 3px;
  }
  span:nth-child(2) {
    width: 96px;
  }
  span:nth-child(3) {
    width: 91px;
  }
  span:nth-child(4) {
    padding-right: 5px;
  }
  .bt {
    background-color: #dcdddd;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.number {
  width: 40px;
  font-size: 12px;
  padding: 0;
  color: blue;
  font-weight: 400;
}
.change-btn {
  text-decoration: underline;
  cursor: pointer;
}
.el-button {
  border: none;
}
.nav-list {
  font-weight: 400;
}
.product-info {
  font-weight: 200;
}
.checked {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > input {
    margin-right: 8px;
    margin-top: 1px;
  }
}
.font-weight {
  font-weight: 900;
}
.checkeds {
  margin-top: 20px;
}
</style>