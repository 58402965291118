<template>
  <div class="outside">
    <div class="user-info" ref="userInfo">
      <div :key="index" class="header" ref="header" v-for="(item,index) in newArr" @click.stop>
        <div
          :class="[ifShow && indexs == index?'big-img':ifSmall&& indexs == index?'small-img':'']"
          @click.stop
          @mouseenter="imgEnter(index)"
          @mouseleave="imgLeave(index)"
          class="header-img"
          ref="header"
        >
          <img  @click.stop="userAllInfo(item,index)" :src="`${pubilckPath}/${item.headAddress}`" alt class="header-lazy" />
          <!-- <span :class="[toolShow && indexs == index?'toolAppear':'toolClose']" class="hover-jobs">{{item.jobs}}</span> -->
          <!-- <img alt src="../../assets/images/README.jpeg"/> -->
        </div>
        <div
          :class="[ifShow && indexs == index?'width-open':ifShow== false && indexs == 'index'?'width-close':'']"
          @click.stop
          class="header-intro"
        >
          <!-- <div>{{item.name}}</div>
          <div>{{ item.jobs }}</div>
          <div v-permission="{action:tokenStatus,effect:'limit'}">{{ item.phone}}</div>
          <div v-noLimit="{action:tokenStatus}">{{ item.phone | hiddenPhone}}</div>-->
          <div :class="[ifShow && indexs == index?'line-open':'line-close']" class="all-infos">
            <span v-permission="{action:tokenStatus,effect:'limit'}">{{item.phone}}</span>
            <span v-noLimit="{action:tokenStatus}">{{ item.phone | hiddenPhone}}</span>
            <span>{{ item.jobs}}</span>
            <span>{{item.name}}</span>
          </div>
          <div v-if="item.details != null && item.details.length > 0">
            <p :key="index" v-for="(items,index) in item.details">{{textArr[index]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div @click="openUser" class="open-icon" v-show="userInfoList.length>5" v-if="commonStatus">
        <span class="iconfont icon-icon_xinyong_xianxing_jijin-135" v-if="!userShow"></span>
        <span class="iconfont icon-icon_xinyong_xianxing_jijin-136" v-else></span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Typed from 'vue-typed-js'

export default {
  name: '',
  data () {
    return {
      msg: '',
      toolShow: false,
      userInfoList: [
      ],
      ifSmall: false,
      arra: ['hahahahhaha'],
      shuffle: false,
      ifShow: false,
      indexs: null,
      typewriter: '',
      i: 0,
      timer: 0,
      str: '',
      users: {
        name: '',
        phone: '',
        jobs: ''
      },
      userShow: true,
      newArr: [],
      userInfos: [],
      textIndex: 0,
      str: [],
      indexx: null,
      textArr: [

      ],
      timer: [],
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
      text6: '',
      text7: '',
      text8: '',
    }
  },
  created () {


  },
  computed: {
    ...mapState(['tokenStatus','commonStatus'])
  },

  filters: {
    hiddenPhone (phone) {
      return phone.substr(0, 3) + "******" + phone.substr(9)
    }
  },
  watch: {

  },
  mounted () {
    this.getparter()
    var _this = this;
    document.addEventListener('click', function () {
      _this.ifShow = false;
      _this.ifSmall = false;
      _this.hiddenOrappear(this.indexs)
      _this.$store.state.commonStatus = true
    })





    setTimeout(() => {
      this.userShow = false;
      this.ifShow = false;
      this.newArr = this.userInfoList.slice(0, 5)
    }, 10000)




  },
  methods: {

    // 获取员工信息
    getparter () {
      this.$request.get(this.$api.parterInfo, { sort: 'sort,asc' }).then((res) => {
        this.userInfoList = res.data.content;


        // console.log('iii',JSON.parse(this.userInfoList[2].details));
        let _this = this;
        this.userInfoList.forEach(function (value, index, array) {
          // console.log('value',value.details);

          if (value.details !== '' && value.details !== null) {

            _this.userInfoList[index].details = JSON.parse(value.details);
          }
        })

        this.newArr = this.userInfoList

        // if (this.userInfoList.length > 5) {
        //   this.newArr = this.userInfoList.slice(0, 5)
        // } else {
        //   this.newArr = this.userInfoList;
        // }
      })

    },
    imgEnter (index) {
      // this.toolShow = true;
      // this.indexs = index;
      // let dom = document.querySelectorAll('.header-img')
      // if (dom[index].classList.contains('hover-leave-img') || dom[index].classList.contains('small-img')) {
      //   dom[index].classList.remove('hover-leave-img')
      //   dom[index].classList.remove('small-img')

      // }
      // dom[index].classList.add('hover-enter-img')
    },
    imgLeave (index) {
      // this.toolShow = false;
      // let dom = document.querySelectorAll('.header-img')
      // if (dom[index].classList.contains('hover-enter-img')) {
      //   dom[index].classList.remove('hover-enter-img')
      // }
      // dom[index].classList.add('hover-leave-img')
    },
    openUser () {
      this.userShow = !this.userShow;
      if (this.userShow) {
        this.newArr = this.userInfoList

      } else {
        this.newArr = this.userInfoList.slice(0, 5)

      }
      // if (this.userShow) {
      //   if (this.$refs.userInfo.classList.contains('closeUser')) {
      //     this.$refs.userInfo.classList.remove('closeUser');
      //   }
      //   this.$refs.userInfo.classList.add('openUser');
      // }else{
      //   if(this.$refs.userInfo.classList.contains('openUser')){
      //     this.$refs.userInfo.classList.remove('openUser');
      //   }
      //   this.$refs.userInfo.classList.add('closeUser');

      // }
      // console.log('iiiiii', this.$refs.userInfo.classList);

    },

    // newText (item, index) {
    //   console.log('wojiushi11', item, index);

    //   for (let i = 0; i < item.length; i++) {

    //     if (this.str.indexOf(item[i].info) == -1) {
    //       this.str.push(item[i].info)
    //     }

    //     if (i < item.length && this.userInfos.length <item.length) {
    //       let obj = {
    //         infos: ''
    //       }
    //       this.userInfos.push(obj)
    //     }

    //   }

    //   console.log('wojiushi222', this.str);
    //   console.log('wojiushi333', this.userInfos);


    // },
    step1 (str) {
      clearInterval(this.timer1)
      this.text1 = ""
      let index = 0;
      this.timer1 = setInterval(() => {
        this.text1 = str.slice(0, index++)
        if (index > str.length) {
          clearInterval(this.timer1)
          // console.log('thi',this.textArr);
        }
      }, 100)

    },

    // },
    step2 (str) {
      this.text2 = ""
      clearInterval(this.timer2)
      let index = 0;
      this.timer2 = setInterval(() => {
        this.text2 = str.slice(0, index++)
        if (index > str.length) {
          clearInterval(this.timer2)
          // console.log('thi',this.textArr);
        }
      }, 100)

    },

    step (indexs, str) {
      clearInterval(this.timer[indexs])
      this.textArr[indexs] = ""
      let index = 0;
      this.timer[indexs] = setInterval(() => {
        this.textArr[indexs] = str.slice(0, index++)
        this.$forceUpdate()
        if (index > str.length) {
          clearInterval(this.timer[indexs])
        }
      }, 100)

    },

    userAllInfo (item, index) {




      let obj = {
        text: ''
      }


      // item.details.forEach((item,index)=>{
      //   this.textArr.push(obj)
      //   // this.step(index,item.info)
      // })

      // this.step(0, item.details[0].info)
      // this.step(1, item.details[1].info)

      if (item.details !== '' && item.details !== null && item.details.length > 0) {
        for (let i = 0; i < item.details.length; i++) {
          this.step(i, item.details[i].info)
        }
      }


      this.ifShow = !this.ifShow;

        this.ifSmall = !this.ifSmall;
;

      // if(!this.ifShow){
      //   this.ifSmall = true;
      // }else{
      //   this.ifSmall = false;
      // }
      this.toolShow = false;
      this.$store.state.commonStatus = !this.$store.state.commonStatus

      // if(ifShow){
      //   this.disabled = true;
      // }else{
      //   this.disabled = false;
      // }
      this.indexs = index;
      this.hiddenOrappear(index)
      // this.str = item.details
      // this.typewriter = ''
      // this.i = 0
      // this.typeing()
    },
    beginAppear (str, index) {


      // this.timer = setInterval(()=>{

      //   // if(this.textIndex < str.length){
      //   //   this.userInfos[index].infos = str.slice(0,this.textIndex++)
      //   // }else{
      //   //   clearInterval(this.timer)
      //   // }
      //   this.userInfos[index].infos = index++

      // },3000)



      // if(this.textIndex < str.length){
      //   this.userInfos[index].infos = str.slice(0,this.textIndex++)
      //   console.log('itemqq1',str.slice(0,this.textIndex++));

      //   this.timer = setTimeout(() => {
      //     this.beginAppear(this.str,this.indexx)
      //   },4000)
      //   console.log('itemqq',this.userInfos);

      // }else{
      //   clearTimeout(this.timer)
      // }



    },


    typeing (item, index) {
      // let i = 0
      // console.log('itemnnnnn', this.userInfos[index].info);

      // if (this.i <= item.length) {
      //   this.userInfos[index].info = item.slice(0, this.i++)
      //   this.timer = setTimeout(() => {
      //     this.typeing()
      //   }, 70000)
      // } else {
      //   clearTimeout(this.timer)
      // }
    },
    hiddenOrappear (index) {
      let header = document.querySelectorAll('.header')
      if (this.ifShow) {
        for (let i = 0; i < header.length; i++) {
          if (i !== index) {
            if (header[i].classList.contains('appear')) {
              header[i].classList.remove('appear')
            }
            header[i].classList.add('hidden')
          }
        }
      } else {
        for (let i = 0; i < header.length; i++) {

          if (header[i].classList.contains('hidden')) {
            header[i].classList.remove('hidden')
          }
          header[i].classList.add('appear')

        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user-info {
  padding-top: 10px;
  position: relative;
  writing-mode: vertical-lr;
  height: 285px;
  width: 45px;
  // overflow: hidden;
  transition: 0.35s;
  transition-timing-function: linear;
  .header {
    position: relative;
    display: inline-block;
    writing-mode: horizontal-tb;
    z-index: 5;
    margin-bottom: 10px;
    margin-right: 10px;
    .header-img {
      margin: 0 10px 10px 0;
      border: 2px solid #fff;

      // transform: scale(1);
      width: 35px;
      height: 35px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      z-index: 3;
      transition: 0.35s;
      overflow: hidden;
      &:hover{
        border-color: #9c8472;
      }
      img {
        width: 100%;
        // border-radius: 50%;
      }
      .hover-jobs {
        position: absolute;
        top: -35px;
        color: #fff;
        display: inline-block;
        width: 35px;
        padding: 2px;
        box-sizing: border-box;
        text-align: center;
        background-color: rgba(92, 99, 110, 0.9);
        z-index: 999;
      }
    }
    .header-intro {
      color: #fff;
      position: absolute;
      // left: 150%;
      visibility: hidden;

      top: 0px;
      z-index: 2;
      opacity: 0;
      .all-infos {
        line-height: 1.2;
        width: 245px;
      }
      > div {
        padding-left: 25px;
        padding-right: 10px;
        // background-image: linear-gradient(
        //   to right,
        //   rgba(255, 255, 255, 0.5),
        //   rgba(213, 213, 217, 0.8),
        //   #acadb4,
        //   #838790,
        //   #5c636e
        // );
        margin-bottom: 1px;
        text-align: right;
        &:nth-child(1) {
          text-align: right;
          width: 0px;
          border-bottom: 1px solid #5c636e;
          background: #5c636e;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          // align-items: flex-end;
          position: relative;
          &::after {
            // content: '';
            // width: 10px;
            // height: 10px;
            // background: #5c636e;
            // display: inline-block;
            // position: absolute;
            // bottom: -5px;
            // right: -10px;
            // border-radius: 25px;
          }
          span {
            color: #fff;
            // font-weight: bold;
            // font-family: 'Kai';
            // margin-right: 10px;
            font-size: 14px;
            &:nth-child(1) {
              margin-right: 10px;
            }
            &:nth-child(2) {
              margin-right: 10px;
              font-size: 13px;
            }
            &:nth-child(3) {
              font-size: 20px;
            }
          }
        }
        // &:nth-child(2) {
        //   width: 250px;
        // }
        // &:nth-child(3) {
        //   width: 300px;
        // }
        &:nth-child(2) {
          background: rgba(92, 99, 110, 0.9);
          width: 380px;
          text-align: left;
          padding-top: 60px;
          padding-bottom: 5px;
          font-family: 'Kai';
          font-size: 14px;
          letter-spacing: 1px;
          > p {
            line-height: 20px;
            font-size: 11px;
          }
        }
      }
    }
  }

  .user-des {
    width: 100%;
    height: 340px;
    position: absolute;
    overflow: hidden;
    top: 0;
    background: #ccc;
  }
}

.toolAppear {
  display: block !important;
}
.toolClose {
  display: none !important;
}

@keyframes openUser {
  0% {
    width: 45px;
  }
  100% {
    width: auto;
  }
}
@keyframes closeUser {
  0% {
    width: auto;
  }
  100% {
    width: 45px;
  }
}
.openUser {
  width: auto;
  animation-name: openUser;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}
.closeUser {
  width: 45px;
  animation-name: closeUser;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

@keyframes hoverEnterImg {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
@keyframes hoverLeaveImg {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.user-info .header .hover-enter-img {
  transform: scale(1.3);
  animation-name: hoverEnterImg;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}
.hover-leave-img {
  transform: scale(1);
  animation-name: hoverLeaveImg;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

@keyframes bigImg {
  0% {
    left: 0;
    transform: scale(1);
  }

  100% {
    transform: scale(4);
    left: 50px;
  }
}
@keyframes smallImg {
  0% {
    left: 50px;

    transform: scale(4);
  }

  100% {
    left: 0;
    transform: scale(1);
  }
}

.user-info .header .big-img {
  transform: scale(4);
  animation-name: bigImg;
  animation-duration: 0.85s;
  animation-fill-mode: linear;
  left: 50px;
}
.user-info .header .small-img {
  transform: scale(1);
  animation-name: smallImg;
  animation-duration: 0.55s;
  animation-fill-mode: linear;
  left: 0;
}
.hover-leave-img {
  transform: scale(1);
  animation-name: hoverLeaveImg;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

@keyframes hidden {
  0% {
    opacity: 1;
      visibility: visible;

  }
  100% {
    opacity: 0;
       visibility: hidden;

  }
}

@keyframes appear {
  0% {
    opacity: 0;
      visibility: hidden;


  }
  100% {
    opacity: 1;
      visibility: visible;


  }
}
.hidden {
  opacity: 0;
  visibility: hidden;
  animation-name: hidden;
  animation-duration: 0.45s;
  animation-fill-mode: linear;
}
.appear {
  opacity: 1;
  visibility: visible;
  animation-name: appear;
  animation-duration: 0.25s;
  animation-fill-mode: linear;
  visibility: unset;
}

@keyframes withOpen {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes withClose {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.user-info .header .width-open {
  opacity: 1;
  visibility: visible;

  animation-name: withOpen;
  animation-duration: 0.95s;
  animation-fill-mode: linear;
  // animation-delay: .7s;
}
.user-info .header .width-close {
  opacity: 0;
  visibility: hidden;

  animation-name: withClose;
  animation-duration: 0.25s;
  animation-fill-mode: linear;
}
.open-icon {
  width: 35px;
  height: 35px;
  border: 2px solid #fff;
  box-sizing: border-box;
  line-height: 32.5px;
  text-align: center;
  border-radius: 25px;
  border-radius: 1px solid;
  background-color: rgba(92, 99, 110, 1);
  color: #fff;
  > span {
    font-size: 20px;
  }
}

@keyframes lineOpen {
  0% {
    width: 350px;
  }
  100% {
    width: 380px;
  }
}
@keyframes lineClose {
  0% {
    width: 370px;
  }
  100% {
    width: 275px;
  }
}

.outside .user-info .header .header-intro .line-open {
  width: 380px;
  animation-name: lineOpen;
  animation-duration: 0.85s;
  animation-fill-mode: linear;
  border-top-left-radius: 5px;
}
.user-info .header .header-intro .line-close {
  width: 375px;
  animation-name: lineClose;
  animation-duration: 0.85s;
  animation-fill-mode: linear;
}
.header-lazy {
  // transition: .7s;
}
</style>
