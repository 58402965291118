<template>
  <div @click="close" class="mask">
    <div @click.stop class="img">
      <img
        :class="[maskStatus?'img-open':'img-close']"
        :src="imgList[imgIndex]"
        alt="图片加载失败"
        ref="img"
      />
      <div class="edit">
        <span @click.stop="left" class="iconfont iconfont1 icon-xiangzuo2"></span>
        <span @click.stop="right" class="iconfont iconfont2 icon-xiangyou2"></span>
        <span @click.stop="rotate" class="iconfont iconfont3 icon-xiangyouxuanzhuan"></span>
      </div>
    </div>
  </div>
</template>

<script>
// @import
import { mapState } from 'vuex'

export default {
  components: {},
  props: {},
  data () {
    return {
      deg: 0
    };
  },

  created () {

  },
  mounted () {

  },
  computed: {
    ...mapState(['imgList', 'imgIndex', 'maskStatus'])

  },
  watch: {
    imgList (newValue, oldValue) {
      console.log('iddmoun', newValue);

    }
  },
  methods: {
    close () {
      this.$store.state.maskStatus = false;
    },
    left () {
      console.log('iddleft', this.imgIndex, this.imgList.length);
      if (this.imgIndex <= 0) {
        return;
      } else {
        this.$store.state.imgIndex--
      }
    },
    right () {
      console.log('iddleft', this.imgIndex, this.imgList.length);
      if (this.imgIndex >= this.imgList.length - 1) {
        return;
      } else {
        this.$store.state.imgIndex++
      }
    },
    rotate () {
      console.log('ioop', this.$refs.img.style.transform);
      this.deg += 90
      this.$refs.img.style.transform = `rotate(${this.deg}deg)`
    }
  },
}
</script>
<style lang='less' scoped>
.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  > .img {
    width: 800px;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.35s;
    > img {
      width: 100%;
      // transform: rotate(90deg);
    }

    #alttext {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    @keyframes imgAppear {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    .img-open {
      // animation: imgAppear 0.35s timing-function;
      animation-name: imgAppear;
      animation-duration: 0.35s;
    }
    .img-close {
      animation-name: imgAppear;
      animation-duration: 0.35s;
      animation-direction: reverse;
    }
  }
  .edit {
    height: 30px;
    width: 120px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    // top: 0;
    left: 0;
    right: 0;
    bottom: -30px;
    margin: auto;
    > .iconfont {
      font-size: 30px;
      color: #fff;
    }

    .iconfont1 {
      font-size: 31px;
    }
    .iconfont3 {
      font-size: 28px;
    }
  }
}
</style>