<template>
  <div>
    <div class="player">
      <video autoplay="autoplay" controls="controls" id="video" muted preload="auto"></video>
    </div>
  </div>
</template>

<script>
import Hls from '../../../assets/js/hls'
import { getLiveVideoList } from '../../../assets/js/api/liveVideoApi'
import { mapState } from 'vuex'
export default {
  name: '',
  data () {
    return {
      videoOne: [],
      videoData: [],
      timer: ''
    }
  },
  created () {
    // console.log('woshi2', this.$store.state.liveVideoRoomNo);
  },
  mounted () {

  },
  computed: {
    ...mapState(['liveVideoRoomNo'])
  },
  watch: {
    liveVideoRoomNo (newValue, oldValue) {
      console.log('liveVideoRoomNo145', newValue, oldValue);

      // this.getplay()

      this.getVideo(newValue, 'video')
      // if (newValue == 1) {
      //   return
      // } else {
      //   this.getplay()

      // }
    }
  },
  methods: {
    getplay () {
      console.log('liveVideoRoomNo1', this.liveVideoRoomNo);
      this.getVideo(this.liveVideoRoomNo, 'video')

    },
    getVideo (roomNo, videoId) {
      let that = this;
      var pathName = roomNo;

      clearTimeout(this.timer);

      var video = document.getElementById(videoId);
      if (Hls.isSupported()) {
        var hls = new Hls({
          debug: true,
        });
        // hls.loadSource('https://monitor.xiubi.com.cn/live/' + pathName + '/livestream.m3u8');
        hls.loadSource(pathName);

        // https://monitor.xiubi.com.cn/live/A10067/livestream.m3u8
        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          // video.muted = false;
          video.play();

          this.timer = setTimeout(() => {
            video.pause()
          }, 1800000)
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = 'http://monitor.xiubi.com.cn/live/' + pathName + '/livestream.m3u8';
        video.addEventListener('canplay', function () {
          video.play();


        });
      }

    },
  }
}
</script>

<style lang='less' scoped>
.player {
  width: 100%;
  height: 100vh;
  *zoom: 1;
  position: relative;
  z-index: -1;
  overflow: hidden;
  > video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    // transform: scale(1.23);
  }
}
</style>
