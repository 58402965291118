const api = {
  // login: '/api/enterprise/user/loginPlatform' /* TODO:登陆 */,
  code: 'api/sms/send' /* TODO:验证码 */,
  checkCode: 'api/sms/test' /* TODO:核对验证码 post */,
  parterInfo: '/api/xwEmployees/back' /* TODO: 员工详情*/,
  getProductList: '/api/xwProduct/back' /* TODO:获取产品列表 */,
  changePassword: '/api/xwEnterprise/update/pass' /* TODO:修改用户密码 */,
  getHistoryList: '/api/xwOrder', //TODO:获取历史订单
  print:
    '/api/xwProduct/back/download' /* TODO: 所需参数 produtName (商品名称)  get*/,
  historyOrderList:
    '/api/xwOrder' /* TODO: 所需参数  (分页器所需参数 当前页、页数、id、排序方法)*/,
  checkNumber: 'api/sms/test' /* TODO:注册时验证验证码和手机号 */,
  checkUserInfo:
    'api/enterprise/info/company' /* TODO:找回账号验证用户信息 step1 post */,
  amendNumber:
    'api/enterprise/user/retrieval' /* TODO:找回用户账号 step2  put */,

  searchProduct: '/api/xwProduct/back' /* TODO:产品搜索 */,
  /* TODO: 公司信息的增删改查 */
  billInfo: '/api/enterprise/info' /* TODO:查询企业公司信息 */,
  addInfo: '/api/enterprise/info' /* TODO:增加企业公司信息 */,
  changeInfo: '/api/enterprise/info' /* TODO:修稿企业公司信息 */,
  delInfo: '/api/enterprise/info' /* TODO:删除企业公司信息 */,

  /* TODO: 收货人（邮寄地址）的增删改查 */
  billAddress: '/api/enterprise/address' /* TODO:查询邮寄地址 */,
  addAddress: '/api/enterprise/address' /* TODO:新增邮寄地址 */,
  changeAddress: '/api/enterprise/address' /* TODO:修改邮寄地址 */,
  delAddress: '/api/enterprise/address' /* TODO:删除邮寄地址 */,

  /* TODO: 邮寄管理  */
  publicMessage: '/api/mail' /* TODO: 15天内邮寄信息查询 */,
  addMessage: '/api/mail' /* TODO: x创建新的邮寄信息 */,
  alterMessage: '/api/mail' /* TODO: 修改邮寄信息 */,
  delMessage: '/api/mail' /* TODO:  删除邮寄信息*/,
  allMessage: '/api/mail/all' /* TODO: 查看所有邮寄信息 */,

  /* TODO: 公告信息 */
  newPublicMessage: '/api/notice' /* TODO: 查询最新的公告信息 */,
  addPublicMessage: '/api/notice' /* TODO: 创建新的公告 */,
  alterPublicMessage: '/api/notice' /* TODO: 修改公告 */,
  delPublicMessage: '/api/notice' /* TODO: 删除公告 */,
  allPublicMessage: '/api/notice/all' /* TODO: 查看所有的公告信息 */,

  /* TODO: 提交订单 */
  confirmOrder: '/api/xwOrder/create',
  /* TODO: 车间videoUrl */
  videoUrl: '/api/room',
  /* TODO: 录播 */
  getVideos: '/api/video',
  customerInfo: '/api/contactus' /* TODO:保存客户信息 */,
  wechat: '/api/wxa/applet',

  /* TODO：new 修比 api */
  inquireDepartments: '/api/dept/all', //查询部门
  liveVideo: '/api/room', //直播列表
  staffList: '/api/employees', //查询员工
  productList: '/api/product/back', //产品列表
  productNav: '/api/product/type', //产品类别
  express: '/api/mail', //邮寄信息
  record: '/api/chat/record', //评论
  ifHasPhone: '/api/enterprise/user/create/', //注册时判断是否有该手机号
  sendMessage: '/api/sms/send', //发送验证码
  login: '/api/enterprise/user/loginPlatform' /* TODO:登陆 */,
  ifNumber: 'api/enterprise/user/create/' /* TODO: 注册时判断是否有该手机号 */,
  codeTest: '/api/sms/test', //测试验证码是否正确
  regapi: '/api/enterprise/user' /* TODO:注册接口    */,
  passwordChange:
    'api/enterprise/user/update/pass' /* TODO:登陆后用户修改密码 */,
  creatOrders: '/api/xwOrder/create', //创建新订单
  allOrder: '/api/xwOrder', //所有订单
  searchProduct: '/api/product/back',
}

export default api
