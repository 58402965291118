<template>
  <div
    @mouseenter="infoStatus = true"
    @mouseleave="infoStatus = false"
    class="announcement-container"
  >
    <div>
      <ul class="announcement-nav">
        <li>
          <img alt src="../../../assets/images/icon/WechatIMG593.png" />
        </li>
        <li>公告</li>
        <li @click="checkMore">{{!moreAnnouncmentStatus?'查看更多':'关闭'}}</li>
      </ul>
      <div class="info">
        <transition name="infos">
          <div @click="checkMore" class="intro" v-show="infoStatus">{{info}}</div>
        </transition>
      </div>
    </div>
    <transition>
      <div
        :style="position"
        class="announcement-open"
        ref="announcement"
        v-if="moreAnnouncmentStatus"
      >
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="head"
        ></div>
        <div class="intros">{{info}}</div>
        <div @click="checkMore" class="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moveData from '../../../mixins/move'

export default {
  name: '',
  mixins: [moveData],

  data () {
    return {
      msg: '',
      info: '',
      moveStatus: false,
      publicTime: '',
      infoStatus: true,

    }
  },
  created () {
  },
  mounted () {
    this.getData()

  },
  computed: {
    ...mapState(['moreAnnouncmentStatus', 'userInfo', 'company']),

  },
  watch: {
    userInfo (newValue, oldValue) {
      if (newValue) {
        this.getData()
      }
    },
    company (newValue, oldValue) {
      console.log('company55', newValue);
      this.getData()
    },
    moreAnnouncmentStatus (newValue, oldValue) {
      if (newValue) {
        console.log('width', document.body.clientWidth, this.$refs.announcement);
        this.x = (document.body.clientWidth - 318) / 2
        this.y = 80
      } else {
        return
      }
    }
  },
  methods: {
    mousedown (event) {
      //鼠标按下事件
      this.leftOffset = event.offsetX;
      this.topOffset = event.offsetY;
      this.isMove = true;
      event.target.style.cursor = 'move'
      console.log('event', event.$el, event.target.style.cursor = 'move');

    },
    //鼠标移动
    mousemove (event) {
      if (!this.isMove) {
        return;
      }
      this.x = event.clientX - this.leftOffset;
      this.y = event.clientY - this.topOffset;
      console.log('event');
      // if (this.x <= 0) {
      //   this.x = this.$refs.position.offsetLeft
      // }
    },
    //鼠标抬起
    mouseup (event) {
      this.isMove = false;
      event.target.style.cursor = 'unset';
    },
    checkMore () {
      this.$store.state.moreAnnouncmentStatus = !this.$store.state.moreAnnouncmentStatus

    },
    getData () {
      console.log('this.userInfo1', this.userInfo, this.company);

      if (this.company) {
        let id = this.company.id
        this.$request.get(this.$api.newPublicMessage, { id: id }).then(res => {
          console.log("ddd6666", res);
          this.info = res.data.info;
          this.publicTime = res.data.dateTime;
        });
      }
      setTimeout(() => {
        this.infoStatus = false;
      }, 120000)

    }



  }
}
</script>

<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 269px;
  }
}
@keyframes moves {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.infos-enter-active {
  animation: moves 0.45s linear;
}

/* 离开动画 */
.infos-leave-active {
  animation: moves 5s reverse;
}
.announcement-container {
  // position: relative;
  .announcement-open {
    position: fixed;

    z-index: 10;
    width: 318px;
    height: 269px;
    background-color: #fff;
    background: url(../../../assets/images/news/公告板.png) no-repeat;
    color: #fff;
    overflow: hidden;
    backdrop-filter: blur(5px);
    .head {
      width: 100%;
      height: 30px;
      position: absolute;
      left: 0;
      top: 0;
    }
    > .intros {
      padding: 30px 20px;
    }
    > .close {
      width: 30px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
    }
  }
  .announcement-nav {
    display: flex;
    height: 20px;
    align-items: center;
    > li {
      height: 20px;
      display: flex;
      align-items: center;
      &:nth-child(1) {
        margin-right: 8px;
      }
      &:nth-child(2) {
        font-size: 16px;
        color: #ffffff;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          display: block;
          height: 1px;
          width: 8px;
          background: #fff;
          top: 58%;
          right: -12px;
        }
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 103px;
          height: 1px;
          right: -107px;
          top: 58%;
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
      &:nth-child(3) {
        width: 79px;
        height: 17px;
        display: block;
        background: url(../../../assets/images/staff/circle.png) no-repeat;
        text-align: center;
        line-height: 17px;
        color: #fff;
        font-size: 12px;
        // margin-left: 35px;
        margin-left: auto;
        margin-top: 5px;
        background-size: contain;
        backdrop-filter: blur(20px);
        overflow: hidden;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  .info {
    height: 75px;
    padding: 10px 0;
  }
  .intro {
    padding: 10px 15px;
    margin-top: 10px;
    width: 240px;
    height: 75px;
    background: url(../../../assets/images/icon/WechatIMG595.png) no-repeat;
    font-size: 12px;
    color: #fff;
    backdrop-filter: blur(3px);
    &:hover {
      // background: url(../../../assets/images/icon/express.png) no-repeat;
    }
  }
}
</style>
