<template>
  <div class="box">
    <!-- 使用了登录界面模板 -->
    <transition>
      <div :style="position" class="login-cantainter" v-if="billAddressStatus">
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="head"
        ></div>
        <span @click="close" class="close"></span>
        <div class="animate">
          <!-- animate盒子解决 有paddig 导致动画不连贯 -->
          <ul class="login-password">
            <li :class="[navStatus?'active1':'']" @click="changLogin(1)">发票信息</li>
            <li :class="[!navStatus?'active2':'']" @click="changLogin(2)">地址信息</li>
          </ul>
          <ul class="login-list li">
            <li v-if="navStatus">
              <ul>
                <li class="add">
                  <span
                    :class="[addStatus?'icon-active':'']"
                    @click="addBill"
                    class="iconfont icon-jiahao"
                  ></span>
                  <div class="add-edit" v-if="addStatus">
                    <span @click="confirm">确认</span>
                    <span @click="cancel">取消</span>
                  </div>
                </li>
                <transition name="change">
                  <li v-if="addStatus">
                    <ul class="part2">
                      <li>
                        <textarea
                          :class="[nameStatus?'textarea-test':'']"
                          cols="30"
                          id
                          name
                          placeholder="请填入企业名称/必填"
                          rows="1"
                          v-model="companyInfo.name"
                        ></textarea>
                        <span>企业</span>
                      </li>
                      <li>
                        <textarea
                          :class="[creditCodeStatus?'textarea-test':'']"
                          @input="test(companyInfo.creditCode)"
                          cols="30"
                          id
                          name
                          placeholder="请填入企业税号/必填"
                          rows="1"
                          v-model="companyInfo.creditCode"
                        ></textarea>
                        <span>税号</span>
                      </li>
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入地址"
                          rows="1"
                          v-model="companyInfo.registerAddress"
                        ></textarea>
                        <span>地址</span>
                      </li>
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入电话"
                          rows="1"
                          v-model="companyInfo.phone"
                        ></textarea>
                        <span>电话</span>
                      </li>
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入银行"
                          rows="1"
                          v-model="companyInfo.accountOpening"
                        ></textarea>
                        <span>银行</span>
                      </li>
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入账号"
                          rows="1"
                          v-model="companyInfo.bankAccount"
                        ></textarea>
                        <span>账号</span>
                      </li>
                    </ul>
                  </li>
                </transition>
                <li :key="index" v-for="(item,index) in companyList">
                  <div class="part1">
                    <div
                      :class="[indexs == index?'active':'']"
                      @click="companyChange(index)"
                      class="name"
                    >{{item.name}}</div>
                    <div class="edit">
                      <div class="current">
                        <span
                          @click="billChange(index,item)"
                        >{{changeStatus&&indexs==index?"确认":'修改'}}</span>
                        <span
                          @click="billDelete(index,item)"
                        >{{changeStatus&&indexs==index?"取消":'删除'}}</span>
                      </div>
                      <!-- <div class="next" v-else>
                        <span @click="billChange(index,item)">确认</span>
                        <span @click="billCancel(index,item)">取消</span>
                      </div>-->
                    </div>
                  </div>
                  <transition name="change">
                    <ul class="part2" v-if="changeStatus && companyIndex == index">
                      <li>
                        <textarea
                          :class="[changeNameStatus?'textarea-test':'']"
                          cols="30"
                          placeholder="请填入企业名称/必填"
                          rows="1"
                          v-model="item.name"
                        ></textarea>
                        <span>企业</span>
                      </li>
                      <li>
                        <textarea
                          :class="[changeCreditCodeStatus?'textarea-test':'']"
                          @input="testChange(index,item.creditCode)"
                          cols="30"
                          id
                          name
                          placeholder="请填入企业税号/必填"
                          rows="1"
                          v-model="item.creditCode"
                        ></textarea>
                        <span>税号</span>
                      </li>
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入地址"
                          rows="1"
                          v-model="item.registerAddress"
                        ></textarea>
                        <span>地址</span>
                      </li>
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入电话"
                          rows="1"
                          v-model="item.phone"
                        ></textarea>
                        <span>电话</span>
                      </li>
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入银行"
                          rows="1"
                          v-model="item.accountOpening"
                        ></textarea>
                        <span>银行</span>
                      </li>
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入账号"
                          rows="1"
                          v-model="item.bankAccount"
                        ></textarea>
                        <span>账号</span>
                      </li>
                    </ul>
                  </transition>
                </li>
              </ul>
            </li>

            <li class="login-code li" v-else>
              <ul>
                <li class="add">
                  <span
                    :class="[addAddressStatus?'icon-active':'']"
                    @click="addAddress"
                    class="iconfont icon-jiahao"
                  ></span>
                  <div class="add-edit" v-if="addAddressStatus">
                    <span @click="confirmAddress">确认</span>
                    <span @click="cancelAddress">取消</span>
                  </div>
                </li>
                <transition name="change">
                  <li v-if="addAddressStatus">
                    <ul class="part2">
                      <li>
                        <textarea
                          cols="30"
                          id
                          name
                          placeholder="请填入店名"
                          rows="1"
                          v-model="addressInfo.companyName"
                        ></textarea>
                        <span>店名</span>
                      </li>
                      <li>
                        <textarea
                          :class="[addressStatus?'textarea-test':'']"
                          cols="30"
                          id
                          name
                          placeholder="请填入地址/必填"
                          rows="1"
                          v-model="addressInfo.address"
                        ></textarea>
                        <span>地址</span>
                      </li>
                      <li>
                        <textarea
                          :class="[getNameStatus?'textarea-test':'']"
                          cols="30"
                          id
                          name
                          placeholder="请填入收件人/必填"
                          rows="1"
                          v-model="addressInfo.name"
                        ></textarea>
                        <span>收件人</span>
                      </li>
                      <li>
                        <textarea
                          :class="[phoneStatus?'textarea-test':'']"
                          cols="30"
                          id
                          name
                          placeholder="请填入电话/必填"
                          rows="1"
                          v-model="addressInfo.phone"
                        ></textarea>
                        <span>电话</span>
                      </li>
                    </ul>
                  </li>
                </transition>
                <li :key="index" v-for="(item,index) in addressList">
                  <div class="part1">
                    <div
                      :class="[addressIndex == index?'active':'']"
                      @click="addressChange(index)"
                      class="name"
                    >{{item.address}}</div>
                    <div class="edit">
                      <!-- <span>修改</span>
                      <span>删除</span>-->
                      <div class="current">
                        <span
                          @click="addressEdit1(index,item)"
                        >{{addressChangeStatus&&addressIndex==index?"确认":'修改'}}</span>
                        <span
                          @click="addressDelet(index,item)"
                        >{{addressChangeStatus&&addressIndex==index?"取消":'删除'}}</span>
                      </div>
                      <!-- <div class="next" v-else>
                        <span @click="addressEdit1(index,item)">确认</span>
                        <span @click="addressCancel(index,item)">取消</span>
                      </div>-->
                      <!-- <span
                        @click="addressEdit1(index,item)"
                      >{{addressChangeStatus&&addressIndex == index?'确认':'修改'}}</span>
                      <span
                        @click="addressEdit2(index)"
                      >{{addressChangeStatus&&addressIndex == index?'取消':'删除'}}</span>-->
                    </div>
                  </div>
                  <transition>
                    <ul class="part2" v-if="addressChangeStatus && addressIndex == index">
                      <li>
                        <textarea cols="30" id name rows="1" v-model="item.companyName"></textarea>
                        <span>店名</span>
                      </li>

                      <li>
                        <textarea cols="30" id name rows="1" v-model="item.address"></textarea>
                        <span>地址</span>
                      </li>
                      <li>
                        <textarea cols="30" id name rows="1" v-model="item.name"></textarea>
                        <span>收件人</span>
                      </li>
                      <li>
                        <textarea cols="30" id name rows="1" v-model="item.phone"></textarea>
                        <span>电话</span>
                      </li>
                    </ul>
                  </transition>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @import
import { mapState } from 'vuex'
import moveData from '../../../mixins/move'

export default {
  components: {},
  props: {},
  mixins: [moveData],

  data () {
    return {
      status: true,
      nameStatus: false,
      creditCodeStatus: false,
      getNameStatus: false,
      addressStatus: false,
      phoneStatus: false,
      companyList: [],
      addressList: [],
      companyIndex: 0,
      // addressIndex: 0,
      changeStatus: false,
      addressChangeStatus: false,
      addStatus: false,
      addAddressStatus: false,
      changeNameStatus: false,
      changeCreditCodeStatus: false,
      noChangeBill: '',
      noChangeAddress: '',
      companyInfo: {
        name: '',
        creditCode: '',
        registerAddress: '',
        phone: '',
        accountOpening: '',
        bankAccount: '',
        enterpriseUser: {
          id: ""
        }
      },
      addressInfo: {
        companyName: '',
        address: '',
        name: '',
        phone: '',
        enterpriseUser: {
          id: ""
        }
      },
      editIndex: 0
    };
  },

  created () {

  },
  mounted () {
    this.getData()
  },
  computed: {
    ...mapState(['billAddressStatus', 'userInfo', 'indexs', 'addressIndex', 'navStatus'])

  },
  watch: {
    userInfo (newValue, oldValue) {
      if (newValue.enterpriseUserDTO.id !== '') {
        this.getData()
      }
    },

    companyList (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.state.hasChangeBill = true

      }
    },
    addressList (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.state.hasChangeAddress = true

      }
    },
    billAddressStatus (newValue, oldValue) {
      if (newValue) {
        console.log('width', document.body.clientWidth, this.$refs.announcement);
        this.x = (document.body.clientWidth - 318) / 2
        this.y = 80
      } else {
        return
      }
    }

  },
  methods: {
    test (str) {
      // replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").
      this.companyInfo.creditCode = str.replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").toUpperCase()
    },
    testChange (index, str) {
      this.companyList[index].creditCode = str.replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").toUpperCase()
    },
    changLogin (index) {
      this.status = !this.status
      this.$store.state.navStatus = !this.$store.state.navStatus
    },
    close () {
      this.$store.state.billAddressStatus = !this.$store.state.billAddressStatus
      // this.$store.state.selectStatus = true
    },
    getData () {
      if (this.userInfo) {
        var id = this.userInfo.enterpriseUserDTO.id;

      }
      this.$request.get(this.$api.billInfo, { id: id }).then(res => {
        console.log("2ee2ok", res);
        this.companyList = res.data.reverse();
        this.noChangeBill = JSON.parse(JSON.stringify(res.data))
        this.$store.state.bills = res.data.reverse();
      });
      this.$request.get(this.$api.billAddress, { id: id }).then(res => {
        console.log("2ee2444444444444ok", res);
        this.addressList = res.data.reverse();
        this.noChangeAddress = JSON.parse(JSON.stringify(res.data))
        this.$store.state.address = res.data.reverse();

      });
    },
    companyChange (index) {
      this.companyIndex = index
      this.$store.state.indexs = index
    },
    addressChange (index) {
      // this.addressIndex = index
      this.$store.state.addressIndex = index

    },
    addBill () {
      this.addStatus = true
    },

    //添加发票信息
    confirm () {
      if (this.companyInfo.name !== '' && this.companyInfo.creditCode !== '') {
        this.companyInfo.enterpriseUser.id = this.userInfo.enterpriseUserDTO.id;
        // 添加企业信息
        this.$request
          .post(this.$api.addInfo, this.companyInfo)
          .then(res => {
            this.getData()
          })
          .catch(err => {
            console.log(err);
          });
        this.addStatus = !this.addStatus
      } else {
        this.companyInfo.name == '' ? this.nameStatus = true : this.nameStatus = false
        this.companyInfo.creditCode == '' ? this.creditCodeStatus = true : this.creditCodeStatus = false
      }
    },
    cancel () {
      this.addStatus = !this.addStatus
      this.nameStatus = false
      this.creditCodeStatus = false


    },
    addAddress () {
      this.addAddressStatus = true

    },
    confirmAddress () {

      if (this.addressInfo.name !== '' && this.addressInfo.address !== '' && this.addressInfo.phone !== '') {

        this.addressInfo.enterpriseUser.id = this.userInfo.enterpriseUserDTO.id;
        this.$request
          .post(this.$api.addAddress, this.addressInfo)
          .then(res => {
            console.log("res123", res);
            if (res.status == 200 || res.status == 201) {
              this.getData()

            }

          }).catch(err => {

          })




        this.addAddressStatus = !this.addAddressStatus


      } else {
        this.addressInfo.name == '' ? this.getNameStatus = true : this.getNameStatus = false
        this.addressInfo.address == '' ? this.addressStatus = true : this.addressStatus = false
        this.addressInfo.phone == '' ? this.phoneStatus = true : this.phoneStatus = false

      }

    },
    cancelAddress () {
      this.addAddressStatus = !this.addAddressStatus
      this.getNameStatus = false
      this.addressStatus = false
      this.phoneStatus = false

    },

    billChange (index, item) {
      this.billEditIndex = index
      this.$store.state.hasChangeBill = false
      if (this.companyIndex == index) {


        if (item.name !== '' && item.creditCode !== '') {

          let obj = JSON.parse(JSON.stringify(item))
          obj.enterpriseUser = {}
          obj.enterpriseUser.id = this.userInfo.enterpriseUserDTO.id;
          console.log('itt啊', '不一样', obj, this.userInfo);
          // this.changeStatus = !this.changeStatus
          this.$request
            .put(this.$api.changeInfo, obj)
            .then(res => {
              console.log('itta', res);

              if (res.status == 200) {
                this.getData()
                this.changeStatus = !this.changeStatus
              }
            });




        } else {
          item.name == '' ? this.changeNameStatus = true : this.changeNameStatus = false
          item.creditCode == '' ? this.changeCreditCodeStatus = true : this.changeCreditCodeStatus = false
        }



      } else {
        this.changeStatus = true
      }
      this.companyIndex = index
      this.$store.state.indexs = index
    },
    billCancel () {
      this.changeStatus = false

    },
    billDelete (index, item) {
      if (this.changeStatus && this.billEditIndex == index) {
        this.changeStatus = false

      } else {
        let id = this.companyList[index].id
        this.$request.delete(this.$api.delInfo + '/' + id).then(res => {
          if (res.status == 200) {
            this.companyList.splice(index, 1);
          }
          console.log("res", res);
        });
      }

    },
    // editTwo (index, item) {


    //   if (this.changeStatus) {

    //     console.log('itemmmss', item, this.companyList[index]);
    //     this.companyList[index] = this.noChangeBill[index]
    //     this.changeStatus = !this.changeStatus
    //     this.companyIndex = index
    //   } else {
    //     let id = this.companyList[index].id
    //     this.$request.delete(this.$api.delInfo + '/' + id).then(res => {
    //       if (res.status == 200) {
    //         this.companyList.splice(index, 1);
    //       }
    //       console.log("res", res);
    //     });
    //   }

    // },
    addressEdit1 (index, item) {
      console.log('itempp', item);
      this.editIndex = index
      // this.noChangeAddress = JSON.parse(JSON.stringify(item))

      this.$store.state.hasChangeAddress = false


      if (this.addressIndex == index) {

        if (item.address !== '' && item.name !== '' && item.phone !== '') {
          let obj = JSON.parse(JSON.stringify(item))
          obj.enterpriseUser = {}
          obj.enterpriseUser.id = this.userInfo.enterpriseUserDTO.id;
          this.$request.put(this.$api.changeAddress, obj).then(res => {
            console.log('resnewsss', res)
            this.getData()
            this.addressChangeStatus = !this.addressChangeStatus
          })
        }
      } else {
        this.addressChangeStatus = true

      }

      this.addressIndex = index
      this.$store.state.addressIndex = index


    },
    addressDelet (index) {
      if (this.addressChangeStatus && this.editIndex == index) {
        this.addressChangeStatus = false

      } else {
        let id = this.addressList[index].id
        this.$request.delete(this.$api.delAddress + '/' + id).then(res => {
          if (res.status == 200) {
            this.addressList.splice(index, 1);
          }
          console.log("res", res);
        });
      }

    },
    addressCancel () {
    },
    addressEdit2 (index) {

      if (this.addressChangeStatus) {
        this.addressList[index] = this.noChangeAddress[index]

        this.addressChangeStatus = !this.addressChangeStatus
        this.addressIndex = index
      } else {
        let id = this.addressList[index].id
        this.$request.delete(this.$api.delAddress + '/' + id).then(res => {
          if (res.status == 200) {
            this.addressList.splice(index, 1);
          }
          console.log("res", res);
        });
      }

    },
  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 318px;
  }
}
@keyframes moves {
  from {
    height: 0;
  }
  to {
    height: 318px;
  }
}
/* 进入动画 */
.change-enter-active {
  animation: moves 0.25s linear;
}

/* 离开动画 */
.change-leave-active {
  animation: moves 0.25s reverse;
}

textarea {
  font-size: 11px;
}
.login-cantainter {
  position: fixed;

  width: 318px;
  height: 370px;
  background: url(../../../assets/images/login/密码登录-背景.png) no-repeat;
  backdrop-filter: blur(5px);
  overflow: hidden;
  z-index: 999;

  .head {
    height: 35px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .close {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 999;
  }
  .animate {
    width: 100%;
    height: 100%;
    padding: 49px 23px;
  }
  .login-password {
    display: flex;
    width: 271px;
    background: url(../../../assets/images/login/密码登录-选择按钮2.png)
      no-repeat;
    .active1 {
      background: url(../../../assets/images/login/密码登录-选择按钮1.png)
        no-repeat;
    }
    .active2 {
      background: url(../../../assets/images/login/验证码登录-选择按钮1.png)
        no-repeat;
    }
    > li {
      width: 138px;
      height: 33px;
      text-align: center;
      line-height: 33px;
      color: #fff;
    }
  }

  .li {
    > li {
      > ul {
        max-height: 260px;
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
          height: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #ccc;
        }
        &::-webkit-scrollbar-button {
          width: 10px;
          border-radius: 50%;
          background: black;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px #333;
          border-radius: 5px;
          // background: blue;
        }
        &::-webkit-scrollbar-corner {
          background: springgreen;
        }

        .active {
          color: #05f8ff;
        }

        .add {
          margin-top: 17px;
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between;

          .iconfont {
            flex-basis: 60%;
          }
          .icon-active {
            color: #f49e0f;
          }

          > .add-edit {
            flex-basis: 40%;
            color: #fff;
            > span {
              margin-left: 15px;

              &:hover {
                color: #05f8ff;
              }
            }
          }
          > span {
            font-size: 18px;
            color: #fff;
          }
        }
        > li {
          // display: flex;
          // color: #fff;
          margin-bottom: 15px;
          .part1 {
            display: flex;
            // margin-top: 15px;
            color: #fff;
            width: 100%;
          }

          .part2 {
            margin-top: 10px;
            li {
              display: flex;
              margin-top: 10px;

              textarea {
                resize: none;
                background: transparent;
                border: none;
                color: #fff;
                // border-bottom: 1px solid #ccc;
                // background: #8f4833;
                border-bottom: 1px solid #ccc;
                width: 200px;
                margin-right: 18px;

                padding: 2px 10px;
                &::-webkit-input-placeholder {
                  color: #ccc;
                }
              }
              .textarea-test {
                &::-webkit-input-placeholder {
                  color: red;
                }
              }
              span {
                color: #fff;
                font-size: 13px;
              }
            }
          }
          .name {
            flex-basis: 60%;
            font-size: 13px;
            &:hover {
              color: #05f8ff;
              cursor: pointer;
            }
          }
          .edit {
            flex-basis: 40%;
            display: flex;
            font-size: 13px;
            span {
              margin-left: 15px;
              &:hover {
                color: #05f8ff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .login-list {
    > li {
    }
    .login-code {
    }
  }
}
</style>