<template>
  <div class="order">
    <transition>
      <div :style="position" class="order-box" v-if="orderListStatus">
        <div
          @mousedown.stop="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="move"
        ></div>
        <span @click="close" class="close"></span>

        <div class="head">
          <span>修比印刷订单</span>
        </div>
        <div class="intro">
          <table class="orderInfo">
            <tr>
              <td class="order-number">历史编号</td>
              <td>订货人</td>
              <td>订单金额</td>
              <td>订单日期</td>
              <td>物流详情</td>
              <td>操作</td>
            </tr>
            <tr :key="index" v-for="(item,index) in dataList">
              <td>{{item.orderNumber}}</td>
              <td>{{item.enterpriseUser.userName}}</td>
              <td>{{item.orderAmount}}</td>
              <td>{{item.createTime | formatData}}</td>
              <td>{{item.expressNumber== null||item.expressNumber == ''?'未发货':item.expressNumber}}</td>
              <td @click="checkDetail(item)">查看详情</td>
            </tr>
          </table>
        </div>
        <div class="footer">
          <div class="pagination">
            <ul>
              <li>
                <span>共</span>
                <span>{{total}}</span>条
              </li>
              <li @click="changePage" class="change">
                <div>
                  <span>{{pageData.pageSize}}</span>条/页
                </div>
                <span :class="[pageStatus?'active':'']" class="iconfont icon-xiangxia1"></span>
                <!-- <span class="iconfont icon-xiangshang1"></span> -->
                <transition name="page">
                  <ul class="page-list" v-if="pageStatus">
                    <li
                      :key="index"
                      @click="changNum(item)"
                      v-for="(item,index) in  pageList"
                    >{{item}}</li>
                  </ul>
                </transition>
              </li>
              <li class="go">
                前往
                <input @blur="jumpPage" @keyup.enter="jumpPage" v-model="pageData.currentPage" />页
              </li>
            </ul>
          </div>
          <button @click="pre" class="pre">上一页</button>
          <button @click="next" class="next">下一页</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @import
import { mapState } from 'vuex'
import utils from "../../../assets/js/utils.js";
import vm from '../../../assets/js/event'
import moveData from '../../../mixins/move'

export default {
  components: {},
  props: {},
  mixins: [moveData],

  data () {
    return {
      value: 2,
      pageList: [5, 10],
      dataList: [],
      pageStatus: false,
      pageData: {
        pageSize: 10,
        currentPage: 1
      },
      total: '',
      nums: 9
    };
  },

  created () {

  },
  mounted () {
    this.getOrderList()

  },
  computed: {
    ...mapState(['orderListStatus', 'userInfo'])

  },
  filters: {
    formatData (time) {
      var data = new Date(time);
      return utils.formatDate(data, "yyyy-MM-dd");
    }
  },
  watch: {
    userInfo (newValue, oldValue) {
      if (newValue) {
        this.getOrderList()

      }
    },
    orderListStatus (newValue, oldValue) {
      if (newValue) {
        console.log('width', document.body.clientWidth, this.$refs.announcement);
        this.x = (document.body.clientWidth - 608) / 2
        this.y = 80
      } else {
        return
      }
    }
  },
  methods: {
    getOrderList () {
      if (this.userInfo) {
        var id = this.userInfo.enterpriseUserDTO.id

      }
      let data = {
        page: this.pageData.currentPage - 1,
        size: this.pageData.pageSize,
        id: id,
        sort: "id,desc"
      }
      this.$request.get(this.$api.allOrder, data).then(res => {
        this.total = res.data.totalElements;
        this.dataList = res.data.content;
        console.log('wewe', res);
      })
    },
    close () {
      this.$store.state.orderListStatus = !this.$store.state.orderListStatus
    },
    changePage () {
      this.pageStatus = !this.pageStatus
    },
    changNum (num) {
      this.pageData.pageSize = num

      let maxNum = Math.ceil(this.total / this.pageData.pageSize)

      if (this.pageData.currentPage >= maxNum) {
        this.pageData.currentPage = maxNum

      }
      this.getOrderList()

    },
    jumpPage () {
      let maxNum = Math.ceil(this.total / this.pageData.pageSize)

      if (this.pageData.currentPage >= maxNum) {
        this.pageData.currentPage = maxNum

      }
      this.getOrderList()

    },
    pre () {
      if (this.pageData.currentPage == 1) {
        this.pageData.currentPage = 1
        this.getOrderList()

        return
      } else {
        this.pageData.currentPage--
        this.getOrderList()

      }
    },
    next () {

      let maxNum = Math.ceil(this.total / this.pageData.pageSize)
      if (this.pageData.currentPage >= maxNum) {
        this.pageData.currentPage = maxNum
        this.getOrderList()

      } else {
        this.pageData.currentPage++
        this.getOrderList()

      }
    },
    checkDetail (items) {

      this.$store.state.confirmOrderStatus = true;


      this.$store.state.checkOrder = false;
      let billId = items.enterpriseInfo.id;
      let addressId = items.enterpriseAddress.id;
      // this.$router.push({ name: 'Order', params: { billId: billId, addressId: addressId } });
      let billAndAddress = {
        billId: billId, addressId: addressId
      }


      vm.$emit("billAndAddress", billAndAddress)



      // 设置相对应的数据格式

      if (items.orderDetails.length > 0) {
        let historyData = []
        items.orderDetails.forEach((item, index) => {
          var obj = {
            productId: item.detailsId,
            price: item.price,
            orderNumber: item.orderNumber,
            product: item.product
          };

          historyData.push(obj)
        });
        // console.log('this.historyData', this.historyData);
        this.$store.state.orders = historyData
      }

      console.log('oioiooi', items);
      console.log('oioiooi', historyData);


      // var saveData = JSON.stringify(this.historyData);
      // window.sessionStorage.setItem("listInfo", saveData);
    }

  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';

.page-enter-active {
  animation: pageMove 0.25s linear;
}
.page-leave-active {
  animation: pageMove 0.25s reverse;
}
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 689px;
  }
}
@keyframes pageMove {
  from {
    height: 0;
    // bottom: 0;
  }
  to {
    // bottom: 24px;
    height: 60px;
  }
}
.order {
  .close {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 999;
  }
  .order-box {
    position: fixed;

    width: 608px;
    height: 689px;
    background: url(../../../assets/images/orderList/订单列表背景.png) no-repeat;
    backdrop-filter: blur(5px);
    overflow: hidden;
    .move {
      width: 100%;
      height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
    }
    .head {
      height: 30px;
      width: 100%;
      margin-top: 30px;
      padding-left: 25px;
      border-bottom: 1px solid #cccccc;

      span {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        position: relative;
        color: #fff;
        &::after {
          display: inline-block;
          content: '';
          width: 90px;
          height: 1px;
          background: #fff;
          position: absolute;
          bottom: 0px;
          left: 0;
        }
      }
    }
    .intro {
      padding: 20px 25px 20px 25px;
      // overflow: hidden;
      .orderInfo {
        width: 558px;
        border: 1px solid #989c9b;
        color: #fff;
        tr {
          height: 40px;
          border-bottom: 1px solid #989c9b;

          &:nth-child(1) {
            background: url(../../../assets/images/orderList/横条1.png);
          }
          > td {
            text-align: center;
            &:nth-child(1) {
              width: 175px;
            }
          }
        }
      }
      // margin-top: 25px;
    }
    .footer {
      padding: 0 25px;
      position: absolute;
      bottom: 20px;
      display: flex;
      align-items: center;
      > .pagination {
        width: 250px;
        margin-right: 20px;

        > ul {
          display: flex;
          color: #fff;
          align-items: center;
          > li {
          }

          .active {
            transform: rotate(-180deg);
          }
          .change {
            display: flex;
            align-items: center;
            padding: 5px;
            margin-right: 10px;
            margin-left: 5px;
            width: 80px;
            height: 26px;
            border: 1px solid #ccc;
            position: relative;
            > div {
              margin-right: 2px;
            }
            span {
              transition: 0.35s;
            }

            .page-list {
              border: 1px solid #ccc;
              position: absolute;
              bottom: 24px;
              left: -1px;
              height: 60px;
              width: 80px;
              overflow: hidden;
              li {
                height: 30px;
                line-height: 30px;
                text-align: center;
              }
            }
          }
          .go {
            display: flex;
            align-items: center;
            > input {
              display: inline-block;
              width: 42px;
              height: 26px;
              text-align: center;
              border: 1px solid #ccc;
              margin: 0 10px;
              background: transparent;
            }
          }
        }

        /deep/.poppers {
          background-color: red !important;
        }
        /deep/.el-pagination {
          display: flex;
          align-items: center;
        }
        /deep/.el-pagination__total {
          color: #fff;
        }
        /deep/.el-pagination .el-select .el-input {
          width: 100% !important;
          background: transparent;
          margin-top: 0 !important;
        }
        /deep/.el-input {
          margin-top: 0 !important;
        }
        /deep/.el-pagination .el-select .el-input .el-input__inner {
          background: transparent;
          color: #fff;
        }
        /deep/.el-pagination__total {
          margin-right: 0 !important;
        }
        /deep/.el-pagination__jump {
          margin-left: 5px;
        }
        /deep/.el-pagination__editor.el-input .el-input__inner {
          background: transparent;
          color: #fff;
          width: 40px;
        }
        /deep/.el-select-dropdown__wrap {
          background: transparent;
        }
        /deep/.poppers {
          width: 40px;
          background-color: red !important;
        }
        /deep/.el-popper[x-placement^='bottom'] {
          background-color: red !important;
        }
        /deep/.el-select-dropdown {
          background: transparent;
        }
        /deep/.el-popper {
          background: transparent !important;
        }
        /deep/.el-pagination__jump {
          color: #fff;
        }
        /deep/.el-pagination__sizes {
          width: 80px;
          background: transparent;
        }
        /deep/.btn-prev {
          display: none;
        }
        /deep/.el-pager {
          display: none;
        }
        /deep/.btn-next {
          display: none;
        }
        // /deep/.el-select .el-input.is-focus .el-input__inner {
        //   border-color: #ccc;
        // }
      }
      button {
        width: 130px;
        height: 41px;
      }
      .pre {
        background: url(../../../assets/images/orderList/下一页按钮2.png)
          no-repeat;
      }
      .next {
        margin-left: 20px;
        background: url(../../../assets/images/orderList/下一页按钮1.png)
          no-repeat;
      }
    }
  }
}
/deep/.poppers {
  width: 40px;
  background-color: red !important;
}
/deep/.el-popper[x-placement^='bottom'] {
  background-color: red !important;
}
</style>