<template>
  <div class="work-shop-container">
    <div class="first-work">
      <div @click="firstWork" class="title">
        <span>{{liveVideoName}}</span>
        <span class="iconfont icon-xiangxia" v-if="!firstWorkList"></span>
        <span class="iconfont icon-xiangshang" v-else></span>
      </div>
      <!-- <transition name="firstWork"> -->
      <ul class="first-work-list" v-show="firstWorkList">
        <li
          :class="[liveCurrentIndex==index?'checked':'',firstWorkList?'li-enter':'']"
          :key="index"
          @click="toggleLiveWorkSpace(item,index)"
          ref="lili"
          v-for="(item,index) in liveVideoList"
        >{{item.roomName}}</li>
      </ul>
      <!-- </transition> -->
    </div>

    <div class="second-work">
      <div @click="secondWork" class="title">
        <span>{{hasVideoName}}</span>
        <span class="iconfont icon-xiangxia" v-if="!secondWorkList"></span>
        <span class="iconfont icon-xiangshang" v-else></span>
      </div>
      <ul v-show="secondWorkList">
        <li
          :class="[hasCurrentIndex == index?'activeHas':'',secondWorkList?'li-enter':'']"
          :key="index"
          @click="changeVideos(item,index)"
          ref="hasli"
          v-for="(item,index) in hasVideos"
        >{{item.name}}</li>
      </ul>
    </div>
    <div class="three-work">
      <div @click="threeWork" class="title company-title">
        <div class="title-part">
          <span>逛</span>
          <span>工</span>
          <span>厂</span>
        </div>
        <span class="iconfont icon-xiangxia" v-if="!threeWorkList"></span>
        <span class="iconfont icon-xiangshang" v-else></span>
      </div>
      <!-- <transition> -->
      <ul class="three-list" v-show="threeWorkList">
        <li
          :class="[company.name==item.name?'checked':'',threeWorkList?'li-enter':'']"
          :key="index"
          @click="toggleCompany(item)"
          ref="companyli"
          v-for="(item,index) in companyList"
        >{{item.name}}</li>
      </ul>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import { getLiveVideoList } from '../../../assets/js/api/liveVideoApi'
export default {
  name: '',
  data () {
    return {
      msg: '',
      firstWorkList: false,
      secondWorkList: false,
      threeWorkList: false,
      liveCurrentIndex: 0,
      hasCurrentIndex: null,
      hasVideos: [],
      liveVideoList: [],
      liveVideoName: '',
      hasVideoName: ''
    }
  },
  created () {

  },
  mounted () {
    // 所有公司列表
    // this.getCompanyList();

  },
  computed: {
    ...mapState(['company', 'companyList'])
  },
  watch: {
    company (newValue, oldValue) {
      console.log('newValue3', newValue);
      if (newValue) {
        this.getVideoList()
        this.getHasVideo()

      }
    }
  },
  methods: {
    // 切换直播车间
    toggleLiveWorkSpace (item, index) {
      this.liveVideoName = item.roomName
      this.$store.commit('setLiveVideoRoomNo', item.roomAddress)
      this.$store.commit('setStaffId', item.id)
      this.liveCurrentIndex = index;
      this.hasCurrentIndex = null

    },
    //切换录播
    changeVideos (item, index) {

      let dom = document.getElementById('video')
      dom.src = item.videoAddress
      this.liveCurrentIndex = null
      this.hasVideoName = item.name

      this.hasCurrentIndex = index
      this.$store.state.liveVideoRoomNo = 1

      // dom.muted = false
    },
    // 切换公司
    toggleCompany (item) {
      console.log('woshitbgongsi', item);

      this.$store.commit('setCompany', item)
      this.getVideoList()

      this.$router.push('/' + item.alias)

    },
    animateMove (dom, enter, leave, status) {
      console.log('statusss1', this[status]);

      dom.forEach((item, index) => {

        if (item.className.includes(enter)) {
          item.classList.remove(enter);
          item.classList.add(leave);


        }
      })
      if (this[status]) {
        setTimeout(() => {
          this[status] = !this[status]
          console.log('statusss2', this[status]);

        }, 200)
      }
    },
    firstWork () {
      this.secondWorkList = false;
      this.animateMove(this.$refs.companyli, 'li-enter', 'li-leave', 'threeWorkList')

      if (this.firstWorkList) {
        // this.secondWorkList = false;
        this.threeWorkList = false;

        console.log('lili', this.$refs.lili[0].className.includes('li-leave'))

        this.animateMove(this.$refs.lili, 'li-enter', 'li-leave', 'firstWorkList')
        // this.$refs.lili.forEach((item, index) => {
        //   console.log('lililili55', item.className);

        //   if (item.className.includes('li-enter')) {
        //     item.classList.remove('li-enter');
        //     item.classList.add('li-leave');

        //     setTimeout(() => {
        //       this.firstWorkList = !this.firstWorkList

        //     }, 200)
        //   }
        // })
      } else {
        this.firstWorkList = !this.firstWorkList

      }

    },
    secondWork () {
      if (this.$refs.lili) {
        this.animateMove(this.$refs.lili, 'li-enter', 'li-leave', 'firstWorkList')

      }
      if (this.$refs.companyli) {
        this.animateMove(this.$refs.companyli, 'li-enter', 'li-leave', 'threeWorkList')

      }

      if (this.secondWorkList) {
        // this.firstWorkList = false;
        // this.threeWorkList = false;
        if (this.$refs.hasli) {
          this.animateMove(this.$refs.hasli, 'li-enter', 'li-leave', 'secondWorkList')

        } else {
          this.secondWorkList = !this.secondWorkList

        }

      } else {
        this.secondWorkList = !this.secondWorkList

      }
    },
    threeWork () {
      console.log('errorrr', this.$refs.hasli, this.$refs.lili);

      if (this.$refs.hasli) {
        this.animateMove(this.$refs.hasli, 'li-enter', 'li-leave', 'secondWorkList')

      }
      if (this.$refs.lili) {
        this.animateMove(this.$refs.lili, 'li-enter', 'li-leave', 'firstWorkList')

      }

      if (this.threeWorkList) {
        // this.firstWorkList = false;
        // this.secondWorkList = false;
        // this.animateMove(this.$refs.lili, 'li-enter', 'li-leave', 'firstWorkList')

        this.animateMove(this.$refs.companyli, 'li-enter', 'li-leave', 'threeWorkList')
        // this.threeWorkList = !this.threeWorkList


      } else {
        this.threeWorkList = !this.threeWorkList

      }
    },
    // ...mapActions(['getCompanyList']),
    // 获取直播房间列表
    getVideoList () {
      var params = {
        deptId: this.company.id,
        sort: 'sort,asc'
      }
      this.$request.get(this.$api.liveVideo, params).then(res => {
        console.log('too', res);
        this.liveVideoList = res.data.content;
        console.log('liveVideoRoomNo1450001', this.liveVideoList);
        if (res.data.content.length > 0) {
          this.$store.commit('setLiveVideoRoomNo', res.data.content[0].roomAddress)
          this.$store.commit('setStaffId', res.data.content[0].id)
          this.liveVideoName = this.liveVideoList[0].roomName
        } else {
          this.$store.commit('setLiveVideoRoomNo', '')
          this.liveVideoName = "暂未开放"
        }


      })

    },
    getHasVideo () {
      var params = {
        deptId: this.company.id,
        sort: 'sort,asc'

      }
      this.$request.get(this.$api.getVideos, params).then(res => {
        this.hasVideos = res.data.content;
        console.log('videos111', this.hasVideos);

        if (this.hasVideos.length > 0) {
          this.hasVideoName = this.hasVideos[0].name
        } else {
          this.hasVideoName = "暂未开放"
        }

      })
    }
  }
}
</script>

<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@keyframes moves {
  from {
    height: 0;
  }
  to {
    height: 144px;
  }
}

.firstWork-enter-active {
  animation: moves 0.35s linear;
}
.firstWork-leave-active {
  animation: moves 0.35s reverse;
}

@keyframes limove {
  0% {
    height: 0;
  }
  100% {
    height: 48px;
  }
}
@keyframes limoveleave {
  0% {
    height: 48px;
  }
  100% {
    height: 0;
  }
}
.li-enter {
  animation: limove 0.25s linear;
  height: 48px;
}
.li-leave {
  animation: limoveleave 0.25s linear;
  height: 0;
}

.work-shop {
  .first-work,
  .second-work,
  .three-work {
    .company-title {
      .iconfont {
        font-size: 18px;
        margin-left: 25px;
        transform: rotate(-90deg);
      }
      &:hover {
        background: url(../../../assets/images/icon/WechatIMG618.png) no-repeat;
      }
    }
    .title {
      width: 236px;
      height: 48px;
      line-height: 48px;
      background: url(../../../assets/images/icon/WechatIMG619.png) no-repeat;
      padding-left: 100px;
      font-size: 16px;
      color: #fff;
      display: flex;
      user-select: none;
      backdrop-filter: blur(20px);
      .title-part {
        width: 64px;
        display: flex;
        justify-content: space-between;
      }

      .iconfont {
        font-size: 18px;
        margin-left: 25px;
      }
    }

    > ul {
      li {
        width: 236px;
        // height: 48px;
        line-height: 48px;
        color: #fff;
        // transition: 0.35s;
        background: url(../../../assets/images/icon/WechatIMG617.png) no-repeat;
        padding-left: 100px;
        backdrop-filter: blur(20px);
        // transition: height 1s;

        &:hover {
          background: url(../../../assets/images/icon/WechatIMG616.png)
            no-repeat;
        }
      }

      .activeHas {
        background: url(../../../assets/images/icon/WechatIMG616.png) no-repeat;
      }
      .checked {
        background: url(../../../assets/images/icon/WechatIMG616.png) no-repeat;
      }
    }
  }
  .first-work {
    // position: relative;
    .first-work-list {
      &::-webkit-scrollbar {
        width: 0px;
        height: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #ccc;
      }
      &::-webkit-scrollbar-button {
        width: 10px;
        border-radius: 50%;
        background: black;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px #333;
        border-radius: 5px;
        // background: blue;
      }
    }
    .title {
      transition: 0.55s;

      &:hover {
        background: url(../../../assets/images/icon/WechatIMG618.png) no-repeat;
      }
    }
  }
  .second-work {
    margin-top: 10px;
    .title {
      transition: 0.55s;
      background: url(../../../assets/images/icon/WechatIMG612.png) no-repeat;
      &:hover {
        background: url(../../../assets/images/icon/WechatIMG611.png) no-repeat;
      }
    }
  }
  .three-work {
    margin-top: 10px;

    > .three-list {
      position: absolute;
      top: 0;
      width: 100%;
      max-height: 100%;
      right: 250px;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
        height: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #ccc;
      }
      &::-webkit-scrollbar-button {
        width: 10px;
        border-radius: 50%;
        background: black;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px #333;
        border-radius: 5px;
        // background: blue;
      }
      li {
        margin-bottom: 10px;
        padding-left: 0;
        text-align: center;
      }
    }
  }
}
</style>
