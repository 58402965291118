<template>
  <div @mouseenter="ifBlock=true" @mouseleave="ifBlock=false" class="staff-container">
    <ul class="staff-nav">
      <li>
        <img alt src="../../../assets/images/icon/WechatIMG591.png" />
      </li>
      <li>车间工作人员</li>
      <li
        @click="staffEdit"
      >{{data.length>6&&!moreStatus?'查看更多':data.length>6&&moreStatus?'关闭':'全部'}}</li>
    </ul>
    <div class="staff-box">
      <transition name="staffmove">
        <div class="staff-move" v-show="ifBlock">
          <ul :class="[moreStatus?'long-height':'short-height']" class="staff-list">
            <li :key="index" @click="staffInfo(item,index)" v-for="(item,index) in data">
              <div class="staff-head">
                <img :src="item.headAddress" alt />
              </div>
              <ul>
                <li>{{item.name}}</li>
                <li>{{item.jobs}}</li>
              </ul>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <transition>
      <div class="info" v-show="!rightStatus">
        <div class="part-1">
          <div class="image-box">
            <div class="image">
              <img :src="currentStaff.headAddress" alt />
            </div>
            <div class="phone">
              <div>
                <span>{{currentStaff.name}}</span>
                <span
                  v-if="currentStaff.phone!=undefined"
                >{{userLoginStatus?currentStaff.phone : hiddenPhone(currentStaff.phone)}}</span>
              </div>
              <div>{{currentStaff.jobs}}</div>
              <div @click="chatBtn">
                <img alt src="../../../assets/images/staff/团队成员-留言按钮.png" />
              </div>
            </div>
          </div>
          <div class="intro">
            <ul>
              <li :key="index" v-for="(items,index) in currentStaff.details">{{textArr[index]}}</li>
            </ul>
          </div>
          <span @click="close" class="close"></span>
        </div>
        <transition name="wechatMove">
          <div class="part-2" v-show="chatStatus">
            <div class="head">欢迎给我留言，工作中...... 空闲下来回复您。</div>
            <div class="content" ref="content">
              <ul class="comment" ref="comment">
                <li :key="index" v-for="(item,index) in chatRecords">
                  <div class="comment-list">
                    <ul>
                      <li class="name">{{item.name}}</li>
                      <li class="phone">{{item.phone}}</li>
                      <li class="time">{{item.createTime | formatData}}</li>
                    </ul>
                    <div class="infos">{{item.info}}</div>
                  </div>
                  <ul class="reply">
                    <li :key="index" v-for="(items,index) in item.replies">
                      <ul>
                        <li class="name">{{items.name}}</li>
                        <li class="phone">{{items.phone}}</li>
                        <li class="time">{{items.createTime | formatData}}</li>
                      </ul>
                      <div class="infos">{{items.info}}</div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="text">
                <textarea
                  @keyup.enter="confirm"
                  cols="30"
                  id
                  name
                  placeholder="请留言"
                  ref="textarea"
                  resize="none"
                  rows="2"
                  v-model="text"
                ></textarea>
              </div>
            </div>
            <span @click="chatBtn" class="chat-close"></span>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import utils from "../../../assets/js/utils";

export default {
  name: '',
  data () {
    return {
      msg: '',
      currentStaff: '',
      chatStatus: false,
      // moreStatus: false,
      // data: [{
      //   id: 0,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 1,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 2,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 3,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 4,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 5,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 3,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 4,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 5,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 1,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 2,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 3,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 4,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 5,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 3,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 4,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 5,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 1,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 2,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 3,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 4,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 5,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 3,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 4,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }, {
      //   id: 5,
      //   job: '经理',
      //   name: 'zhaoguang',
      // }],
      data: [],
      text: "",
      chatRecords: [],
      ifBlock: true,
      textArr: [

      ],
      timer: [],
      indexs: null
    }
  },
  created () {

    // this.getStaffList()

  },
  mounted () {
    this.$refs.textarea.focus();

    setTimeout(() => {
      this.ifBlock = false
    }, 300000)
  },

  computed: {
    ...mapState(['moreStatus', 'staffId', 'imgPublickUrl', 'rightStatus', 'userLoginStatus'])
  },
  filters: {
    formatData (time) {
      var data = new Date(time);
      return utils.formatDate(data, "yyyy-MM-dd");
    },


  },
  watch: {
    staffId (newValue, oldValue) {
      if (newValue) {

        console.log('id111222', newValue);
        let params = {
          roomId: newValue
        }

        this.getStaffList(params)
      }
    },
    chatStatus (newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.textarea.focus();

        })

      }
    }
    // userLoginStatus (newValue, oldValue) {
    //   if (newValue) {
    //     return this.currentStaff.phone
    //   } else {
    //     return this.hiddenPhone(this.currentStaff.phone)
    //   }
    // }
  },
  methods: {
    hiddenPhone (phone) {

      return phone.substr(0, 3) + "******" + phone.substr(9)


    },
    getStaffList (params) {
      // let params = {
      //   roomId: this.staffId
      // }
      console.log('id111', this.staffId);

      this.$request.get(this.$api.staffList, params).then(res => {
        console.log('staff666', res);
        this.data = res.data.content
        this.data.forEach(item => {
          item.details = JSON.parse(item.details)
        })
      })
    },
    step (indexs, str) {
      clearInterval(this.timer[indexs])
      this.textArr[indexs] = ""
      let index = 0;
      this.timer[indexs] = setInterval(() => {
        this.textArr[indexs] = str.slice(0, index++)
        this.$forceUpdate()
        if (index > str.length) {
          clearInterval(this.timer[indexs])
        }
      }, 100)

    },
    //查看员工详情
    staffInfo (item, index) {
      this.currentStaff = item
      console.log('itemitem', this.indexs, index);
      // this.$store.state.rightStatus = true
      // this.$store.state.rightStatus = false



      if (this.indexs == index) {
        this.$store.state.rightStatus = !this.$store.state.rightStatus

      } else {
        this.$store.state.rightStatus = false
        this.indexs = JSON.parse(JSON.stringify(index))
      }
      this.currentStaff.details = item.details
      if (item.details !== '' && item.details !== null && item.details.length > 0) {
        for (let i = 0; i < item.details.length; i++) {
          this.step(i, item.details[i].info)
        }
      }



      console.log('pppp零零落落', this.currentStaff);

      let datas = {
        empId: this.currentStaff.id
      }
      // this.getStaffList()
      this.getChatRecords(datas)

    },
    getChatRecords (datas) {
      this.$request.get(this.$api.record, datas).then(res => {
        console.log('pppp零零落落', res);
        this.chatRecords = res.data
      })
    },
    chatBtn () {
      this.chatStatus = !this.chatStatus;
      setTimeout(() => {
        let ref = this.$refs.content;
        let ref2 = this.$refs.comment
        console.log('content', ref2.scrollTop, ref2.scrollHeight)
        ref.scrollTop = ref2.scrollHeight
      }, 100)
    },
    confirm () {
      console.log('staf1撒打算', this.text.length);
      let data = {
        employees: {
          id: this.currentStaff.id,
        },
        info: this.text
      }
      this.$request.post(this.$api.record, data).then(res => {
        console.log('staf11', res);
        this.text = ''

        let datas = {
          empId: this.currentStaff.id
        }

        // this.getStaffList()
        this.getChatRecords(datas)


      })



    },
    close () {
      this.$store.state.rightStatus = !this.$store.state.rightStatus
      this.chatStatus = false

    },
    staffEdit () {
      if (this.data.length > 6) {
        this.$store.state.moreStatus = !this.$store.state.moreStatus
        this.ifBlock = true

      } else {
        return
      }
    }
  },

}
</script>

<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    // height: 0;
    opacity: 0;
  }
  to {
    // height: 369px;
    opacity: 1;
  }
}
@keyframes moves {
  from {
    // height: 0;
    opacity: 0;
    // backdrop-filter: blur(0px);
  }
  to {
    // height: 369px;
    opacity: 1;
    // backdrop-filter: blur(1px);
  }
}
@keyframes wechatmove {
  from {
    // height: 0;
    opacity: 0;
  }
  to {
    // height: 369px;
    opacity: 1;
  }
}
.staffmove-enter-active {
  animation: moves 1s linear;
}
.staffmove-leave-active {
  animation: moves 5s reverse;
}
.wechatMove-enter-active {
  animation: wechatmove 0.7s linear;
}
.wechatMove-leave-active {
  animation: wechatmove 0.7s reverse;
}
.staff-container {
  .staff-nav {
    display: flex;
    height: 20px;
    align-items: center;
    > li {
      height: 20px;
      display: flex;
      align-items: center;
      &:nth-child(1) {
        margin-right: 8px;
      }
      &:nth-child(2) {
        font-size: 16px;
        color: #ffffff;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          display: block;
          height: 1px;
          width: 8px;
          background: #fff;
          top: 58%;
          right: -12px;
        }
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 40px;
          height: 1px;
          right: -43px;
          top: 58%;
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
      &:nth-child(3) {
        width: 79px;
        height: 17px;
        display: block;
        background: url(../../../assets/images/staff/circle.png) no-repeat;

        text-align: center;
        line-height: 17px;

        color: #fff;
        font-size: 12px;
        margin-left: auto;
        margin-top: 5px;
        background-size: contain;
        backdrop-filter: blur(20px);
        overflow: hidden;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .long-height {
    height: 100%;
  }
  .short-height {
    height: 198px;
  }
  .staff-box {
    min-height: 198px;
    margin-top: 10px;
  }

  .staff-list {
    margin-top: 10px;
    // display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;

    > li {
      height: 56px;
      width: 114px;
      float: left;
      margin-bottom: 10px;
      background: url(../../../assets/images/border/WechatIMG590.png);
      display: flex;
      align-items: center;
      padding: 10px;
      box-sizing: border-box;
      backdrop-filter: blur(5px);
      &:nth-child(even) {
        margin-left: 10px;
      }
      .staff-head {
        height: 30px;
        width: 30px;
        border: 2px solid #fedf96;
        border-radius: 25px;
        margin-right: 5px;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }
      > ul {
        li {
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }
  .info {
    width: 318px;
    height: 369px;
    position: absolute;
    top: 20px;
    left: 270px;
    z-index: 999;
    // backdrop-filter: blur(5px);

    // overflow: hidden;
    .part-1 {
      padding: 30px 25px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      backdrop-filter: blur(5px);
      background: url(../../../assets/images/staff/团队成员-背景.png) no-repeat;

      .image-box {
        display: flex;
        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 96px;
          height: 95px;
          margin-right: 15px;
          background: url(../../../assets/images/staff/团队成员-头像框.png)
            no-repeat;
          img {
            width: 89px;
            height: 89px;
          }
        }
        .phone {
          > div {
            padding-left: 8px;
            height: 27px;
            width: 155px;
            line-height: 27px;
            color: white;
            font-size: 12px;

            &:nth-child(1) {
              color: #fff;
              > span {
                margin-right: 5px;
              }
              background: url(../../../assets/images/staff/团队成员-姓名框.png)
                no-repeat;
            }
            &:nth-child(2) {
              margin-top: 10px;
              background: url(../../../assets/images/staff/团队成员-职务框.png)
                no-repeat;
            }
            &:nth-child(3) {
              margin-top: 10px;
              display: flex;
              justify-content: end;
            }
          }
        }
      }
      .intro {
        color: #fff;
        margin-top: 30px;
        font-size: 14px;
        ul {
          li {
            font-family: cursive;
          }
        }
      }
      .close {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .part-2 {
      width: 312px;
      height: 354px;

      padding: 20px;
      color: #fff;
      background: url(../../../assets/images/staff/团队成员-留言框.png)
        no-repeat;
      backdrop-filter: blur(5px);

      position: absolute;
      top: 10px;
      left: 348px;

      .content {
        height: 270px;
        overflow: hidden;
        overflow: auto;
        margin-top: 15px;
        transition: 0.35s;
        &::-webkit-scrollbar {
          width: 5px;
          height: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #fff;
        }
        &::-webkit-scrollbar-button {
          width: 10px;
          border-radius: 50%;
          background: black;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px #333;
          border-radius: 5px;
          // background: blue;
        }
        &::-webkit-scrollbar-corner {
          background: springgreen;
        }
      }
      .comment {
        // max-height: 270px;
        // overflow: hidden;
        // overflow-y: auto;
        padding-right: 15px;
        > li {
          .comment-list {
            ul {
              display: flex;
              li {
              }

              .phone {
                margin-left: 15px;
              }
              .time {
                margin-left: auto;
              }
            }
            .infos {
              font-size: 12px;
            }
          }
        }
      }
      .reply {
        margin-left: 15px;
        > li {
          ul {
            display: flex;
            li {
            }

            .phone {
              margin-left: 15px;
            }
            .time {
              margin-left: auto;
            }
          }
          .infos {
            font-size: 12px;
          }
        }
      }
      .text {
        color: #fff;
        margin-top: 15px;
        > textarea {
          background: transparent;
          resize: none;
          // padding: 10px;
          box-sizing: border-box;
          overflow: hidden;
          border: none;
          color: #fff;
        }
        > textarea::-webkit-input-placeholder {
          color: #ccc;
        }
      }
      .chat-close {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 0;
        right: 0;
      }
    }
  }
}
</style>
