<template>
  <div
    @mouseenter="expressIfBlock=true"
    @mouseleave="expressIfBlock=false"
    class="express-container"
  >
    <ul class="express-nav">
      <li>
        <img alt src="../../../assets/images/icon/WechatIMG594.png" />
      </li>
      <li>近期发货</li>
      <li @click="moreExpress">{{!infoStatus?'查看全部':'关闭'}}</li>
    </ul>
    <div class="express-container">
      <transition name="express">
        <div class="express-box" v-show="expressIfBlock">
          <ul :class="{move:moveStatus}" class="express-list" ref="express">
            <li :key="index" @click="moreExpress" v-for="(item,index) in data">
              <div class="express-id">
                <p>{{item.id}}</p>
              </div>
              <div class="express-info">{{item.dateTime | formatData}}发货:{{item.info}}</div>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <transition>
      <!-- <div :style="position" class="moveBox" v-show="infoStatus">
        <div
          @mousedown="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="moveHead"
        ></div>
        <div class="content">
          <slot></slot>
        </div>
      </div>-->
      <div :style="position" class="info-container" ref="position" v-show="infoStatus">
        <div
          @mousedown="mousedown"
          @mouseleave="mousemove"
          @mousemove="mousemove"
          @mouseup="mouseup"
          class="head"
        >
          <span>近期发货</span>
        </div>
        <div class="info">
          <ul>
            <li :key="index" v-for="(items,index) in datas">
              <div class="time">{{items.dateTime | formatData}}发货:</div>
              <div class="message">{{items.info}}</div>
            </li>
          </ul>
        </div>
        <span @click="moreExpress" class="close"></span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import utils from "../../../assets/js/utils";
import VueDragResize from 'vue-drag-resize'
import moveData from '../../../mixins/move'


export default {
  name: '',
  mixins: [moveData],

  components: {
    VueDragResize
  },
  data () {
    return {
      msg: '',
      moveStatus: false,
      expressIfBlock: true,
      data: [
        {
          id: 0,
          date: '22年06月01日发货',
          msg: '0打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        },
        {
          id: 1,
          date: '22年06月01日发货',

          msg: '1打发发送发送到范德萨发生的发撒的方式打发阿斯顿发0打发发送发送到范德萨发生的发撒的方式打发阿斯顿发0打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        },
        {
          id: 2,
          date: '22年06月01日发货',

          msg: '2打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        },
        {
          id: 3,
          date: '22年06月01日发货',

          msg: '3打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        },
        {
          id: 4,
          date: '22年06月01日发货',
          msg: '4打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        }, {
          id: 0,
          date: '22年06月01日发货',
          msg: '0打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        },
        {
          id: 1,
          date: '22年06月01日发货',

          msg: '1打发发送发送到范德萨发生的发撒的方式打发阿斯顿发0打发发送发送到范德萨发生的发撒的方式打发阿斯顿发0打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        },
        {
          id: 2,
          date: '22年06月01日发货',

          msg: '2打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        },
        {
          id: 3,
          date: '22年06月01日发货',

          msg: '3打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        },
        {
          id: 4,
          date: '22年06月01日发货',
          msg: '4打发发送发送到范德萨发生的发撒的方式打发阿斯顿发',
        }
      ],
      datas: [],
      // x: 0,//left:x
      // y: 80,//top:y
      // leftOffset: 0,//鼠标距离移动窗口左侧偏移量
      // topOffset: 0,//鼠标距离移动窗口顶部偏移量
      // isMove: false,//是否移动标识
    }
  },
  created () {

  },
  mounted () {
    setInterval(() => {
      this.expressMove()

    }, 5000)
    setTimeout(() => {
      this.expressIfBlock = false;
    }, 120000)



  },
  computed: {
    ...mapState(['company', 'infoStatus']),
    //top与left加上px
    // position () {
    //   return `top:${this.y}px;left:${this.x}px;`;
    // },

  },
  watch: {
    company (newValue, oldValue) {
      let data = {
        id: newValue.id
      }
      this.getData(data)
    },
    infoStatus (newValue, oldValue) {
      if (newValue) {
        console.log('width', document.body.clientWidth, this.$refs.position.offsetWidth);
        this.x = (document.body.clientWidth - 528) / 2
        this.y = 80
      } else {
        return
      }
    }
  },
  filters: {
    formatData (time) {
      var data = new Date(time);
      return utils.formatDate(data, "yyyy-MM-dd");
    }
  },
  methods: {
    // mousedown (event) {
    //   //鼠标按下事件
    //   this.leftOffset = event.offsetX;
    //   this.topOffset = event.offsetY;
    //   this.isMove = true;

    // },
    // //鼠标移动
    // mousemove (event) {
    //   if (!this.isMove) {
    //     return;
    //   }
    //   this.x = event.clientX - this.leftOffset;
    //   this.y = event.clientY - this.topOffset;
    //   console.log('event');
    //   // if (this.x <= 0) {
    //   //   this.x = this.$refs.position.offsetLeft
    //   // }
    // },
    // //鼠标抬起
    // mouseup () {
    //   this.isMove = false;
    // },
    getData (data) {

      this.$request.get(this.$api.express, data).then((res) => {
        console.log('express', res);
        this.data = res.data
        console.log('express', this.data);
        this.datas = JSON.parse(JSON.stringify(this.data))
        console.log('express', this.datas);

      })
    },
    // 发货信息滚动
    expressMove () {
      this.moveStatus = true;
      let listDom = this.$refs.express;
      console.log('listDom', this.$refs);
      console.log('listDom', listDom);

      console.log('listDoms', listDom.style);
      listDom.style.top = -85 + 'px'
      this.data.push(this.data[0])

      setTimeout(() => {
        this.moveStatus = false;
        this.data.shift()
        listDom.style.top = 0
      }, 1000)

    },
    //查看更多发货信息
    moreExpress () {
      this.$store.state.infoStatus = !this.$store.state.infoStatus
    },
  }
}
</script>

<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 698px;
  }
}
@keyframes moves {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.express-enter-active {
  animation: moves 0.35s linear;
}
.express-leave-active {
  animation: moves 5s reverse;
}
.express-container {
  .express-nav {
    display: flex;
    height: 20px;
    align-items: center;
    font-weight: 400;

    > li {
      height: 20px;
      display: flex;
      align-items: center;
      &:nth-child(1) {
        margin-right: 8px;
      }
      &:nth-child(2) {
        font-size: 16px;
        color: #ffffff;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          display: block;
          height: 1px;
          width: 8px;
          background: #fff;
          top: 58%;
          right: -12px;
        }
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 70px;
          height: 1px;
          right: -78px;
          top: 58%;
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
      &:nth-child(3) {
        width: 79px;
        height: 17px;
        display: block;

        background: url(../../../assets/images/staff/circle.png) no-repeat;

        text-align: center;
        line-height: 17px;
        color: #fff;
        font-size: 12px;
        margin-left: auto;
        margin-top: 5px;
        background-size: contain;
        backdrop-filter: blur(20px);
        overflow: hidden;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  .express-container {
    height: calc(100vh - 555px);
  }
  .express-box {
    height: calc(100vh - 555px);
    overflow: hidden;
    position: relative;
  }
  .info-container {
    // transition: 0.45s;
    width: 528px;
    height: 698px;
    // padding-top: 20px;
    position: fixed;
    // left: 500px;
    // right: 0;
    // top: 50px;
    // bottom: 0;
    // margin: 30px auto;
    background: #fff;
    padding: 10px 0;
    overflow: hidden;
    background: url(../../../assets/images/近期发货.png) no-repeat;
    z-index: 999;
    backdrop-filter: blur(3px);

    // padding-top: 20px;
    // overflow-y: auto;
    > .close {
      width: 30px;
      height: 30px;
      background: transparent;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
    }
    > .head {
      height: 50px;
      width: 99.8%;
      padding: 0 30px;
      border-bottom: 1px solid #ccc;
      // cursor: move;
      > span {
        // margin-top: 15px;
        display: inline-block;
        height: 50px;
        line-height: 50px;
        color: #fff;
        font-size: 14px;
        position: relative;
        &::after {
          content: '';
          display: block;
          height: 1px;
          width: 57px;
          background-color: #fff;
          position: absolute;
          bottom: 0;
        }
      }
    }
    > .info {
      height: 580px;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 30px;
      margin-top: 30px;
      position: relative;
      // ::-webkit-scrollbar {
      //   width: 0 !important;
      // }
      // ::-webkit-scrollbar {
      //   width: 0 !important;
      //   height: 0;
      // }
      &::-webkit-scrollbar {
        width: 0px;
        height: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #ccc;
      }
      &::-webkit-scrollbar-button {
        width: 10px;
        border-radius: 50%;
        background: black;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px #333;
        border-radius: 5px;
        // background: blue;
      }
      &::-webkit-scrollbar-corner {
        background: springgreen;
      }

      ul {
        > li {
          min-height: 60px;
          padding-left: 20px;
          position: relative;
          color: #fff;
          > .time {
            color: #72f5fc;
            margin-bottom: 10px;
          }
          .message {
            padding-bottom: 24px;
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 25px;
            top: 8px;
            left: 0;
            background: #72f5fc;
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            top: 13px;
            left: 2px;
            background: #72f5fc;
          }
        }
      }
    }
  }
  .move {
    transition: 0.45s;
  }
  .express-list {
    // margin-top: 8px;
    position: absolute;
    top: 0;
    li {
      margin-top: 10px;
      width: 240px;
      height: 75px;
      background: url(../../../assets/images/icon/WechatIMG595.png) no-repeat;
      font-size: 12px;
      color: #fff;
      display: flex;
      transition: 0.45s;
      backdrop-filter: blur(3px);

      &:hover {
        background: url(../../../assets/images/icon/express.png) no-repeat;
      }

      .express-id {
        width: 20px;
        height: 100%;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;

        p {
          transform: rotate(90deg);
        }
        // &::before {
        //   content: '';
        //   height: 100%;
        //   display: inline-block;
        //   vertical-align: middle;
        // }
        // &::after {
        //   content: '';
        //   display: block;
        //   height: 100%;
        //   position: absolute;
        //   width: 2px;
        //   background: rgba(65, 99, 99, 0.35);
        //   right: 0;
        //   top: 0;
        // }
      }
      .express-info {
        padding: 10px 5px;
        max-height: 65px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }
}
.moveBox {
  width: 500px;
  height: 300px;
  position: fixed;
  top: 80px;
  z-index: 9999;
  box-shadow: 0 0 5px 3px #95a5a6;

  .moveHead {
    height: 30px;
    background-color: #bdc3c7;
    cursor: move;
  }
}
</style>
