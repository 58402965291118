<template>
  <div class="product-container">
    <div class="nav-con">
      <!-- 产品 nav -->

      <ul class="nav-list">
        <li :class="open?'open-active':''" @click="all" class="edit">{{open?'折叠':'展开'}}</li>
        <li :class="ifAll?'nav-active':''" @click="allList">所有分类</li>
        <li
          :class="[navIndex == index?'nav-active':'']"
          :key="index"
          @click="change(index,item)"
          v-for="(item,index) in nav"
        >{{item.name}}</li>
      </ul>
      <!-- nav 操作按钮 -->
      <ul class="nav-right">
        <li @click.stop="openSearch" class="search-container">
          <transition>
            <div @click.stop class="search" v-if="searchStatus">
              <input @keyup.enter="search" placeholder="请输入搜索内容" type="text" v-model="searchValue" />
              <img @click="search" alt src="../../../assets/images/icon/WechatIMG335.png" />
            </div>
          </transition>
          <span>精准查找</span>
        </li>
        <li
          :class="[submitStatus?'submit-active':'']"
          @click="confirm"
          class="confirm"
          v-if="userLoginStatus"
        >
          <span>确认订单</span>
        </li>
      </ul>
    </div>
    <!--产品列表 -->

    <div class="list">
      <ul class="product-list" v-if="userLoginStatus">
        <li :key="index" v-for="(item,index) in list">
          <div @click="bigImg(index)" class="image">
            <img :src="item.bigFilePath" alt />

            <!-- <img alt src="../../../assets/images/rBH8OV9geK2AR7ssAAe_InyBW_c594.jpg" /> -->
          </div>
          <div class="name">
            <span>{{item.productName}}</span>
          </div>
          <div>
            <span>
              ￥{{
              (orderList[index].price = endPrice(item.productPrices, index))
              }}元/{{ item.productUnits }}
            </span>
          </div>
          <input
            @blur="
                inputBtn(item.productPrices, index, item.moq, $event, item)
              "
            oninput="if(value.length>5)value=value.slice(0,6)"
            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
            placeholder="请输入数量"
            type="text"
            v-model.number.trim="orderList[index].orderNumber"
          />
          <div @click="openDesc(index)" class="desc">{{item.productDesc}}</div>
          <div
            :class="currentIndex == index?'desc-open':'desc-close'"
            @mouseleave="leaveDesc(index)"
            class="all-desc"
          >{{item.productDesc}}</div>
        </li>
      </ul>
      <ul class="product-list no-login" v-else>
        <li :key="index" v-for="(item,index) in list">
          <div @click="bigImg(index)" class="image">
            <img :src="item.bigFilePath" alt />

            <!-- <img alt src="../../../assets/images/rBH8OV9geK2AR7ssAAe_InyBW_c594.jpg" /> -->
          </div>
          <div class="name">
            <span>{{item.productName}}</span>
          </div>
        </li>
      </ul>
    </div>
    <!-- <maskBox></maskBox> -->
  </div>
</template>

<script>
// @import
import { mapState, mapActions } from 'vuex'
import maskBox from '../mask'
import vm from '../../../assets/js/event'

export default {
  components: { maskBox },
  props: {},
  data () {
    return {
      open: true,
      searchStatus: false,
      list: [],
      desc: true,
      currentIndex: null,
      nav: [],
      navIndex: null,
      ifAll: true,
      allListStatus: false,
      data: {},
      orderList: [],//订单列表
      submitStatus: false,
      searchValue: '',
      currentNavId: ''
    };
  },

  created () {
    this.getProductNav()
  },
  mounted () {
    console.log('idd', this.company);
    setTimeout(() => {
      this.open = false;
      this.$store.state.rightBoxStatus = true

    }, 5000)
  },
  computed: {
    ...mapState(['company', 'imgPublickUrl', 'userLoginStatus'])
  },
  watch: {
    company (newValue, oldValue) {
      console.log('idd', newValue);
      this.data = {
        deptId: newValue.id
      }
      this.getProductList(this.data)
      this.getProductNav(this.data)

    }
  },
  methods: {
    all () {
      // this.allListStatus = true;
      // this.ifAll = false;
      // this.navIndex = null

      this.open = !this.open
      if (this.open) {
        this.$parent.all()
        this.$store.state.rightBoxStatus = false
      } else {
        this.$parent.part()
        this.$store.state.rightBoxStatus = true

      }
    },
    search () {
      console.log('searchProductserarc', this.company);

      let params = {
        deptId: this.company.id,
        productName: this.searchValue,
        typeId: this.currentNavId,
        state: 0,
        sort: 'sort,desc',
      }

      this.$request.get(this.$api.searchProduct, params).then(res => {
        console.log('searchProduct', res);
        this.list = res.data.content.reverse()
        let arr = []

        this.list.forEach((item, index) => {
          arr.push(item.bigFilePath)

          // 生成订单列表
          this.orderList[index] = {
            productId: item.productId,
            price: 0,
            orderNumber: null,
            product: item,
          }

        })
        console.log('iddres', arr);
        this.$store.state.imgList = arr;
      })
    },
    confirm () {

      const order = this.orderList.filter((item) => {
        return item.orderNumber
      })
      if (order.length > 0) {
        this.$store.state.orders = order

        vm.$emit("customEvent", order)


        this.$store.state.confirmOrderStatus = true
        this.$store.state.checkOrder = true;
        this.$store.state.buttonShow = true;
        this.$store.state.disabled = false;


      } else {
        this.$message({
          type: 'warning', message: '请输入数量'
        })
      }



    },
    openSearch () {
      this.searchStatus = !this.searchStatus
    },
    //获取产品列表
    getProductList (data) {

      this.$request.get(this.$api.productList, data).then(res => {
        console.log('jjjijijiijijijij', res);
        this.list = res.data.content.reverse();
        let arr = []

        this.list.forEach((item, index) => {
          arr.push(item.bigFilePath)

          // 生成订单列表
          this.orderList[index] = {
            productId: item.productId,
            price: 0,
            orderNumber: null,
            product: item,
          }

        })
        // console.log('iddres', arr);
        this.$store.state.imgList = arr;


      })
    },
    endPrice (productPrices, index) {
      if (productPrices.length > 1) {
        for (var i = 0; i < productPrices.length; i++) {
          if (
            this.orderList[index].orderNumber >=
            productPrices[i].productNumber &&
            this.orderList[index].orderNumber <
            productPrices[i + 1].productNumber
          ) {
            return productPrices[i].price
          } else if (
            this.orderList[index].orderNumber >=
            productPrices[productPrices.length - 1].productNumber
          ) {
            return productPrices[productPrices.length - 1].price
          }
        }
      }
      return productPrices[0].price
    },
    inputBtn (item, index, moq, event, items) {
      console.log('items', items)

      // debugger
      var inputNum = item[0].productNumber //请订值
      var moq = moq //购买数量的基准值
      var num = this.orderList[index].orderNumber //用户输入的值
      console.log('pppp', num)

      // 起订不能低于指定值
      if (moq != 0 && num !== '' && num !== null) {
        if (num < moq) {
          this.$message({
            message: `起订数量为${moq}${items.productUnits}`,
            type: 'warning',
          })
          this.orderList[index].orderNumber = moq
        }
      }

      if (item.length > 1 && num >= inputNum) {
        // debugger;
        for (var i = 0; i < item.length; i++) {
          // if (i + 1 == item.length) {
          //   var b = item[i].price;
          //   console.log("我就是价格", b);
          // }

          if (i < item.length) {
            //判断最后一个值，不进行中间值的对比，直接获取大于最后一个数值后为多少单价。（包含临界点）
            if (i + 1 == item.length) {
              if (num >= item[i].productNumber) {
                console.log('大于长度le', item[i].productNumber)
                console.log('大于长度le', item[i].price)
                console.log('价格为', item[i].price)
                this.orderList[index].price = item[i].price
              }

              // console.log("我就是价格", a);
            }
            //中间值的对比，看在哪一个区间 获取相对于的单价。（包含临界点）
            if (i + 1 < item.length && i + 1 !== item.length) {
              // console.log("第一个值", item[i].productNumber);
              // console.log("第二个值", item[i + 1].productNumber);

              if (
                num >= item[i].productNumber &&
                num < item[i + 1].productNumber
              ) {
                console.log('比较的值', num)
                console.log('第一个值', item[i].productNumber)
                console.log('第二个值', item[i + 1].productNumber)
                console.log('价格为', item[i].price)
                break
              }
            }

            // if (i + 1 < item.length && i + 1 !== item.length) {
            //   if (
            //     Number(item[i].productNumber) < Number(num) &&
            //     Number(num) < Number(item[i + 1].productNumber)
            //   ) {
            //     console.log("对比的值", num);

            //     console.log("第一个值", item[i].productNumber);
            //     console.log("第二个值", item[i + 1].productNumber);
            //   }
            // }

            // i--;
          }
          // debugger
        }

      }
      for (var i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].orderNumber > 0) {
          this.submitStatus = true
          return
        } else {
          this.submitStatus = false
        }
      }

      console.log('submitStatus', this.submitStatus, this.orderList);


      // if (status) {
      //   // dom.style.right = 0
      //   if (dom.classList.contains('disappear')) {
      //     dom.classList.remove('disappear')
      //   }
      //   dom.classList.add('appear')
      // } else {
      //   // dom.style.right = -62+"px"

      //   dom.classList.remove('appear')
      //   dom.classList.add('disappear')
      // }

      // dom.style.display = 'none'
    },
    //获取产品分类
    getProductNav (data) {
      this.$request.get(this.$api.productNav, data).then(res => {
        console.log('jjjijijiijijijij', res);
        this.nav = res.data.content
        // this.currentNavId = this.nav[0].id
      })
    },
    change (index, item) {
      this.navIndex = index
      this.currentNavId = item.id
      this.ifAll = false;
      this.allListStatus = false;
      console.log('item', item.id);

      let data = {
        typeId: item.id
      }
      this.getProductList(data)
    },
    allList () {
      this.allListStatus = false;
      this.ifAll = true
      this.navIndex = null
      this.getProductList(this.data)

    },
    openDesc (index) {
      // this.desc = !this.desc;
      this.currentIndex = index;
    },
    leaveDesc (index) {
      this.currentIndex = null
    },
    bigImg (index) {
      this.$store.state.maskStatus = true;
      this.$store.state.imgIndex = index;


    },

  },
}
</script>
<style lang='less' scoped>
@import '../../../style/animate.less';
@keyframes move {
  from {
    left: 0;
    width: 0;
  }
  to {
    width: 240px;
    left: -240px;
  }
}
.product-container {
  height: 100%;
  .nav-con {
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .nav-list {
    // position: absolute;
    // top: 0;
    height: 22px;
    display: flex;
    .edit {
      background: url(../../../assets/images/icon/WechatIMG674.png);
    }
    li {
      margin-right: 10px;
      width: 85px;
      height: 22px;
      text-align: center;
      color: #fff;
      backdrop-filter: blur(3px);

      &:nth-child(1) {
        // background: url(../../../assets/images/productList/分类1.png);
      }
      background: url(../../../assets/images/productList/分类2.png);
    }
    .nav-active {
      background: url(../../../assets/images/productList/分类1.png);
    }
    .open-active {
      background: url(../../../assets/images/icon/WechatIMG675.png);
    }
    margin-bottom: 10px;
  }
  .nav-right {
    display: flex;
    margin-bottom: 10px;
    .confirm {
      // border-right: 1px solid rgba(5, 248, 255, 0.5);
      transition: 0.35s;
      padding-left: 0;
    }
    li {
      backdrop-filter: blur(5px);
    }
    .submit-active {
      background: url(../../../assets/images/icon/WechatIMG331.png) no-repeat;
      padding-left: 25px;
    }
    .search-container {
      position: relative;
      .search {
        width: 240px;
        height: 48px;
        position: absolute;
        overflow: hidden;
        left: -240px;
        // background: #fff;
        // border: 1px solid #ccc;
        backdrop-filter: blur(5px);
        background: url(../../../assets/images/productList/WechatIMG338.png);

        > input {
          width: 100%;
          height: 50px;
          background: transparent;
          padding-left: 10px;
        }

        img {
          position: absolute;
          right: 10px;
          top: 15px;
        }
      }
    }
    > li {
      width: 118px;
      height: 48px;
      background-size: contain;
      line-height: 48px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      background: url(../../../assets/images/icon/WechatIMG333.png) no-repeat;
    }
  }

  .list {
    height: calc(100% - 75px);
    overflow-y: auto;
    padding: 16px;
    background: url(../../../assets/images/productList/列表背景.png) no-repeat;
    background-size: cover;
    backdrop-filter: blur(3px);
    &::-webkit-scrollbar {
      width: 0px;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: transparent;
    }
    &::-webkit-scrollbar-button {
      // width: 10px;
      border-radius: 50%;
      background: black;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px #333;
      border-radius: 5px;
      // background: blue;
    }
    &::-webkit-scrollbar-corner {
      background: springgreen;
    }
    .no-login {
      li {
        width: 70px;
        height: 70px;
        padding: 0;
        background: transparent;

        // &:nth-of-type(18n) {
        //   margin-right: 0;
        // }
        // &:nth-of-type(10n) {
        //   margin-right: 10px;
        // }
        > .image {
          width: 67px;
          height: 45px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 12px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    > ul {
      display: flex;
      flex-wrap: wrap;
      // li:nth-of-type(10n) {
      //   margin-right: 0;
      // }
      > li {
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 10px 0;
        width: 136px;
        height: 196px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        background: url(../../../assets/images/productList/产品框2.png);
        color: #fff;

        > .name {
          font-size: 12px;
          text-align: center;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .desc {
          height: 21px;
          font-size: 12px;
          color: #ccc;
          width: 75px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .all-desc {
          height: 100px;
          width: 100%;
          padding: 10px;
          position: absolute;
          background: #ccc;
          transition: 0.35s;
          // bottom: -100px;
        }
        .desc-close {
          bottom: -100px;
        }
        .desc-open {
          bottom: 0px;
        }
        > .image {
          width: 66px;
          height: 45px;
          overflow: hidden;
          > img {
            width: 100%;
          }
        }
        > input {
          width: 94px;
          height: 23px;
          text-align: center;
          font-size: 12px;
          border: 1px solid #fff;
          background-color: transparent;
          // outline: none;
          color: #fff;
          &:-moz-placeholder {
            color: #fff;
          }
        }
        > input::-webkit-input-placeholder {
          color: #fff;
        }
      }
    }
  }
}
// @media screen and(max-width: 1919px) {
//   .product-list {
//     li {
//       margin-right: 10px !important;
//     }
//   }
// }
</style>