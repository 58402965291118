import Vue from 'vue'
import Vuex from 'vuex'
// import { getCompanyList } from '../assets/js/api/companyApi'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    nums: false,
    ids: '',
    navTitle: '',
    swiperShow: true,
    tokenStatus: sessionStorage.getItem('tokenStatus')
      ? JSON.parse(sessionStorage.getItem('tokenStatus'))
      : false, //登陆状态
    // tokenStatus:
    //   sessionStorage.getItem('tokenStatus') ||
    //   localStorage.getItem('tokenStatus')
    //     ? JSON.parse(sessionStorage.getItem('tokenStatus')) ||
    //       JSON.parse(localStorage.getItem('tokenStatus'))
    //     : false, //登陆状态
    userId: sessionStorage.getItem('user-id')
      ? JSON.parse(sessionStorage.getItem('user-id'))
      : '',
    loginShow: false,
    registerShow: false, //注册页面开关
    findNumber: false,
    againOrder: false,
    checkOrder: true,
    billIndex: sessionStorage.getItem('billIndex')
      ? JSON.parse(sessionStorage.getItem('billIndex'))
      : 0,
    mailIndex: sessionStorage.getItem('mailIndex')
      ? JSON.parse(sessionStorage.getItem('mailIndex'))
      : 0,
    billOrAddress: sessionStorage.getItem('billOrAddress')
      ? JSON.parse(sessionStorage.getItem('billOrAddress'))
      : '',
    indexs: sessionStorage.getItem('indexs')
      ? JSON.parse(sessionStorage.getItem('indexs'))
      : 0,
    // back: false,
    addressIndex: sessionStorage.getItem('addressIndex')
      ? JSON.parse(sessionStorage.getItem('addressIndex'))
      : 0,
    menuLeft: false,
    menuRight: false,
    redioActive: sessionStorage.getItem('redioActive')
      ? JSON.parse(sessionStorage.getItem('redioActive'))
      : 0,
    commonStatus: true,

    // new xiubi vuex
    userInfo: sessionStorage.getItem('userInfo')
      ? JSON.parse(sessionStorage.getItem('userInfo'))
      : '', //登陆后用户信息
    userInfo:
      sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo')
        ? JSON.parse(sessionStorage.getItem('userInfo')) ||
          JSON.parse(localStorage.getItem('userInfo'))
        : '', //登陆后用户信息
    // userLoginStatus: sessionStorage.getItem('userLoginStatus')
    //   ? JSON.parse(sessionStorage.getItem('userLoginStatus'))
    //   : false, //登陆状态
    userLoginStatus:
      sessionStorage.getItem('userLoginStatus') ||
      localStorage.getItem('userLoginStatus')
        ? JSON.parse(sessionStorage.getItem('userLoginStatus')) ||
          JSON.parse(localStorage.getItem('userLoginStatus'))
        : false, //登陆状态
    navStatus: true,
    defaultCompanyId: null,
    routeList: null,
    imgPublickUrl: 'https://image.xiubi.com.cn/',
    company: null, //当前公司信息
    companyList: [], //所有公司list
    liveVideoRoomNo: null, //直播房间号
    staffId: null, //当前车间的员工
    moreStatus: false, //点击更多员工后 隐藏公告和快递信息
    moreAnnouncmentStatus: false, //点击公告更多后 隐藏公告和快递信息
    infoStatus: false, //快递信息页面控制
    maskStatus: false, //图片蒙层
    imgList: [], //所有准备放大图片列表
    imgIndex: null, //放大图片的当前下标
    rightStatus: true, //员工详情出现 控制 工作人员 公告 发货 模块 状态
    contactStatus: false, //联系我们 状态
    loginStatus: false, //登录界面控制
    regStatus: false, //注册页面控制
    selectStatus: false, //下拉菜单页面控制
    orderListStatus: false, //订单列表页面控制
    passwordStatus: false, //修改密码页面控制
    billAddressStatus: false, //发票地址页面控制
    rightBoxStatus: false, //公司简介 车间等右部结构控制
    companyInfoStatus: false, //公司简介 弹框组件控制
    confirmOrderStatus: false, //确认订单页面控制
    findNumberStatus: false, //账号找回页面控制
    orders: [], //确认的订单列表
    hasChangeBill: false, //是否已经修改了发票信息
    hasChangeAddress: false, //是否已经修改了地址信息
    buttonShow: true, //订单页面 操作按钮控制
    disabled: false, //订单页面 input 权限控制
    address: [], //所有的地址信息
    bills: [], //所有的发票信息
  },
  mutations: {
    setMenuLeft(state, data) {
      state.menuLeft = data
    },
    setMenuRight(state, data) {
      state.menuRight = data
    },
    setRouteList(state, data) {
      state.routeList = data
    },
    setCompany(state, data) {
      state.company = data
    },
    setCompanyList(state, data) {
      state.companyList = data
    },
    setStaffId(state, data) {
      state.staffId = data
    },
    setLiveVideoRoomNo(state, data) {
      state.liveVideoRoomNo = data
    },
  },
  actions: {
    // 所有公司列表
  },
  modules: {},
})
