import Vue from 'vue'
import VueRouter from 'vue-router'
const login = () => import('../mobile/mobileLogins')
const register = () => import('../mobile/mobileRegisters')
const mobileIndex = () => import('../mobile/mobileIndex')
const historyPage = () => import('../mobile/commonAssembly/historyPage')
const myOrder = () => import('../mobile/commonAssembly/myOrder')
const express = () => import('../mobile/commonAssembly/express')
const findNumber = () => import('../mobile/commonAssembly/findNumber')
const changePage = () => import('../mobile/commonAssembly/changePage')
const errPage = () => import('../mobile/err.vue')
const owner = () => import('../mobile/commonAssembly/owner')
const infoPage = () => import('../mobile/commonAssembly/infoPage')
const change = () => import('../mobile/commonAssembly/infoPage/change')
const add = () => import('../mobile/commonAssembly/infoPage/add')
const managementPage = () => import('../mobile/commonAssembly/managementPage')
const changePassword = () => import('../mobile/commonAssembly/changePassword')
const serve = () => import('../mobile/commonAssembly/serve')
const mobileRoutes = [
  {
    path: '/',
    component: mobileIndex,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/serve',
    name: 'serve',
    component: serve,
  },
  {
    path: '/owner',
    name: 'owner',
    component: owner,
  },
  {
    path: '/infoPage',
    name: 'infoPage',
    component: infoPage,
    meta: {
      // keepAlive: true,
    },
  },
  {
    path: '/managementPage',
    name: 'managementPage',
    component: managementPage,
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword,
  },
  {
    path: '/change',
    name: 'change',
    component: change,
  },
  {
    path: '/add',
    name: 'add',
    component: add,
  },
  {
    path: '/findNumber',
    name: 'findNumber',
    component: findNumber,
  },
  {
    path: '/mobileIndex',
    name: 'mobileIndex',
    component: mobileIndex,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: register,
  },
  {
    path: '/historyPage',
    name: 'historyPage',
    component: historyPage,
  },
  {
    path: '/express',
    name: 'express',
    component: express,
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    component: myOrder,
  },
  {
    path: '/changePage',
    name: 'changePage',
    component: changePage,
  },
  {
    path: '/err',
    name: 'err',
    component: errPage,
  },
]

export default mobileRoutes
