<template>
  <div class="center-container">
    <!-- 四周的边宽样式结构 -->
    <div class="borders">
      <div class="content">
        <div class="content-left">
          <!-- 员工头像 staff -->
          <div class="staff">
            <staff></staff>
          </div>

          <!-- 公告信息 -->
          <div class="announcement">
            <announcement></announcement>
          </div>

          <!-- 发货信息 -->
          <div class="express">
            <express></express>
          </div>
        </div>
        <div class="content-right">
          <transition name="right">
            <div class="right-box" v-show="rightBoxStatus">
              <!-- 公司简介 -->
              <div class="company">
                <company></company>
              </div>

              <!-- 车间 -->
              <div class="work-shop">
                <workShop></workShop>
              </div>
            </div>
          </transition>
          <div @mousewheel="fixed" class="bottom-box" ref="bottomBox">
            <productList></productList>
          </div>
        </div>
        <div class="company-all">
          <companyAll></companyAll>
        </div>
        <div class="contact-box">
          <contact></contact>
        </div>
        <div class="login">
          <login></login>
        </div>
        <div class="register">
          <register></register>
        </div>
        <div class="select">
          <selectList></selectList>
        </div>
        <div class="changePassword">
          <changePassword></changePassword>
        </div>
        <div class="bill-address">
          <billAddress></billAddress>
        </div>
        <div class="orderList">
          <orderList></orderList>
        </div>
        <div class="confirm-order">
          <confirmOrder></confirmOrder>
        </div>
        <div class="find-number">
          <findNumber></findNumber>
        </div>
      </div>

      <div class="shu-border-left-top">
        <div class="border-one"></div>
        <div class="border-two"></div>
        <div class="border-three"></div>
      </div>
      <div class="shu-border-right-top">
        <div class="border-one"></div>
        <div class="border-two"></div>
        <div class="border-three"></div>
      </div>
      <div class="shu-border-left-bottom">
        <div class="border-one"></div>
        <div class="border-two"></div>
        <div class="border-three"></div>
      </div>
      <div class="shu-border-right-bottom">
        <div class="border-one"></div>
        <div class="border-two"></div>
        <div class="border-three"></div>
      </div>
    </div>
  </div>
</template>

<script>
import staff from '../../commonComponents/staffModule'
import announcement from '../../commonComponents/announcementModule'
import express from '../../commonComponents/expressModule'
import company from '../../commonComponents/companyIntro'
import workShop from '../../commonComponents/workShop'
import productList from '../../commonComponents/productList'
import contact from '../../commonComponents/contactUs'
import companyAll from '../../commonComponents/companyAllintro'
import login from '../../commonComponents/login'
import register from '../../commonComponents/register'
import selectList from '../../commonComponents/selectList'
import orderList from '../../commonComponents/orderList'
import changePassword from '../../commonComponents/changPassword'
import billAddress from '../../commonComponents/billOrAddress';
import confirmOrder from '../../commonComponents/confirmOrder'
import findNumber from '../../commonComponents/findNumber'
import { mapState } from 'vuex'


export default {
  name: '',
  data () {
    return {
      msg: ''
    }
  },
  components: {
    staff,
    announcement,
    express,
    company,
    workShop,
    productList,
    contact,
    login,
    register,
    selectList,
    orderList,
    changePassword,
    billAddress,
    confirmOrder,
    findNumber,
    companyAll
  },
  created () {
  },
  mounted () {
    setTimeout(() => {
      this.part()
    }, 5000)
    // this.fixed()
    // console.log('bottomBox', 1, this.$refs.bottomBox);

    // this.$refs.bottomBox.addEventListener('scroll', this.fixed, true)
  },
  computed: {
    ...mapState(['moreStatus', 'moreAnnouncmentStatus', 'rightStatus', 'contactStatus', 'loginStatus', 'rightBoxStatus'])
  },
  methods: {
    fixed () {
      let dom = document.querySelector('.bottom-box')
      // console.log('bottomBox', 1, this.$refs.bottomBox.getBoundingClientRect().top);
      let top = this.$refs.bottomBox.getBoundingClientRect().top
      if (top <= 110) {
        this.$refs.bottomBox.style.position = 'fixed'

      } else {
        this.$refs.bottomBox.style.position = 'absolute'

      }

    },
    all () {
      console.log('all');

      this.$refs.bottomBox.style.position = 'fixed'
      this.$refs.bottomBox.style.top = 0

    },
    part () {
      this.$refs.bottomBox.style.position = 'absolute'
      this.$refs.bottomBox.style.top = "80%"
    }
  }
}
</script>

<style lang='less' scoped>
@import '../../../style/common.less';
@import '../../../style/animate.less';
@keyframes move {
  from {
    height: 0;
  }
  to {
    height: 500px;
  }
}
@keyframes moves {
  from {
    height: 0;
  }
  to {
    height: 567px;
  }
}
.right-enter-active {
  animation: moves 0.25s linear;
}
.right-leave-active {
  animation: moves 0.25s reverse;
}
.center-container {
  position: absolute;
  height: calc(100% - 100px);
  // border: 2px solid red;
  left: 40px;
  top: 48px;
  border-left: 2px solid rgba(255, 255, 255, 0.25);
  border-right: 2px solid rgba(255, 255, 255, 0.25);

  .borders {
    height: 100%;
    border: none;
    .content {
      padding: 28px 20px;
      height: calc(100vh - 100px);
      box-sizing: border-box;
      display: flex;
      .content-left {
        width: 240px;
        overflow: hidden;
        .staff {
          max-height: 100%;
          overflow: hidden;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0px;
            height: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #fff;
          }
          &::-webkit-scrollbar-button {
            width: 10px;
            border-radius: 50%;
            background: black;
          }
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px #333;
            border-radius: 5px;
            // background: blue;
          }
          &::-webkit-scrollbar-corner {
            background: springgreen;
          }
        }
        .announcement {
          margin-top: 18px;
        }
        .express {
          margin-top: 28px;
        }
      }
      .content-right {
        transform: translate(0, 0);
        width: calc(100% - 240px);
        height: 100%;
        // border: 1px solid blue;
        position: relative;
        overflow: hidden;
        overflow-y: scroll;
        padding-left: 55px;
        &::-webkit-scrollbar {
          width: 0px;
          height: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: transparent;
        }
        &::-webkit-scrollbar-button {
          width: 10px;
          border-radius: 50%;
          background: black;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px #333;
          border-radius: 5px;
          // background: blue;
        }
        &::-webkit-scrollbar-corner {
          background: springgreen;
        }
        .right-box {
          width: 240px;
          // border: 1px solid yellow;
          height: calc(100% - 230px);
          float: right;
          position: relative;

          .work-shop {
            margin-top: 16px;
            max-height: calc(100% - 218px);
            overflow: hidden;
            overflow-y: auto;
            padding-bottom: 10px;
            &::-webkit-scrollbar {
              width: 0px;
              height: 2px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background: #ccc;
            }
            &::-webkit-scrollbar-button {
              width: 10px;
              border-radius: 50%;
              background: black;
            }
            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 2px #333;
              border-radius: 5px;
              // background: blue;
            }
          }
        }
        .bottom-box {
          width: calc(100% - 62px);
          // width: 100%;
          height: 100%;
          // background-color: #fff;
          overflow: hidden;
          position: absolute;
          transition: 0.6s;
          // padding-top: 570px;
          // left: 2px;
          margin-left: 7px;
          top: 0;
        }
      }
    }
    .shu-border-left-top {
      top: 0;
    }
    .shu-border-right-top {
      top: 0;
    }
  }
}

@media screen and(max-width: 1919px) {
  .center-container {
    width: calc(100% - 84px);
  }
}
@media screen and (min-width: 1920px) {
  .center-container {
    width: 1836px;
  }
}
</style>
