<template>
  <div :class="[boxBegin?'begin-box':'']" class="box">
    <div class="user-box">
      <userInfo />
    </div>
    <div class="public-left" v-show="commonStatus">
      <publicMessage :shows="shows" v-on:datas="getChildDatas" />
    </div>

    <div class="menu-list">
      <div
        :class="[searchShow?'enters':'leaves']"
        @mouseenter="searchShow=true"
        @mouseleave="searchShow=false"
        class="search-container"
      >
        <div @mouseleave="searchClose" class="search-box">
          <el-input
            @blur="inputBlur"
            @focus="inputFocus"
            @keyup.enter.native="searchBtn"
            clearable
            placeholder="请输入要查找的内容"
            v-model="searchValue"
          ></el-input>
          <ul class="history-list">
            <li
              :key="index"
              @click="historySearch(item)"
              v-for="(item, index) in historyList"
            >{{ item }}</li>
          </ul>
        </div>
        <div :class="[searchShow?'enters':'leaves']" class="search-btn">
          <span @click="searchBtn" class="iconfont icon-sousuo3"></span>
        </div>
      </div>
      <div
        :class="[menuShow?'enters':'leaves']"
        @click="checkList"
        @mouseenter="menuShow=true"
        @mouseleave="menuShow=false"
        class="menu-btn"
      >
        <span class="iconfont icon-liebiao" v-if="ifList"></span>
        <span class="iconfont icon-four-squares" v-else></span>
      </div>
      <!--  -->
      <div
        :class="[confirmShow ? 'enters' : 'leaves' , submitStatus ? 'appear' : '']"
        @click="onSubmit"
        @mouseenter="confirmShow=true"
        @mouseleave="confirmShow=false"
        class="confirmOrder"
      >确认订单</div>
    </div>

    <div class="nothings" v-if="nothings">您搜索的产品暂未在以下列表,请联系工作人员咨询,期待您的来电!</div>

    <div @click="hiddenLogin" class="list animated fadeIn" ref="fatList" v-if="tableShow">
      <!-- @mousewheel="scrool" -->

      <div class="list-table" ref="lists">
        <!-- <div @click="up" class="product-menu"></div> -->

        <div
          :key="index"
          @mouseenter="enterChange(index)"
          @mouseleave="leaveChange(index)"
          class="list-tr"
          v-for="(item, index) in productList"
        >
          <div @click="tableView(item.bigFilePath)" class="imgs">
            <div class="grid" ref="gallery" v-loading="loading">
              <img :alt="item.productName" :src="`${pubilckPath}/${item.bigFilePath}`" />
            </div>
            <img :src="`${pubilckPath}/${item.bigFilePath}`" alt style="display:none" />
          </div>
          <div :title="item.productName" class="title">{{ item.productName }}</div>
          <div class="price" v-show="priceShow">
            ￥{{
            (orderList[index].price = endPrice(item.productPrices, index))
            }}元/{{ item.productUnits }}
          </div>
          <div class="input" v-show="priceShow">
            <input
              @blur="
                inputBtn(item.productPrices, index, item.moq, $event, item)
              "
              class="el-input"
              oninput="if(value.length>5)value=value.slice(0,6)"
              onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
              placeholder="请输入数量"
              v-model.number.trim="orderList[index].orderNumber"
            />
          </div>
          <div
            :title="item.productDesc"
            @click="openDes(index)"
            class="describe"
            v-show="priceShow"
          >{{ item.productDesc }}</div>
          <div
            :title="item.productDesc"
            @click="closeDes(index)"
            class="all-des"
            v-show="priceShow"
          >{{ item.productDesc }}</div>
        </div>
      </div>
    </div>
    <!-- 产品列表第一版 -->
    <!-- <div @mouseenter="arrow()" class="arrow" ref="arrow">
      <span style="width:50px;display: block;">
        <van-icon class="move ar-animated ar-delay-2s" name="arrow" />
        <van-icon class="move ar-animated ar-delay-1s" name="arrow" />
        <van-icon class="move ar-animated" name="arrow" />
      </span>
    </div>-->

    <!-- 产品列表第二版 -->
    <div class="list-li animated fadeIn" ref="listT" v-if="!tableShow">
      <!-- @mousewheel="scrools" -->
      <div class="table-box">
        <div class="scoll-box">
          <div
            :key="index"
            @click.stop="listOpenImg"
            @mouseenter="listUp(index)"
            @mouseleave="listDown(index)"
            class="tr_container"
            v-for="(item, index) in productList"
          >
            <div class="logins">
              <div class="smallImg">
                <div @click.stop ref="image" v-loading="loading">
                  <img :src="`${pubilckPath}/${item.bigFilePath}`" alt />
                </div>
              </div>
            </div>
            <div :title="item.productName" class="names">{{ item.productName }}</div>
            <div class="prices" v-show="priceShow">
              {{
              (orderList[index].price = endPrice(item.productPrices, index))
              }}/{{ item.productUnits }}
            </div>
            <div
              :title="item.productDesc"
              @click="listDesOpen(index)"
              class="describes"
              v-show="priceShow"
            >{{ item.productDesc }}</div>
            <div
              :title="item.productDesc"
              @click="listDesClose(index)"
              class="all-describes"
            >{{ item.productDesc }}</div>
            <div @click.stop class="numbers" v-show="priceShow">
              <input
                @blur="
                  inputBtn(item.productPrices, index, item.moq, $event, item)
                "
                class="el-input"
                oninput="if(value.length>5)value=value.slice(0,6)"
                onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                placeholder="请输入数量"
                v-model.number.trim="orderList[index].orderNumber"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="shows" append-to-body width="40%">
      <div class="express-title">发货信息</div>
      <ul class="ul-list">
        <li :key="index" v-for="(item,index) in expressDatas">{{item.info}}</li>
      </ul>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="shows = false">取 消</el-button>
        <el-button type="primary" @click="shows = false">确 定</el-button>
      </span>-->
    </el-dialog>

    <!-- <el-drawer
      :modal="false"
      :size="size"
      :visible.sync="drawer"
      :with-header="false"
      @open="open"
      direction="btt"
      title="我是标题"
    >
      <div class="kong"></div>

      <div class="list-li animated fadeIn" ref="listT" v-if="!tableShow" @mousewheel="scrools">
        <div class="table-box">
          <div class="scoll-box">
            <div
              :key="index"
              @click.stop="bigImage(index)"
              class="tr_container"
              v-for="(item, index) in productList"
            >
              <div class="logins">
                <div class="smallImg">
                  <div @click.stop ref="image" v-loading="loading">
                    <img :src="`${item.bigFilePath}`" alt />
                  </div>
                </div>
              </div>
              <div :title="item.productName" class="names">{{ item.productName }}</div>
              <div class="prices" v-show="priceShow">
                {{
                (orderList[index].price = endPrice(
                item.productPrices,
                index
                ))
                }}/{{ item.productUnits }}
              </div>
              <div
                :title="item.productDesc"
                class="describes"
                v-show="priceShow"
              >{{ item.productDesc }}</div>
              <div @click.stop class="numbers" v-show="priceShow">
                <input
                  @blur="inputBtn(item.productPrices, index,item.moq)"
                  class="el-input"
                  oninput="if(value.length>5)value=value.slice(0,6)"
                  onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                  placeholder="请输入数量"
                  v-model.number.trim="orderList[index].orderNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="trBtn">
        <div class="tright list-top">
          <i @click="changeList" class="el-icon-s-unfold libiao" v-if="ifTable"></i>
          <i @click="changeTable" class="el-icon-menu biaoge" v-else></i>
        </div>
        <div @click.stop="closeDrawer" class="tright">
          <span class="iconfont icon-shouqi2"></span>
        </div>
        <div>
          <input @click.stop="onSubmit" class="subBtn" type="button" value="确认订单" />
        </div>
      </div>
      <div class="mengceng" v-if="bigImg.show">
        <div
          :class="bigImg"
          @click.stop="size = '100%'"
          class="bigImgs animated animate__fadeIn"
          ref="bigImg"
        >
          <div class="rotate-box animated zoomIn" ref="image">
            <div @click="rotate" class="rotate animated fadeIn" ref="rotate">
              <i class="el-icon-refresh-right"></i>
            </div>
            <img :src="img" alt class="followm" />
            <i @click.stop="closeBigImg" class="el-icon-error animated zoomIn" ref="is"></i>
          </div>
        </div>
      </div>
    </el-drawer>-->
  </div>
</template>
<script>
const token = window.sessionStorage.getItem('user-token')
const listInfo = window.sessionStorage.getItem('listInfo')
// import Viewer from "v-viewer";
import Viewer from 'viewerjs'
import bus from '../../utils/bus'

import utils from '../../assets/js/utils'
import publicMessage from '../../views/publicMessage'

import userInfo from '../../views/userInfo'

// console.log("88888", listInfo);
export default {
  components: {
    publicMessage,
    userInfo,
  },
  data () {
    return {
      boxBegin: true,
      imgShow: true,
      drawer: false,
      searchShow: true,
      menuShow: true,
      confirmShow: true,
      submitStatus: false,
      productList: [],
      orderList: [],
      img: '',
      bigImg: { hide: true, show: false },
      bigShows: false,
      size: '100%',
      token: token,
      arr: [],
      totalprice: 0,
      searchInput: '',
      listInfo: listInfo,
      bigShows: false,
      ifTable: true,
      tableShow: true,
      deg: 0,
      viewer: [],
      imgList: [],
      views: [],
      loading: true,
      priceShow: false,
      paddingNum: '',
      two: '',
      numCheck: 1,
      muilt: 1,
      muilts: 1,
      ifList: true,
      searchValue: '',
      historyList: [],
      nothings: false,
      options: {
        inline: true,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: 'data-source',
      },
      shows: false,
      expressDatas: [],
    }
  },
  computed: {
    commonStatus () {
      return this.$store.state.commonStatus
    }
  },
  created () {
    this.getList()
    this.imgShow = true
  },
  mounted () {
    setTimeout(() => {
      this.searchShow = false
      this.menuShow = false
      this.confirmShow = false
      // this.boxBegin = false;

    }, 10000)
    setTimeout(() => {
      this.boxBegin = false;

    }, 3000)

    var token_imit = window.sessionStorage.getItem('user-token')
    if (token_imit) {
      this.priceShow = true
    } else {
      // debugger
      this.priceShow = false
    }
    this.productListHidden()

    // if(sessionStorage.getItem("historyList")){
    //   this.historyList = JSON.parse(JSON.stringify(sessionStorage.getItem("historyList")))
    //   // console.log('4444',this.historyList);

    // }
  },
  updated () {
    this.tableView()
    console.log('ret', this.productList);

  },
  watch: {
    orderList (newValue, oldValue) {
      console.log('coms11', newValue)
      console.log('coms12', oldValue)
    },
    $route () {
      console.log('wotiaole', sessionStorage.getItem('listInfo'))
      if (sessionStorage.getItem('listInfo') == null) {
        this.orderList.forEach((item, index) => {
          item.orderNumber = ''
        })
        let dom = document.querySelector('.confirmOrder')
        if (dom.classList.contains('appear')) {
          dom.classList.remove('appear')
          dom.classList.add('disappear')
          this.submitStatus = false
        }
        this.$forceUpdate()
      }
    },
  },
  methods: {
    getChildDatas (datas, status) {
      console.log('datas', datas, status)
      this.expressDatas = JSON.parse(JSON.stringify(datas))
      this.shows = status
    },
    // up () {
    //   let boxDom = document.querySelector('.box')
    //   if (boxDom.classList.contains('up')) {
    //     boxDom.classList.remove('up')
    //     boxDom.classList.add('down')
    //     this.menuList = false;
    //   } else {
    //     boxDom.classList.remove('down')
    //     boxDom.classList.add('up')

    //   }
    // },
    inputBlur () {
      // this.searchValue = ''
      // this.historyList = []
    },
    inputFocus () {
      let ul = document.querySelector('.history-list')
      ul.style.display = 'block'
      if (localStorage.getItem('historyList')) {
        this.historyList = JSON.parse(localStorage.getItem('historyList'))
      }
    },
    historySearch (item) {
      this.searchValue = item
      this.searchList()
      let dom = document.querySelector('.search-box')
      let dom2 = document.querySelector('.search-container')
      dom.classList.remove('searchOpen')
      dom.classList.add('searchClose')
      let ul = document.querySelector('.history-list')
      ul.style.display = 'none'
      this.searchValue = ''
    },
    searchClose () {
      console.log('出来了');

      let dom = document.querySelector('.search-box')
      dom.classList.remove('searchOpen')
      dom.classList.add('searchClose')
      let ul = document.querySelector('.history-list')
      ul.style.display = 'none'
    },
    searchBtn () {
      let dom = document.querySelector('.search-box')
      let dom2 = document.querySelector('.search-container')
      let ul = document.querySelector('.history-list')

      console.log('dom2', dom2.style)
      if (this.searchValue == '' || this.searchValue == undefined) {
        ul.style.display = 'none'
        this.searchList()

        if (dom.classList.contains('searchOpen')) {
          dom.classList.remove('searchOpen')
          dom.classList.add('searchClose')
        } else {
          dom.classList.add('searchOpen')
          dom.classList.remove('searchClose')
        }
        dom2.style.overflow = 'unset'
      } else {
        //存localStorage

        this.searchList()
        dom.classList.remove('searchOpen')
        dom.classList.add('searchClose')

        this.historyList.unshift(this.searchValue)
        this.historyList = this.unique(this.historyList)
        console.log('44445', this.historyList)
        if (this.historyList.length > 5) {
          //最多保存10条
          this.historyList = this.historyList.slice(0, 5)
        }
        localStorage.setItem('historyList', JSON.stringify(this.historyList))
        ul.style.display = 'none'

        this.searchValue = ''
      }
    },
    searchList () {
      this.$request
        .get(this.$api.getProductList, {
          productName: this.searchValue,
          state: 0,
          sort: 'sort,desc',
        })
        .then((res) => {
          if (res.data.content.length <= 0) {
            this.nothings = true
            this.productList = []
          } else {
            this.nothings = false
            this.productList = res.data.content
            console.log('items', this.productList)
          }
        })
    },
    //去重方法封装
    unique (arr) {
      return arr.filter(function (item, index, arr) {
        return arr.indexOf(item, 0) === index
      })
    },

    checkList () {
      this.ifList = !this.ifList
      this.tableShow = !this.tableShow
      this.listView()
    },
    listOpenImg () { },

    // 产品列表第二版
    productListHidden () {
      setTimeout(() => {
        this.$refs.prolist.classList.add('leave')
        this.imgShow = false
      }, 7000)
    },
    enter () {
      this.$refs.prolist.classList.remove('leave')
    },
    leave () {
      this.imgShow = false
      this.$refs.prolist.classList.add('leave')
    },

    openListBtn () {
      console.log('right', this.$refs.prolist)

      setTimeout(() => {
        this.$refs.prolist.classList.add('prolistBlock')
        this.$refs.prolist.classList.add('heartBeat')
      }, 2000)

      setTimeout(() => {
        this.$refs.prolist.classList.remove('heartBeat')
        this.$refs.prolist.classList.add('bounceOutRight')
        // this.$refs.prolist.classList.remove('rightBlock');
      }, 3000)

      setTimeout(() => {
        this.$refs.prolist.classList.remove('prolistBlock')
        this.$refs.arrow.classList.add('arrowBlock')
      }, 4000)
    },
    arrow () {
      this.$refs.arrow.classList.remove('arrowBlock')
      this.$refs.prolist.classList.remove('bounceOutRight')
      this.$refs.prolist.classList.add('bounceInRight')

      this.$refs.prolist.classList.add('prolistBlock')
    },
    mouseLeave () {
      console.log('画出来了')
      this.$refs.arrow.classList.add('arrowBlock')
      this.$refs.prolist.classList.add('bounceOutRight')
    },

    open () {
      // debugger
      this.tableView()
    },

    bigImage (index) {
      this.views[index].view()
    },

    inputBtn (item, index, moq, event, items) {
      console.log('items', items)

      // debugger
      var inputNum = item[0].productNumber //请订值
      var moq = moq //购买数量的基准值
      var num = this.orderList[index].orderNumber //用户输入的值
      console.log('pppp', num)

      // 起订不能低于指定值
      if (moq != 0 && num !== '' && num !== null) {
        if (num < moq) {
          this.$message({
            message: `起订数量为${moq}${items.productUnits}`,
            type: 'warning',
          })
          this.orderList[index].orderNumber = moq
        }
      }

      if (item.length > 1 && num >= inputNum) {
        // debugger;
        for (var i = 0; i < item.length; i++) {
          // if (i + 1 == item.length) {
          //   var b = item[i].price;
          //   console.log("我就是价格", b);
          // }

          if (i < item.length) {
            //判断最后一个值，不进行中间值的对比，直接获取大于最后一个数值后为多少单价。（包含临界点）
            if (i + 1 == item.length) {
              if (num >= item[i].productNumber) {
                console.log('大于长度le', item[i].productNumber)
                console.log('大于长度le', item[i].price)
                console.log('价格为', item[i].price)
                this.orderList[index].price = item[i].price
              }

              // console.log("我就是价格", a);
            }
            //中间值的对比，看在哪一个区间 获取相对于的单价。（包含临界点）
            if (i + 1 < item.length && i + 1 !== item.length) {
              // console.log("第一个值", item[i].productNumber);
              // console.log("第二个值", item[i + 1].productNumber);

              if (
                num >= item[i].productNumber &&
                num < item[i + 1].productNumber
              ) {
                console.log('比较的值', num)
                console.log('第一个值', item[i].productNumber)
                console.log('第二个值', item[i + 1].productNumber)
                console.log('价格为', item[i].price)
                break
              }
            }

            // if (i + 1 < item.length && i + 1 !== item.length) {
            //   if (
            //     Number(item[i].productNumber) < Number(num) &&
            //     Number(num) < Number(item[i + 1].productNumber)
            //   ) {
            //     console.log("对比的值", num);

            //     console.log("第一个值", item[i].productNumber);
            //     console.log("第二个值", item[i + 1].productNumber);
            //   }
            // }

            // i--;
          }
          // debugger
        }

      }
      for (var i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].orderNumber > 0) {
          this.submitStatus = true
          return
        } else {
          this.submitStatus = false
        }
      }

      console.log('submitStatus', this.submitStatus, this.orderList);


      // if (status) {
      //   // dom.style.right = 0
      //   if (dom.classList.contains('disappear')) {
      //     dom.classList.remove('disappear')
      //   }
      //   dom.classList.add('appear')
      // } else {
      //   // dom.style.right = -62+"px"

      //   dom.classList.remove('appear')
      //   dom.classList.add('disappear')
      // }

      // dom.style.display = 'none'
    },

    // 列表图片放大
    hiddenLogin () {
      this.$store.state.loginShow = false
    },
    big (imgurl, e) {
      this.open()
    },
    // 切换列表和表格
    changeList () {
      this.ifTable = false
      this.tableShow = false
      // this.numCheck = 1
      this.muilts = 1

      this.$nextTick(() => {
        this.$refs.listT.style.paddingTop = '60px'
      })

      this.listView()
    },

    // 初始化列表放大实例
    listView () {
      this.$nextTick(() => {
        for (var i = 0; i < this.$refs.image.length; i++) {
          this.views.push(new Viewer(this.$refs.image[i], { title: false }))
        }
      })
    },
    // 初始化表格放大实例
    tableView () {

      this.$nextTick(() => {
        for (var i = 0; i < this.$refs.gallery.length; i++) {
          this.viewer = new Viewer(this.$refs.gallery[i], { title: false })
        }
      })
    },
    changeTable () {
      this.ifTable = true
      this.tableShow = true
      this.muilt = 1
      this.$nextTick(() => {
        this.$refs.fatList.style.paddingTop = '60px'
      })
      this.tableView()
    },
    // 列表图片缩小
    closeBigImg () {
      this.bigImg.show = false
      this.bigImg.hide = true
    },
    // // 旋转图片
    // rotate () {
    //   switch (this.deg) {
    //     case 0:
    //       this.deg = 90
    //       this.$refs.is.style = 'left:0'
    //       this.$refs.rotate.style = 'top:40px;right:-20px'
    //       this.$refs.rotate.style.transform = 'rotate(-90deg)'
    //       break
    //     case 90:
    //       this.deg = 180
    //       this.$refs.is.style = 'bottom:0;left:0;top:unset'
    //       this.$refs.rotate.style = 'top:10px;left:10px'
    //       this.$refs.rotate.style.transform = 'rotate(-180deg)'
    //       break
    //     case 180:
    //       this.deg = 270
    //       this.$refs.is.style = 'bottom:0;right:0;top:unset'
    //       this.$refs.rotate.style = 'bottom:40px;left:-20px;top:unset'
    //       this.$refs.rotate.style.transform = 'rotate(-270deg)'
    //       break
    //     case 270:
    //       this.deg = 0
    //       this.$refs.is.style = 'top:0;right:0;'
    //       this.$refs.rotate.style = 'bottom:10px;right:10px;top:unset'
    //       this.$refs.rotate.style.transform = 'rotate(0deg)'
    //       break
    //   }

    //   this.$refs.image.style.transform = `rotate(${this.deg}deg)`
    //   // this.$refs.is.style = "left:0";
    // },
    handleScroll () {
      this.bigImg.show = false
      this.bigImg.hide = true
    },
    // 列表出现时，登录/注册页面消失。
    closePage () {
      // this.size = "50%";
      this.$store.state.loginShow = false
      this.$store.state.registerShow = false
    },
    // 获取数据列表
    getList () {
      this.$axios({
        method: 'GET',
        url: '/api/xwProduct/back',
        params: {
          state: 0,
          sort: 'sort,desc',
        },
      })
        .then((res) => {
          console.log('ret', res);

          this.productList = res.data.content

          this.loading = false
          console.log("22444442", this.productList);
          this.productList.forEach((item, index) => {
            this.orderList[index] = {
              productId: item.productId,
              price: 0,
              orderNumber: null,
              product: item,
            }
          })

          if (this.listInfo) {
            const info = JSON.parse(this.listInfo)
            // info.forEach((item, index) => {
            //   this.orderList[index].orderNumber = item.orderNumber;
            // });

            info.forEach((every, nums) => {
              this.orderList.forEach((items, indexs) => {
                if (every.product.id == items.product.id) {
                  this.orderList[indexs].orderNumber = every.orderNumber
                }
              })
            })
          }

        })
        .catch((err) => {
          console.log(err)
        })
    },

    onSubmit () {
      this.$store.state.checkOrder = true
      this.$store.state.againOrder = false

      this.$store.state.clearList = this.orderList
      const a = this.orderList.filter((item) => {
        return item.orderNumber
      })
      console.log(a)
      var prolist = JSON.stringify(a)
      console.log('4444444', a)
      window.sessionStorage.setItem('listInfo', prolist)

      if (!this.token) {
        this.$message.error('请登录！')
        this.$router.push('/login')
        this.$store.state.loginShow = true
        this.drawer = false
      }
      if (a.length === 0 && this.token) {
        this.$message.error('错误，订单不能为空')
      } else {
        this.$router.push('/order')
        this.drawer = false
      }
    },
    scrool (e) {
      var cHeight = document.documentElement.clientHeight
      var muilt = this.muilt++
      var paddingNum = muilt * 60
      if (paddingNum >= cHeight) {
        this.$refs.fatList.style.paddingTop = `${cHeight}px`
      } else {
        this.$refs.fatList.style.paddingTop = `${paddingNum}px`
      }
    },
    scrools () {
      var cHeight = document.documentElement.clientHeight
      var muilts = this.muilts++
      var paddingNums = muilts * 60
      if (paddingNums >= cHeight) {
        this.$refs.listT.style.paddingTop = `${cHeight}px`
      } else {
        this.$refs.listT.style.paddingTop = `${paddingNums}px`
      }
    },
    add () {
      this.drawer = true
      this.imgShow = false
      this.$refs.prolist.classList.add('leave')
      // this.leave()
      // this.$refs.prolist.classList.add('leave')
      // this.$refs.arrow.classList.add('arrowBlock');
      // this.$refs.prolist.classList.add('bounceOutRight');
      this.size = '100%'
      this.muilt = 1
      this.$nextTick(() => {
        if (this.$refs.fatList) {
          this.$refs.fatList.style.paddingTop = '60px'
        }
        if (this.$refs.listT) {
          this.$refs.listT.style.paddingTop = '60px'
          this.muilts = 1
        }
      })
    },
    endPrice (productPrices, index) {
      if (productPrices.length > 1) {
        for (var i = 0; i < productPrices.length; i++) {
          if (
            this.orderList[index].orderNumber >=
            productPrices[i].productNumber &&
            this.orderList[index].orderNumber <
            productPrices[i + 1].productNumber
          ) {
            return productPrices[i].price
          } else if (
            this.orderList[index].orderNumber >=
            productPrices[productPrices.length - 1].productNumber
          ) {
            return productPrices[productPrices.length - 1].price
          }
        }
      }
      return productPrices[0].price
    },
    showImg (imgurl, e) {
      this.img = `${imgurl}_resize_?w=1000&h=1000`
      this.bigImg.show = true
      this.bigImg.hide = false
      var intX = window.event.clientX
      var intY = window.event.clientY
      var allH = document.body.clientHeight
      console.log('allh', allH)
      // 设置大图左上角起点位置
      this.$refs.bigImg.style.left = intX - 150 < 0 ? 0 : intX - 150 + 'px'
      this.$refs.bigImg.style.top = intY - 150 < 0 ? 0 : intY - 150 + 'px'
    },
    hideImg (e) {
      this.bigImg.show = false
      this.bigImg.hide = true
    },

    closeDrawer () {
      this.$store.state.loginShow = false
      this.$store.state.registerShow = false
      this.drawer = false
    },
    enterChange (index) {
      let dom = document.querySelectorAll('.list-tr')

      if (dom[index].classList.contains('dishover')) {
        dom[index].classList.remove('dishover')
      }
      dom[index].classList.add('hover')
    },
    leaveChange (index) {

      let dom = document.querySelectorAll('.list-tr')
      if (dom[index].classList.contains('hover')) {
        dom[index].classList.remove('hover')
      }
      dom[index].classList.add('dishover')
      let doms = document.querySelectorAll('.all-des')
      if (doms[index].classList.contains('open-des')) {
        doms[index].classList.remove('open-des')
        doms[index].classList.add('close-des')
      }
    },
    openDes (index) {
      let dom = document.querySelectorAll('.all-des')
      if (dom[index].classList.contains('close-des')) {
        dom[index].classList.remove('close-des')
      }
      dom[index].classList.add('open-des')
    },
    closeDes (index) {
      let dom = document.querySelectorAll('.all-des')
      if (dom[index].classList.contains('open-des')) {
        dom[index].classList.remove('open-des')
      }
      dom[index].classList.add('close-des')
    },
    listUp (index) {
      let dom = document.querySelectorAll('.tr_container')
      if (dom[index].classList.contains('list-down')) {
        dom[index].classList.remove('list-down')
      }
      dom[index].classList.add('list-up')
    },
    listDown (index) {
      let dom = document.querySelectorAll('.tr_container')
      if (dom[index].classList.contains('list-up')) {
        dom[index].classList.remove('list-up')
      }
      dom[index].classList.add('list-down')

      let doms = document.querySelectorAll('.all-describes')
      if (doms[index].classList.contains('list-des-open')) {
        doms[index].classList.remove('list-des-open')
        doms[index].classList.add('list-des-close')
      }
    },
    listDesOpen (index) {
      let dom = document.querySelectorAll('.all-describes')
      if (dom[index].classList.contains('list-des-close')) {
        dom[index].classList.remove('list-des-close')
      }
      dom[index].classList.add('list-des-open')
    },
    listDesClose (index) {
      let dom = document.querySelectorAll('.all-describes')

      dom[index].classList.remove('list-des-open')
      dom[index].classList.add('list-des-close')
    },
  },
}
</script>
<style scoped lang="less">
html {
  overflow-x: hidden;
}
.list {
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  // padding-top: 100vh;
  padding-top: 10px;
}

.product-menu {
  height: 35px;
  width: 35px !important;
  background: #ffffff !important;
  position: absolute;
  right: -10px;
  padding: 0 !important;
  margin-right: 0 !important;
}
.menu-list {
  position: fixed;
  // padding:2px 0;
  top: 60px;
  right: 0px;
  // width: 100px !important;
  padding: 0 !important;
  text-align: center;
  color: #fff;

  font-size: 16px;
  z-index: 10;
  .confirmOrder {
    font-size: 12px;
    color: #000;
    cursor: pointer;
    // padding: 15px;
    line-height: 36px;
  }
  @keyframes enters {
    0% {
      right: -69px;
    }
    100% {
      right: 0;
    }
  }
  @keyframes leaves {
    0% {
      right: 0px;
    }
    100% {
      right: -69px;
    }
  }
  .enters {
    animation-name: enters;
    animation-duration: 0.4s;
    animation-fill-mode: linear;
    right: 0;
  }
  .leaves {
    animation-name: leaves;
    animation-duration: 0.4s;
    animation-fill-mode: linear;
    right: -69px;
  }

  > div {
    margin-bottom: 10px;

    // margin-bottom: 10px;
    height: 34px;
    width: 76px;
    // background: #fff;
    line-height: 36px;
    position: relative;
    > .iconfont {
      color: #000;
      font-size: 30px;
    }
  }
  .appear {
    background: #F0850C;
    color: #fff !important;
    animation-name: appear;
    animation-duration: 0.5s;
    right: 0;
    animation-fill-mode: linear;
  }
}
.confirmOrder {
  background: #fff;
  right: -62px;
  transition: 0.45s;
  &:hover {
    right: 0;
  }
}
.table-box {
  max-width: 80%;
  margin: 0 auto;
  // overflow: hidden;
  // overflow-y: auto;
  .scoll-box {
    width: 100%;
    // margin-top: 80px;
  }
  .tr {
    position: fixed;
    width: 1745px;
    z-index: 999;
  }
  .tr,
  .tr_container {
    position: relative;
    width: 100%;
    background: rgba(92, 99, 110, 0.4);
    font-size: 16px;
    color: #fff;
    display: flex;
    margin-bottom: 10px;
    // border-radius: 3px;
    > div {
      height: 40px;
      text-align: left;
      line-height: 40px;
    }
    .logins {
      width: 8%;
      .smallImg {
        margin: 2px auto;
        cursor: pointer;
      }
    }
    .names {
      width: 30%;
      cursor: default;
    }
    .prices {
      width: 10%;
      cursor: default;
    }
    .describes {
      width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .numbers {
      width: 180px;
      input {
        border: 0.0625rem solid #fff;
        background: rgba(92, 99, 110, 0.4);

        &::-webkit-input-placeholder {
          color: #fff;
          opacity: 0.5;
        }
      }
    }
  }

  /deep/.el-table {
    margin: 0 auto;
    overflow: hidden;
    overflow-y: scroll;
  }
}
.viewer-toolbar > ul > .viewer-large {
  display: none !important;
}
.grid {
  width: 113.99px;
  height: 89.93px;
}

.list-table {
  // max-width: 1745px;
  // max-width: 1640px;
  max-width: 100%;
  padding-left: 65px;
  margin: 0 auto;
  // padding-top: 1000px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  > div {
    width: 124px;
    padding: 5px;
    background: rgba(92, 99, 110, 0.7);
    // margin-right: 5px;
    margin-right: 10px;
    color: #fff;
    margin-bottom: 10px;
    // border-radius: 5px;
    input {
      background: rgba(92, 99, 110, 0.4);
      border: 1px solid #fff;
    }
    > .imgs {
      // border-radius: 5px;
      cursor: pointer;
      overflow: hidden;
      // width: 124px;
      height: 84px;
      position: relative;
      img {
        width: 100%;
        // object-fit: fill;
        // object-fit: contain;
        // object-fit: scale-down;
        // image-rendering: -moz-crisp-edges; /* Firefox */
        // image-rendering: -o-crisp-edges; /* Opera */
        // image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
        // image-rendering: crisp-edges;
        // -ms-interpolation-mode: nearest-neighbor; /* IE /(non-standard property) */
      }
      .grid {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .aa {
        width: 114px;
        height: 85px;
        position: absolute;
        background: #ccc;
        z-index: 99;
        top: 0;
        visibility: hidden;
      }
    }

    .title {
      width: 100%;
      overflow: hidden;
      margin-top: 10px;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: default;
    }
    .price {
      margin-top: 5px;
      font-size: 12px;
      cursor: default;
    }
    .describe {
      width: 100%;
      overflow: hidden;
      // height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 5px;
      font-size: 12px;
      cursor: pointer;
    }
    .all-des {
      position: absolute;
      bottom: 12px;
      height: 0;
      overflow: hidden;
      width: 92%;
      cursor: pointer;
    }
    .input {
      margin-top: 5px;
      margin-bottom: 5px;
      .el-input {
        text-align: center;
        width: 90px;
        // height: 25px;
        padding: 0;
        border: 1px solid #fff;
        // border-radius: 5px;
        color: #fff;
        outline: none;
        /deep/.el-input__inner {
          color: #fff;
        }
        &::-webkit-input-placeholder {
          color: #fff;
          opacity: 0.5;
        }
        &:focus {
          border: 1px solid #409eff;
          outline: none;
        }
      }
    }
  }
}
.smallImg {
  width: 20px;
  height: 20px;
  img {
    width: 100%;
    object-fit: fill;
    object-fit: contain;
    object-fit: scale-down;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
}
.el-input {
  text-align: center;
  width: 90px;
  // height: 25px;
  padding: 0;
  border: 1px solid #dcdfe6;
  // border-radius: 5px;
  outline: none;
  &:focus {
    border: 1px solid #409eff;
    outline: none;
  }
}

.divs {
  display: flex;
  flex-wrap: wrap;
  div {
    height: 300px;
    width: 500px;
  }
}
.container {
  background: #f5f5f5;
  margin-right: 10px;
  padding-top: 10px;
  border-radius: 5px;
  transition: 0.65s;
  &:hover {
    transform: translateZ(60deg);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.big-imgs {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
.mengceng {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(128, 128, 128, 0.7);
}
.bigImgs {
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  .rotate-box {
    display: flex;
    justify-content: center; //实现水平居中
    align-items: center;
    position: relative;
    transition-duration: 2s;
    &:hover .rotate {
      display: block;
    }
    > i {
      z-index: 999;
      transition: 1s;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 35px;
      color: #ffcc00;
    }
  }
  .rotate {
    display: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
    height: 60px;
    width: 120px;
    border: 1px solid #ccc;
    line-height: 60px;
    text-align: center;
    z-index: 99;
    font-size: 30px;
    color: #ffcc00;
    border-radius: 5px;
    background-color: rgba(128, 128, 128, 0.7);
  }
  > i {
    z-index: 999;
    transition: 1s;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 35px;
    color: #ffcc00;
  }
}
.box {
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  // height: 100px;
  // top: 90vh;
  height: 100vh;
  top: 0;
  width: 100%;
  padding-top: 89vh;
  box-sizing: border-box;
  z-index: 1;
  transition: 1.3s;
}
.begin-box {
  padding-top: 50vh;
  transition: 1.3s;
}
.followm {
  // width: 500px;
  border-radius: 5px;
}
.hide {
  display: none;
}
.img_box {
  width: 100%;
  .imgs {
    width: 100%;
  }
}
.show {
  position: fixed;
  // pointer-events: none;

  display: block;
}
/deep/ .el-drawer {
  //  background:rgba(224, 230, 235,0.8);
  // background: linear-gradient(to bottom, #ccc, #e2e2e2, #f7f7f7);
  background: transparent;
  overflow-y: scroll;
}

/deep/ .el-card__body {
  padding: 10px;
}
.left {
  width: 100%;
  display: inline-block;
  text-align: right;
  // overflow: hidden;
  // border-radius: 50%;
  width: 1005;
  .tile {
    height: 40px;
    background-color: #fff;
    text-align: center;
    line-height: 40px;
  }

  // &:hover img{
  //   transform: scale(2);
  // }
  // &:hover .right{
  //   display: block;
  //   background: rgba(0, 0, 0, .8);
  // }
}
.right {
  display: inline-block;
  line-height: 35px;
  margin-top: 20px;
  height: 35px;
  width: 100px;
  vertical-align: bottom;
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.rightBlock {
  display: inline-block !important;
}

.imgbox {
  width: 100%;
  position: relative;
  height: 106px;
  overflow: hidden;
  .icon-icon {
    position: absolute;
    /* z-index: 99; */
    right: 0;
    bottom: 4px;
    color: #fff;
    font-size: 20px;
  }
  .img {
    position: absolute;
    width: 100%;
    vertical-align: bottom;
  }
}

.orderText {
  color: #666;
  text-align: left;
  // overflow: hidden;
  p {
    width: 100%;
    white-space: nowrap;
    margin: 5px 0px;
    font-size: 13px;
    overflow: hidden;
    height: 18px;
    color: #000;
    text-overflow: ellipsis;
  }
  input {
    border-radius: 5px;
    margin-top: 7px;
    background-color: transparent;
    // border-radius: 10px;
    outline: none;
    border: 1px solid #666;
    width: 60%;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}
.el-row {
  margin-top: 20px;
  margin-left: 125px;
  padding: 0 90px;
  height: 100vh;
}
.el-col {
  margin-bottom: 20px;
}
.prolist {
  // display: none; //产品列表第一版
  position: fixed;
  right: 0;
  bottom: 150px;
}
.prolistBlock {
  display: block !important;
}

.foot {
  width: 100%;
  background-color: #f0f3f5;
  height: 125px;
  text-align: center;
  padding-top: 30px;
  position: fixed;
  bottom: 0;
  .btn {
    width: 325px;
    height: 46px;
    outline: none;
    border: none;
    background-color: #fff;
    color: #0758d8;
    border-radius: 23px;
    font-size: 20px;
  }
}
.tright {
  width: 80px;
  height: 40px;
  // background-color: #fff;
  background: #7459dc;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 25px;
  text-align: center;
  padding-top: 5px;

  .icon-shouqi2 {
    font-size: 20px;
  }
}
.list-top {
  position: fixed;
  right: 0;
  top: 55px;
  line-height: 40px;
  // padding-top: 0;
  .libiao {
    font-size: 25px;
  }
  .biaoge {
    font-size: 25px;
  }
}
.trBtn {
  position: fixed;
  bottom: 0;
  right: 0;
}
.subBtn {
  border: none;
  // background-color: #fff;
  color: #fff;
  background: #41b3ff;
  border-radius: 25px;
  margin-bottom: 10px;
  width: 80px;
  font-size: 14px;
  text-align: center;
  // border-top: 1px solid #666;
  height: 40px;
  font-weight: 700;
  outline: none;
  // color: #666;
}

//去除input type=number 的默认样式
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}
@keyframes move {
  0% {
    left: 0%;
    opacity: 0;
  }
  50% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
.move {
  -webkit-animation-name: move;
  animation-name: move;
  // position: relative;
  // margin-left: -25px;
  font-size: 15px;
  color: #fff;
}
.ar-animated {
  margin-left: -10px;
  color: #fff;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  font-style: inherit;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.ar-delay-1s {
  animation-delay: 0.1s;
}
.ar-delay-2s {
  animation-delay: 0.2s;
}
.ar-delay-3s {
  animation-delay: 0.3s;
}
.ar-delay-4s {
  animation-delay: 0.4s;
}
.ar-delay-5s {
  animation-delay: 0.5s;
}
.ar-delay-6s {
  animation-delay: 0.6s;
}
.ar-delay-7s {
  animation-delay: 0.7s;
}
.ar-delay-8s {
  animation-delay: 0.8s;
}
.ar-delay-9s {
  animation-delay: 0.9s;
}
.ar-delay-10s {
  animation-delay: 0.1s;
}
.arrow {
  position: fixed;
  width: 50px;
  display: none;
  right: 35px;
  bottom: 180px;
  // text-align:right;
}
.btt {
  overflow-x: hidden;
}
.arrowBlock {
  display: block !important;
}

.nothings {
  position: absolute;
  margin-left: 35%;
  bottom: 400px;
  font-size: 20px;
  color: red;
}

// 产品列表第二版
.prolist {
  width: 120px !important;
  height: auto !important;
  bottom: 120px !important;

  transition: 0.8s;
}
.leave {
  right: -85px;
  // bottom: 88.5px !important;
}
.enter {
}
.product-list {
  color: #ffffff;
  background: #fff;
  // padding:5px;
  box-sizing: border-box;
  // border-top-left-radius: 3px;
  // border-bottom-left-radius: 3px;
  position: relative;
  > .product-icon {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #000;
    > i {
      font-size: 25px;
    }
  }
  .product-img {
    width: 100%;
    position: absolute;
    top: 35px;
    img {
      width: 100%;
    }
  }
}

.list-tr {
  position: relative;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}
@keyframes up {
  0% {
    top: 90vh;
  }
  100% {
    top: 55px;
  }
}
@keyframes down {
  0% {
    top: 55px;
  }
  100% {
    top: 90vh;
  }
}
.up {
  animation-name: up;
  top: 55px;
  height: 95vh;
  overflow-y: auto;
  animation-duration: 0.35s;
  animation-timing-function: ease-in-out;
}
.down {
  animation-name: down;
  top: 90vh;
  animation-duration: 0.35s;
  animation-timing-function: linear;
}
.menu-btn {
  background: #fff;
  right: -62px;
  transition: 0.45s;
}
.search-container {
  position: relative;
  z-index: 10;
  overflow: hidden;
  right: -62px;
  transition: 0.45s;
  &:hover {
    right: 0;
  }
  /deep/.el-input--suffix .el-input__inner {
    height: 25px;
  }
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 34px;
  position: absolute;
  background: #65C8D0;
  left: 0px;
  z-index: -9;
  > .el-input {
    width: 300px;
    border: none;
    // background-color: #65C8D0;
  }

  > .history-list {
    width: 320px;
    position: absolute;
    top: 33px;
    left: 0px;
    background: #65C8D0;
    color: #000;
    padding: 0 25px;
    li {
      text-align: left;
      padding-left: 0px;
      height: 25px;
      line-height: 25px;
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
    }
  }
}
.search-btn {
  height: 100%;
  width: 100%;
  background: #65C8D0;
  display: flex;
  justify-content: center;
  align-items: center;
  > .iconfont {
    color: #000;
    font-size: 30px;
  }
}
.menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#65C8D0;
}
.confirmOrder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0850C;
}

.table-box .tr_container .all-describes {
  position: absolute;
  width: 100%;
  background: rgba(92, 99, 110, 1);
  z-index: 9;
  padding: 0 15px;
  bottom: 0;
  height: 0;
  overflow: hidden;
  cursor: pointer;
}
@keyframes searchOpen {
  0% {
    left: 0;
  }
  100% {
    left: -320px;
  }
}
@keyframes searchClose {
  0% {
    left: -320px;
  }
  100% {
    left: 0;
  }
}
.searchOpen {
  left: -320px;
  width: 320px;
  animation-name: searchOpen;
  animation-duration: 0.35s;
  animation-timing-function: ease-in-out;
}
.searchClose {
  // width: 35px;
  left: 0;
  animation-name: searchClose;
  animation-duration: 0.35s;
  animation-timing-function: ease-in-out;
}
@keyframes appear {
  0% {
    background-color: #fff;
    right: -62px;
  }
  100% {
    background-color: #F0850C;
    right: 0;
  }
}
@keyframes disappear {
  0% {
    background-color: #F0850C;
    right: 0;
  }
  100% {
    background-color: #fff;
    right: -62px;
  }
}
.appear {
  background: #F0850C;
  color: #fff !important;
  animation-name: appear;
  animation-duration: 0.5s;
  right: 0;
  animation-fill-mode: linear;
}
.disappear {
  background: #fff;
  color: #000;
  animation-name: disappear;
  animation-duration: 0.35s;
  right: -62px;
  animation-fill-mode: linear;
}

@keyframes hover {
  0% {
    // opacity: 0;
    transform: scale(1);
    transform: translateY(0px);

    background: rgba(92, 99, 110, 0.4);
  }
  100% {
    // opacity: 1;
    transform: scale(2);
    transform: translateY(-7px);
    background: rgba(92, 99, 110, 1);
  }
}
@keyframes dishover {
  0% {
    // opacity: 1;
    transform: scale(2);
    transform: translateY(-7px);
    background: rgba(92, 99, 110, 1);
  }
  100% {
    // opacity: 0;
    transform: scale(1);
    transform: translateY(0px);
    background: rgba(92, 99, 110, 0.4);
  }
}

.hover {
  background: rgba(92, 99, 110, 1) !important;
  transform: translateY(-7px);

  animation-name: hover;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}
.dishover {
  background: rgba(92, 99, 110, 0.4);
  transform: translateY(0px);
  animation-name: dishover;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

@keyframes openDes {
  0% {
    height: 0px;
  }
  100% {
    height: 100px;
  }
}
@keyframes closeDes {
  0% {
    height: 100px;
  }
  100% {
    height: 0px;
  }
}

.list-table > div .open-des {
  height: 100px;
  background: rgba(92, 99, 110, 1);
  animation-name: openDes;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

.close-des {
  height: 0px;
  background: rgba(92, 99, 110, 1);
  animation-name: closeDes;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

// 列表动画

@keyframes listUp {
  0% {
    // transform: translateX(0px);
    transform: scale(1);
    background: rgba(92, 99, 110, 0.4);
  }
  100% {
    // transform: translateX(5px);
    transform: scale(1.1);
    background: rgba(92, 99, 110, 1);
  }
}
@keyframes listDown {
  0% {
    transform: scale(1.1);
    background: rgba(92, 99, 110, 1);

    // transform: translateX(5px);
  }
  100% {
    transform: scale(1);
    background: rgba(92, 99, 110, 0.4);

    // transform: translateX(0px);
  }
}

.table-box .list-up {
  // transform: translateX(5px);
  transform: scale(1.1);
  background: rgba(92, 99, 110, 1);

  animation-name: listUp;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

.list-down {
  transform: translateX(0px);
  background: rgba(92, 99, 110, 0.4);

  animation-name: listDown;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

@keyframes listDesOpen {
  0% {
    height: 0px;
  }
  100% {
    height: 100%;
  }
}
@keyframes listDesClose {
  0% {
    height: 100%;
  }
  100% {
    height: 0px;
  }
}

.table-box .tr_container .list-des-open {
  height: 100%;

  animation-name: listDesOpen;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}
.table-box .tr_container .list-des-close {
  height: 0;

  animation-name: listDesClose;
  animation-duration: 0.35s;
  animation-fill-mode: linear;
}

.user-box {
  position: fixed;
  top: 30px;
  // bottom: 500px;
  left: 10px;
  // width: 400px;
  // padding-left: 30px;
  padding-top: 20px;
  // display: none;
}

.express-title {
  text-align: center;
  font-size: 20px;
}
.ul-list {
  margin-top: 0px;
  padding: 30px;
  li {
    font-size: 14px;
    margin-bottom: 10px;
    // border-bottom:1px solid #ccc;
  }
}
/deep/.el-dialog__body {
  padding: 0;
}
</style>
