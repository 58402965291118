import request from '../assets/js/request'

export default {
  data() {
    return {
      apiUrl: '',
      methods: '',
      params: {},
      dataList: [],
      a: '345',
    }
  },
  methods: {
    init() {
      this.beforeInit()
      if (this.methods == 'get') {
        return request
          .get(this.apiUrl, this.params)
          .then((res) => {
            this.dataList = res.data.content
            console.log('mixins', this.dataList, this.msg)
          })
          .catch((err) => {
            // reject(err)
          })
      }
    },
    // beforeInit() {
    //   return true
    // },
  },
}
